import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DeviceNode } from "../../types/types";
import {
  validateNewName,
  updateSensorNameWrapper,
  revertChanges,
  toggleNameEdit,
} from "../../containers/NodeInfoHeader.helper";

import style from "./NodeInfo.module.scss";
import { PowerButton } from "./PowerButton";
import { useChart } from "../../contexts/ChartDataContext";
import { getProductName, getProbeName } from "../../utils/utility";
import { NodeSignalStrength } from "./NodeSignalStrength";
import { InfoBubble } from "../Misc/InfoBubble";
import { ScheduleButton } from "./TimeRangeModal/ScheduleButton";
import { NodeTypes, ProbeTypes, TSensorTypes } from "../../types/generated_types";
import { CloudControlButton } from "./CloudControlButton";

interface Props {
  node: DeviceNode;
  updateSensorName: (newName: string, nodeID: string) => void;
}

function acclimatiseEstimate(startDate : string): number {
  // captured_datetime (startDate) needs to explicitly cast into Date
  const acclimationLocalTime = new Date(startDate);
  return Math.ceil((Date.now() - acclimationLocalTime.getTime()) / 3600000);
}

export function NodeInfoHeader({
  node,
  updateSensorName,
}: Props): ReactElement {
  const { t } = useTranslation();
  const chart = useChart();
  const archived = !!chart.installation.archived_time;
  const [nameEditOnState, setNameEditOnState] = useState(false);
  const [displayErrorState, setDisplayErrorState] = useState(false);
  const [newNameState, setNewNameState] = useState("");

  const errorMessage = useMemo(() => (
    <div className="alert alert-danger p-0 mb-0 mt-1" role="alert">
      {t("Length : min 1, max 25")}
    </div>
  ), [t]);

  useEffect(() => {
    setNewNameState(node.sensor_name);
  }, [nameEditOnState, node.sensor_name]);

  // Determine wether to display a form for changing the sensors name or just
  // display the sensors name
  const SensorName = nameEditOnState ? (
    <>
      <input
        type="text"
        value={newNameState}
        className="new-sensor-name"
        placeholder={t("New Sensor Name")}
        aria-label="Recipient's username"
        name="sensorName"
        onChange={(e) => {
          if (validateNewName(e.target.value)) {
            setDisplayErrorState(false);
          } else {
            setDisplayErrorState(true);
          }
          setNewNameState(e.target.value);
        }}
      />
      <span
        className="m-1 badge badge-pill badge-secondary pointer-on-hover"
        title={t("Save settings")}
        aria-hidden="true"
        onClick={() =>
          updateSensorNameWrapper(
            node,
            newNameState,
            setNameEditOnState,
            displayErrorState,
            updateSensorName,
          )
        }
      >
        <i className="far fa-save" />
      </span>
      <span
        className="m-1 badge badge-pill badge-secondary pointer-on-hover"
        aria-hidden="true"
        onClick={() =>
          revertChanges(
            setNameEditOnState,
            setDisplayErrorState,
            setNewNameState,
          )
        }
        title={t("Revert Changes")}
      >
        <i className="fa fa-times" />
      </span>
    </>
  ) : (
    node.sensor_name
  );

  const spanStatus = node.active && !archived ? (
    <span className={`${style.status} ${style.active}`}>{t("Active")}</span>
  ) : (
    <span className={`${style.status} ${style.passive}`}>{t("Passive")}</span>
  );
  
  const resistance = Number.parseFloat(
    node.fields.find(item => item.sens_type === TSensorTypes.OHM)?.latest_data ?? ""
  );

  // Store various node status notifications
  const message = [];
  // Probe fault, calibrating or acclimatising status
  if (node.node_type === NodeTypes.IMSMk2) {
    if (node.probe_fault) {
      message.push(<p>{t("Probe error")}</p>);
    } else if (
      node.probe_type === ProbeTypes.VOC &&
      (node.calibrating || node.acclimatising)
    ) {
      //const estimate = node.calibrating ? 48 : acclimatiseEstimate(node.acclimatising)
      // Inverted logic because of typescript issues
      const estimate = node.acclimatising ? acclimatiseEstimate(node.acclimatising) : 48
      message.push(
        <div>
          {t("Acclimatising")}
          <InfoBubble>
            <div style={{ fontWeight: "normal", width: "13rem", textAlign: "left"}}>
              <p style={{ marginBottom: "0.5em"}}>
                <Trans>
                Data will be available after a <b>48h</b> acclimatisation process
                </Trans>
              </p>
              {node.active &&
                <p>
                  <Trans count={estimate} i18nKey="_acclimatisation_estimate">
                  Estimated remaining time: <b>{{count: estimate}}{"\u00a0"}hours</b>
                  </Trans>
                </p>
              }
            </div>
          </InfoBubble>
        </div>
      );
    }
  }

  // Show resistance out of range warning when resistance is <10kΩ or >1GΩ
  if (resistance < 0.01 || resistance > 1000) {
    message.push(
        <div>
          {t("Resistance out of range")}
          <InfoBubble>
            <div style={{fontWeight: "normal", width: "13rem", textAlign: "left"}}>
              <Trans i18nKey="_resistance_fault_info">
                <p style={{marginBottom: "0.5em"}}>
                  Normal working range is <b>0.01&nbsp;MΩ to 1000&nbsp;MΩ.</b> Possible
                  faults may include:
                </p>
                <p style={{marginBottom: "0.5em"}}>
                  <b>Low impedance</b> fault<br/>
                  Probe in contact with water, metal or salts.
                </p>
                <p>
                  <b>High impedance</b> fault<br/>
                  Probe not inserted or improperly inserted into material.
                </p>
              </Trans>
            </div>
          </InfoBubble>
        </div>
      );
  }
  // Full water bucket message and red alam (displayed in single line)
  if (node.node_type === NodeTypes.AD9 || node.node_type === NodeTypes.IMI)
  if (node.water_bucket_full || node.red_alarm) {
    const combinedMessage = [];
    if (node.water_bucket_full) combinedMessage.push(t("Water bucket full"));
    if (node.red_alarm) combinedMessage.push(t("Technical alarm"));
    message.push(<p>{combinedMessage.join(", ")}</p>);
  }

  const errorStyle = (
    (node.node_type === NodeTypes.IMI || node.node_type === NodeTypes.AD9) && 
    (node.water_bucket_full || node.red_alarm)
  ) || (
    node.node_type === NodeTypes.IMSMk2 &&
    node.probe_fault
  );
  
  return (
    <>
      {errorStyle && <div className="water-alarm"/>}
      <div className={style["sensor-header"]}>
        
        <div className={style.left}>
          { (node.node_type === NodeTypes.IMI || node.node_type === NodeTypes.AD9) && 
            !archived &&
            <PowerButton
              isOn={node.state_is_on || false}
              outlet={TSensorTypes.STE}  // the single (non-numbered) "outlet"
              node={node}
            />
          }
          { node.node_type === NodeTypes.AD9 && !archived &&
            <CloudControlButton node={node}/>
          }
        </div>
        <div className={style.right}>
          { (node.node_type === NodeTypes.IMRMk2
            || node.node_type === NodeTypes.CC4
            || node.node_type === NodeTypes.IMI
            || node.node_type === NodeTypes.AD9
            ) &&
            <ScheduleButton
              isScheduled={node.quiet_times.length > 0}
              node={node}
            />
          }
        </div>

        <label className={style.center}>
          {spanStatus}
          {node.active ? <NodeSignalStrength node={node} /> : null}
        </label>

        <div className={style.name}>
          {/* The name class contains padding to compensate for buttons in this div
              which need to be adjusted if buttons are changed
            */}
          <span>
            {t("Name")}: {SensorName}
          </span>
          { !nameEditOnState &&
            <span
              className={style.edit}
              onClick={() => toggleNameEdit(nameEditOnState, setNameEditOnState)}
              aria-hidden="true"
            >
              <i className="fa fa-pen" />
            </span>
          }
        </div>
        <div>{displayErrorState && nameEditOnState ? errorMessage : null}</div>
        <div>
          {t("Type")}: {getProductName(node.node_type, t)}
        </div>
        { node.node_type === NodeTypes.AD9 &&
          <div>
            {t("Model")}: {node.model !== null ? node.model : t("Unknown")} 
          </div>
        }
        { node.node_type === NodeTypes.IMSMk2 &&
          <div>
            {t('Probe type')}: {getProbeName(node.probe_type, t)}
          </div>
        }
        <div>
          {t("ID")}: {node.local_id}
        </div>
        { message.length > 0 &&
          <div
            className={errorStyle ? style["error-msg"] : style["info-msg"]}>
            <i className="fas fa-exclamation-triangle" />
            { message }
          </div>
        }
      </div>
    </>
  );
}
