import React, { ReactElement } from 'react';
import { useTranslation } from "react-i18next";

import style from './WeekdaySelector.module.scss';


interface Props {
  days: number;
  onChange?: (weekdays: number) => void;
  highlightRed?: boolean;
  disabled?: boolean;
  small?: boolean;
}

export default function WeekdaySelector({
  days,
  onChange,
  highlightRed,
  disabled,
  small,
}: Props): ReactElement {
  const { t } = useTranslation();
  
  const weekdays = [
    t("_monday"), 
    t("_tuesday"),
    t("_wednesday"), 
    t("_thursday"), 
    t("_friday"), 
    t("_saturday"),
    t("_sunday"),
  ];

  return (
    <div className={style.container}>
      { weekdays.map((weekday, index) =>
        <div 
          key={index}
          className={style.weekday}
          // this will check if corresponding bit for the weekday is 1 or 0
          // 1 << index will for example create 00000001 for Monday, 00000010 for Tueesday, etc
          // & will combine the two numbers leaving matching bits on
          // weekday-selected style is set when resulting number is a non-zero number
          data-selected={(days & (1 << index)) > 0}
          data-red={highlightRed}
          data-disabled={disabled}
          data-small={small}
          onClick={() => {
            if (disabled || !onChange) return;
            // perform XOR operation to selectedWeekdays with number where bit of the day
            // matching the index is set to 1, this will flip that specific bit
            onChange(days ^ (1 << index));
          }}
        >
          <label>{weekday}</label>
        </div>
      )}
    </div>
  );
}