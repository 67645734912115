import React, { ReactNode, useMemo } from "react";
import styles from "./EnergySavingNodeCard.module.scss";
import { useTranslation } from "react-i18next";
import { getProductName } from "../../utils/utility";
import { NodeTypes } from "../../types/generated_types";
import { useChart } from "../../contexts/ChartDataContext";
import { ScheduleDeviceNode } from "../../types/types";
import { WarningRow } from "../PortalModal/WarningRow";

interface Props {
    children: ReactNode;
    nodeId: number;
    nodeName: string;
    nodeType: NodeTypes;
}

export function EnergySavingNodeCard({
    children,
    nodeId,
    nodeName,
    nodeType,
}: Props) {
    const { t } = useTranslation();
    const { nodes } = useChart();

    const hasQuietTimes = useMemo(() => {
        var currentNode = nodes.find(e => e.local_id === nodeId.toString()) as ScheduleDeviceNode;
        if (currentNode === undefined)
            return false;
        return currentNode.quiet_times.length > 0;
    }, [nodes, nodeId]);

    return <div className={styles.container}>
        <div className={styles.titleHeader}>
            <h4>{t("{{label}}: {{value}}", {
                label: t("Name"),
                value: nodeName,
            })}</h4>
            <h5>{t("{{label}}: {{value}}", {
                label: t("Type") + "/" + t("ID"),
                value: getProductName(nodeType, t) + " " + nodeId,
            })}</h5>
            { hasQuietTimes &&
                <WarningRow>
                    {t("The product is on Time Schedule!")}
                </WarningRow>
            }
        </div>
        <div className={styles.childContainer}>
            {children}
        </div>
    </div>
}