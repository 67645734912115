import React from "react";
import style from "./IconButton.module.scss";

export enum Icon {
  GEAR,
  TIME,
}

function selectIcon(icon: Icon) {
  switch (icon) {
    case Icon.GEAR: return <i className="fa fa-gear" />
    case Icon.TIME: return <i className="fa fa-clock" />
  }
}

export interface IconButtonProps {
  icon: Icon,
  disabled?: boolean;
  title?: string;
  green?: boolean; // if more colors are needed, use implmenetation similar to ModalButton
  onClick: () => void
}

export function IconButton({
  icon,
  disabled,
  title,
  green,
  onClick,
}: IconButtonProps) {
  return <button
    className={style.button}
    onClick={onClick}
    disabled={disabled}
    data-green={green}
    title={title}
  >
    { selectIcon(icon) }
  </button>
}