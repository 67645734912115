import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ScheduleDeviceNode, QuietTime } from '../../../types/types';
import { TSensorTypes } from '../../../types/generated_types';
import style from './TimeRangeModal.module.scss';
import addIcon from './add_icon.svg';
import { Requester } from '../../../utils/Requester';
import { useChart } from '../../../contexts/ChartDataContext';
import toast from 'react-hot-toast';
import { getProductName } from '../../../utils/utility';
import { QuietTimeSelector, TIME_UNITS_PER_DAY } from '../QuietTimeSelector';
import PortalModalHeader from '../../PortalModal/PortalModalHeader';
import PortalModalContent from '../../PortalModal/PortalModalContent';
import PortalModalFooter from '../../PortalModal/PortalModalFooter';
import { ButtonStyle, ModalButton } from '../../PortalModal/ModalButton';
import { CloseButton } from '../../PortalModal/CloseButton';
import { WarningRow } from '../../PortalModal/WarningRow';


interface TimeRangeSelection {
  // Weekdays are represented by bits in single number from right to left (low bit being Monday)
  days: number,
  // Start time of selected range between 0 to 144 (minutes per day / 10)
  startTime: number,
  // duration
  duration: number,
  // IDs to find linked items
  linkNext: number,
  // Selected power sockets represented as bits - 1 selected, 0 deselected
  sockets: number,
}

interface Props {
  node: ScheduleDeviceNode;
  close: () => void;
}

export function TimeRangeModal({
  node,
  close,
}: Props): React.ReactElement {
  // List of time ranges selected
  const [selectionsMap, setSelectionsMap] = useState<Map<number, TimeRangeSelection>>(new Map());
  const [helpMode, setHelpmode] = useState(false);
  const { refreshQuietTimes, energySavingData } = useChart();
  const { t } = useTranslation();

  const hasNoEnergySaving = useMemo(() =>
    energySavingData
      .find(e => e.instNodeID === node.id)
      ?.patterns.every(e => e.id === 0),
    [energySavingData, node]);

  useEffect(() => {
    const map = new Map<number, TimeRangeSelection>();

    let index = 0;
    while (index < node.quiet_times.length) {
      let item = node.quiet_times[index];
      let nextItem = node.quiet_times[index + 1]; // should be undefined if out of bounds
      
      // determine if item can be merged with nextItem
      if (
        nextItem
        && nextItem.days === (((item.days << 1) & 127) | (item.days >> 6))
        && item.end_time/600 === TIME_UNITS_PER_DAY
        && nextItem.start_time === 0
        && nextItem.mask === item.mask
      ) {
        map.set(Date.now() + index, {
          days: item.days,
          startTime: item.start_time / 600,
          duration: (item.end_time - item.start_time + nextItem.end_time)/600,
          linkNext: 0,
          sockets: item.mask,
        });
        // increment index additional time since nextItem is merged and is to be skipped next iteration
        index++;
      } else {
        map.set(Date.now() + index, {
          days: item.days,
          startTime: item.start_time / 600,
          duration: (item.end_time - item.start_time)/600,
          linkNext: 0,
          sockets: item.mask,
        });
      }
      index++;
    }
    setSelectionsMap(map);
  }, [node]);

  function isSaveDisabled() {
    for (let item of Array.from(selectionsMap.values())) {
      if (!item.days) return true; // no days set will have value 0 which acts as false
      if (!item.sockets && socketCount > 0) return true; // same as days
    }
    return false;
  }

  const socketCount = node.fields.filter(item => [
    TSensorTypes.STE1,
    TSensorTypes.STE2,
    TSensorTypes.STE3,
    TSensorTypes.STE4,
  ].includes(item.sens_type)).length ?? 0;

  return (
    <>
      <PortalModalHeader 
        title={t("Time schedule")}
        close={close}
      />
      <PortalModalContent>
        <div className={style.header}>
          <label> 
            <Trans>Turn {{productName: getProductName(node.node_type, t)}} OFF when:</Trans>
          </label>
          <label className={style.timezone}>
            <Trans>Times in {{timezone: node.timezone?.join("/")}}</Trans>
          </label>
        </div>
        { !hasNoEnergySaving &&
          <WarningRow>
            {t("The product is on Energy Saving!")}
          </WarningRow>
        }
        <hr/>
        <div className={style.list}>
          { Array.from(selectionsMap.entries()).map(([key, value]) =>
            <div className={style['list-item-container']} key={key}>
              <QuietTimeSelector 
                days={value.days}
                startTime={value.startTime}
                duration={value.duration}
                sockets={value.sockets}
                socketCount={socketCount}
                helpMode={helpMode}
                onSelectionChange={(days, startTime, duration, sockets) => {
                  // NOTE: IMCS-199 could use functional setter
                  const newMap = new Map(selectionsMap);
                  newMap.set(key, {
                    days: days,
                    startTime: startTime,
                    duration: duration,
                    sockets: sockets,
                    linkNext: 0,
                  });
                  setSelectionsMap(newMap);
                }}
                onDelete={() => {
                  // NOTE: IMCS-199 could use functional setter
                  const map = new Map(selectionsMap);
                  map.delete(key);
                  setSelectionsMap(map);
                }}
              />
              <hr/>
            </div>
          )}
          <button className={style['add-button']}
            onClick={() => {
              // NOTE: IMCS-199 could use functional setter
              const map = new Map(selectionsMap);
              map.set(
                Date.now(),
                {
                  days: 0,
                  startTime: 40,
                  duration: 60,
                  linkNext: 0,
                  // set all sockets to 1: 0b1000 -1 = 0b111 as example for 3 sockets
                  // socket count count can't be less than one
                  sockets: Math.max((1 << socketCount) - 1, 1),
                }
              );
              setSelectionsMap(map);
            }}
          ><img src={addIcon} alt="Add"/>
            <Trans>Add Time</Trans>
          </button>
        </div>
      </PortalModalContent>
      <PortalModalFooter>
        { (isSaveDisabled() && helpMode) &&
          <label>
            <Trans>At least one day and socket must be selected</Trans>
          </label>
        }
        <ModalButton
          buttonStyle={isSaveDisabled() ? ButtonStyle.GRAY : undefined}
          onClick={() => {
            if(isSaveDisabled()) {
              setHelpmode(true);
              return;
            }
            
            setHelpmode(false);
            if (node) {
              const requestArray = new Array<QuietTime>();
              Array.from(selectionsMap.values()).forEach(value => {
                requestArray.push({
                  start_time: value.startTime * 600,
                  end_time: Math.min(value.startTime + value.duration, TIME_UNITS_PER_DAY) * 600,
                  days: value.days,
                  mask: value.sockets,
                });
                // if duration reaches over end of the day add entry for next day
                if(value.startTime + value.duration > TIME_UNITS_PER_DAY) {
                  requestArray.push({
                    start_time: 0,
                    end_time: (value.startTime + value.duration - TIME_UNITS_PER_DAY) * 600,
                    // days rotated left by one
                    days: ((value.days << 1) & 127) | (value.days >> 6),
                    mask: value.sockets,
                  })
                }
              });

              Requester.postQuietTimes({
                id: node.id,
                quiet_times: requestArray,
              })
              .then(response => {
                if (response.status === "error") throw response.message;
                toast.success(t("Saved").toString());
                refreshQuietTimes(node, requestArray);
                close();
              })
              .catch(() => toast.error(t("Something went wrong").toString()));
            }
          }}
        >
          <Trans>Submit</Trans>
        </ModalButton>
        <CloseButton close={close} />
      </PortalModalFooter>
    </>
  );
}