import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import logo from "../../images/logo_80px.png";

import style from "./Header.module.scss";
import styleBottom from "./HeaderBottom.module.scss";

import {
  DashboardMainOptions,
} from "./HeaderBottom";
import { Requester } from "../../utils/Requester";

enum Expandable {
  ADMIN,
  DASHBOARD,
  NONE,
}

export function StaticHeader(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [expandable, setExpandable] = useState<Expandable>(Expandable.NONE);
  const [burgerExpand, setBurgerExpand] = useState(false);
  const [languages, setLanguage] = useState<{ [key: string]: string }>({});

  // Pre-determine the selected page
  const isAdmin = history.location.pathname.startsWith("/dashboard/admin");
  const isAccount = history.location.pathname.startsWith("/dashboard/account");
  const isDashboard = !(isAdmin || isAccount);

  useEffect(() => {
    Requester.getLanguageList()
    .then(result => setLanguage(result))
    .catch(() => setLanguage({en: "English"}));
  }, []);

  // save in local cache the default selected language and use that one
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      void i18n.changeLanguage(lang);
    }
  }, [i18n, languages]);

  useEffect(() => {
    localStorage.setItem("lang", i18n.language);
  }, [i18n.language]);

  const liClassName = style.fade;
  return (
    <div className="fixed-top">
      <nav className={style.navbar} role="navigation">
        <div className={style.left}>
          <NavLink to={""}>
            <img src={logo} alt="Simplify®" />
          </NavLink>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={style.hamburger}
          onClick={() => setBurgerExpand(!burgerExpand)}
        >
          <div className={style.line} />
          <div className={style.line} />
          <div className={style.line} />
        </div>
        <ul
          className={`${style["nav-links"]} ${burgerExpand ? style.open : ""}`}
        >
          <li className={liClassName}>
            <div className={isDashboard ? style.selected : ""}>
              <NavLink
                to="/dashboard/list/active"
                className={styleBottom["hide-on-mobile"]}
              >
                <div className={style["nav-btn-wrapper"]}>
                  <i className="fas fa-chart-area mr-1" />
                  {t("Dashboard")}
                </div>
              </NavLink>
              <div
                tabIndex={0}
                role="button"
                className={style.carret}
                onClick={() =>
                  setExpandable(
                    expandable === Expandable.DASHBOARD
                      ? Expandable.NONE
                      : Expandable.DASHBOARD,
                  )
                }
              >
                <i className="fas fa-chart-area mr-1" />
                {t("Dashboard")}
                <i
                  className={`fas fa-caret-${
                    expandable === Expandable.DASHBOARD ? "down" : "right"
                  } ${styleBottom["show-on-mobile"]}`}
                />
              </div>
            </div>
          </li>
          <DashboardMainOptions
            aActiveClassName={style.active}
            liClassName={`${liClassName} ${style["sub-par-menu"]} ${
              expandable === Expandable.DASHBOARD ? "" : "d-none"
            }`}
          />
          <li className={liClassName}>
            <div className={`dropdown ${style.dropdown}`}>
              <div
                className={`btn-account d-flex ${style["nav-btn-wrapper"]}`}
                role="button"
                id="languageDropdown"
                data-toggle="dropdown"
              >
                <i className="fas fa-globe-europe mr-1" />
                {languages[i18n.language]}
                <div className="triangle-const-width">
                  <i
                    className={`fas fa-caret-right ${style["margin-reset"]}`}
                  />
                </div>
              </div>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                {Object.keys(languages).map((k) => (
                  <button
                    key={k}
                    type="button"
                    className="dropdown-item"
                    onClick={() => i18n.changeLanguage(k)}
                  >
                    {languages[k]}
                  </button>
                ))}
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <div
        id="cst-secondary-nav"
        className={`col-lg-12 hidden-xs hidden-sm shadow ${styleBottom["hide-on-mobile"]}`}
      >
        <div className="container-fluid">
          <ul className="nav nav-pills">
            <DashboardMainOptions liClassName={`nav-item nav-link ${style["nav-link-bottom"]}`} aActiveClassName="" />
          </ul>
        </div>
      </div>
    </div>
  );
}
