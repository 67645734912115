import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartConfig } from "../../types/types";
import { TSensorTypes } from "../../types/generated_types";

import style from "./ChartType.module.scss";
import { TranslateVisualSensorType } from "../Misc/TranslateVisualSensorType";
import { NumericInput } from "../Misc/NumericInput";

interface Props {
  selSensorTypes: Array<TSensorTypes>;
  updateChartConfig: (newConfig: ChartConfig) => void;
  secondaryAxis: TSensorTypes | undefined;
  yMaxMain: number | undefined;
  yMinMain: number | undefined;
  yMaxSecond: number | undefined;
  yMinSecond: number | undefined;
}

export function ChartConfigurator({
  selSensorTypes,
  updateChartConfig,
  secondaryAxis,
  yMaxMain,
  yMinMain,
  yMaxSecond,
  yMinSecond,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [newSecondaryAxis, setNewSecondaryAxis] = useState<TSensorTypes | undefined>(secondaryAxis);
  const [yMaxMainTemp, setYMaxMainTemp] = useState<number | undefined>(yMaxMain !== undefined ? yMaxMain : 100);
  const [yMinMainTemp, setYMinMainTemp] = useState<number | undefined>(yMinMain !== undefined ? yMinMain : 0);
  const [yMaxSecondTemp, setYMaxSecondTemp] = useState<number | undefined>(yMaxSecond !== undefined ? yMaxSecond : 100);
  const [yMinSecondTemp, setYMinSecondTemp] = useState<number | undefined>(yMinSecond !== undefined ? yMinSecond : 0);

  const [primaryMaxEnable, setPrimaryMaxEnable] = useState(yMaxMain !== undefined);
  const [primaryMinEnable, setPrimaryMinEnable] = useState(yMinMain !== undefined);

  const [secondMaxEnable, setSecondMaxEnable] = useState(yMaxSecond !== undefined);
  const [secondMinEnable, setSecondMinEnable] = useState(yMinSecond !== undefined);

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (secondaryAxis === undefined) {
      setNewSecondaryAxis(undefined);
    }
  }, [secondaryAxis]);

  useEffect(() => {
    setIsSaved(false);
  }, [newSecondaryAxis, yMaxMainTemp, yMinMainTemp, yMaxSecondTemp, yMinSecondTemp, primaryMaxEnable, primaryMinEnable, secondMaxEnable, secondMinEnable]);

  return (
    <>
      <div className={style["column-holder"]}>
        <div className={style.column}>
          <div className={style.title}>
            {t("Left y-axis")}
          </div>
          <div className={style.row}>
            <input
              onChange={(e) => setPrimaryMaxEnable(e.target.checked)}
              type="checkbox"
              className={style["min-max-checkbox"]}
              checked={primaryMaxEnable}
            />
            <div className={style.descriptor} onClick={() => setPrimaryMaxEnable(previous => !previous)}> {t("Max")}: </div>
            <NumericInput 
              disabled={!primaryMaxEnable}
              initialValue={yMaxMainTemp}
              onChange={(value) => setYMaxMainTemp(value)}
              />
          </div>
          <div className={style.row}>
            <input
              onChange={(e) => setPrimaryMinEnable(e.target.checked)}
              type="checkbox"
              className={style["min-max-checkbox"]}
              checked={primaryMinEnable}
            />
            <div className={style.descriptor} onClick={() => setPrimaryMinEnable(previous => !previous)}> {t("Min")}: </div>
            <NumericInput 
              disabled={!primaryMinEnable}
              initialValue={yMinMainTemp}
              onChange={(value) => setYMinMainTemp(value)}
            /> 
          </div>
        </div>

        <div className={`${style.column}`}>
          <div className={selSensorTypes.length > 1 ? "" : style["disabled-column"]}>
            <div className={style.title}>
              {t("Right y-axis")}
            </div>
            <div className={style.row}>
              <input
                onChange={(e) => setSecondMaxEnable(e.target.checked)}
                type="checkbox"
                className={style["min-max-checkbox"]}
                checked={secondMaxEnable}
              />
              <div className={style.descriptor} onClick={() => setSecondMaxEnable(previous => !previous)}> {t("Max")}: </div>
              <NumericInput 
                disabled={!secondMaxEnable}
                initialValue={yMaxSecondTemp}
                onChange={(value) => setYMaxSecondTemp(value)}
              /> 
            </div>
            <div className={style.row}>
              <input
                onChange={(e) => setSecondMinEnable(e.target.checked)}
                type="checkbox"
                className={style["min-max-checkbox"]}
                checked={secondMinEnable}
              />
              <div className={style.descriptor} onClick={() => setSecondMinEnable(previous => !previous)}> {t("Min")}: </div>
              <NumericInput 
                disabled={!secondMinEnable}
                initialValue={yMinSecondTemp}
                onChange={(value) => setYMinSecondTemp(value)}
              /> 
            </div>
            <div className={style.row}>
              <select
                name="secondaryAxisType"
                className={style["type-select"]}
                disabled={selSensorTypes.length <= 1}
                onChange={(e) => {
                  setNewSecondaryAxis(e.target.value !== "" ? e.target.value as TSensorTypes : undefined);
                }}
                value={newSecondaryAxis}
              >
                <option key="none" value="">
                  {t("Choose a type")}
                </option>
                {selSensorTypes.length <= 1 ? "" : selSensorTypes.map((el) => (
                  <option key={el} value={el}>
                    {TranslateVisualSensorType(el)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          { selSensorTypes.length < 2 &&
            <div className={style["padlock-wrapper"]}>
              <div className={style.padlock}>
                <div className={style.warning}>
                  {t("Select another type to unlock")}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          setIsSaved(true);

          const updatedConfig: ChartConfig = {};

          updatedConfig.secondaryAxis = newSecondaryAxis;

          if (primaryMaxEnable) {
            updatedConfig.yMaxMain = yMaxMainTemp;
          } else {
            updatedConfig.yMaxMain = undefined;
          }
          if (primaryMinEnable) {
            updatedConfig.yMinMain = yMinMainTemp;
          } else {
            updatedConfig.yMinMain = undefined;
          }
          if (secondMaxEnable) {
            updatedConfig.yMaxSecond = yMaxSecondTemp;
          } else {
            updatedConfig.yMaxSecond = undefined;
          }
          if (secondMinEnable) {
            updatedConfig.yMinSecond = yMinSecondTemp;
          } else {
            updatedConfig.yMinSecond = undefined;
          }

          updateChartConfig(updatedConfig);
        }}
      >
        {isSaved ? t("Saved") : t("Save changes")}
      </button>
    </>
  );
}
