import React, { ReactElement } from 'react';

import styles from './PortalModalHeader.module.scss';
import closeIcon from './close_icon.svg';

interface Props {
  title: string;
  close: () => void;
}

export default function PortalModalHeader({
  title,
  close,
}: Props): ReactElement{
  return (
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
      <button
        className={styles['close-button']} 
        onClick={() => close()}>
          <img 
            src={closeIcon}
            alt="Close"
          />
        </button>
    </div>
  );
}