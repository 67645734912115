import React from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import styles from "./MoistureIndicator.module.scss";

interface Props {
  wmeValue: number | null;
}

function getMoistureLevel(wmeValue: number | null, t: TFunction) {
  if (wmeValue === null) return {
    style: styles["na"],
    tooltip: t("n/a"),
  }

  if (wmeValue < 17) return { 
    style: styles["dry"],
    tooltip: t("Dry"),
  };
  if (wmeValue < 20) return {
    style: styles["moist"],
    tooltip: t("Between dry and wet"),
  };
  else return {
    style: styles["very-moist"],
    tooltip: t("Wet"),
  };
}

export function MoistureIndicator({
  wmeValue
}: Props) {
  const { t } = useTranslation();

  return <div className={styles.frame}>
    <abbr title={getMoistureLevel(wmeValue, t).tooltip}>
      <div className={
        `${styles["moisture-indicator"]} ${getMoistureLevel(wmeValue, t).style}`
      }/>
    </abbr>
  </div>
}