import React from "react";

interface Props {
  children: JSX.Element;
  tabIndex?: string;
  id: string;
  Large?: boolean;
  className?: string;
}

export function Modal({
  Large, children, id, tabIndex, className,
}: Props): React.ReactElement {
  const sizeProp = Large ? "modal-lg" : "";
  const tabIndexChecked = !isNaN(Number(tabIndex)) ? Number(tabIndex).valueOf() : 1;
  return (
    <>
      <div
        className="modal fade"
        style={{zIndex: 100000}} // overrides bootstrap z-index, ensures it is above navigation bar
        id={id}
        tabIndex={tabIndexChecked}
        role="dialog"
        aria-labelledby={`${id}Label`}
        aria-hidden="true"
      >
        <div className={`modal-dialog ${sizeProp} ${className !== undefined ? className : ""}`} role="document">
          {children}
        </div>
      </div>
    </>
  );
}
