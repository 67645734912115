import React from "react";
import { useTranslation } from "react-i18next";
import { useChart } from "../../contexts/ChartDataContext";
import style from "./NoData.module.scss";

interface Props {
  loadAllData: () => void;
  loadSensors: () => void;
}

export function NoData({
  loadAllData,
  loadSensors,
}: Props) {
  const { t } = useTranslation();
  const chart = useChart();
  
  const range_selected = chart.chartParams.visibleDates;
  
  let node_selected = false;
  
  const no_sensor_data = Object.values(chart.nodes).every(
    (node) => node.latest_data == null
  );

  if (!no_sensor_data) {
    for (const nodes of Object.values(chart.chartParams.nodes)) {
      if (nodes.length) {
        node_selected = true;
        break;
      }
    }
  }

  return (
    <div className={style["no-data-container"]}>
      <h2>
        { no_sensor_data ? t("No data has been uploaded yet")
          : !node_selected ? t("No sensors selected")
          : range_selected ? t("No data in this range")
          : !chart.chartParams.allData ? t("No data in past 2 weeks")
          : t("Nothing to show")
        }
      </h2>
      {!no_sensor_data &&
        <>
          <p>{t("Try this")}</p>
          <button
            className={style["no-data-load-button"]}
            type="button"
            onClick={() => {
              if (!node_selected) {
                loadSensors()
                chart.reloadNodes();
              } else {
                loadAllData();
              }
            }}
          >
            {!node_selected ? t("Select default sensors")
            : t("Load all data")}
          </button>
        </>
      }
  </div>
  );
}