import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { TSensorTypes } from "../../types/generated_types";
import { TranslateVisualSensorType } from "./TranslateVisualSensorType";

interface Props {
  sensorType: TSensorTypes;
};

export function tagDescription(
  sensorType: TSensorTypes, t: TFunction<string>
): string | undefined {
  switch (sensorType) {
    case TSensorTypes.DWT:
      return t("Dew point");
    case TSensorTypes.HML:
      return t("Hygrostat set-point");
    case TSensorTypes.HUM:
      return t("Relative humidity");
    case TSensorTypes.PDV:
      return t("Saturated Vapour Pressure");
    case TSensorTypes.PWR:
      return t("Power use/output");
    case TSensorTypes.TMP:
      return t("Temperature");
    case TSensorTypes.WCT:
      return t("Water content (absolute humidity)");
    case TSensorTypes.BATT:
      return t("Battery voltage");
    case TSensorTypes.GPK:
      return t("Mixing ratio");
    case TSensorTypes.STE:
      return t("Relay state");
    case TSensorTypes.STE1:
      return t("Outlet {{socketNumber}}", {socketNumber: 1});
    case TSensorTypes.STE2:
      return t("Outlet {{socketNumber}}", {socketNumber: 2});
    case TSensorTypes.STE3:
      return t("Outlet {{socketNumber}}", {socketNumber: 3});
    case TSensorTypes.STE4:
      return t("Outlet {{socketNumber}}", {socketNumber: 4});
    case TSensorTypes.VOC:
      return t("Total Volatile Organic Compounds level");
    case TSensorTypes.CO2:
      return t("Estimated CO₂ level");
    case TSensorTypes.IAQ:
      return t("Indoor Air Quality index");
    case TSensorTypes.OHM:
      return t("Resistance");
    case TSensorTypes.WME:
      return t("Wood Moisture Equivalent");
    case TSensorTypes.MC:
      return t("Moisture Content");
    case TSensorTypes.OHR:
      return t("Operational hours");
    case TSensorTypes.HEAT:
      return t("Heater state");
    case TSensorTypes.FMODE:
      return t("Fan mode (normal/continuous)");
    case TSensorTypes.SPEED:
      return t("Fan speed (normal/slow)");
    default:
      return undefined;
  }
}

export function TypeTag({sensorType}: Props): React.ReactElement {
  const { t } = useTranslation();
  const description = tagDescription(sensorType, t);

  if (!description) {
    return <>{TranslateVisualSensorType(sensorType)}</>;
  }
  return <abbr title={description}>{TranslateVisualSensorType(sensorType)}</abbr>;
}
