import React, { ReactElement} from "react";
import { NodeInfo } from "./NodeInfo";

import { DeviceNode } from "../../types/types";

interface Props {
  nodes: DeviceNode[];
}

export function NodeInfoManager({ nodes }: Props): ReactElement {

  return (
    <>
      {nodes.map((n) => (
        <NodeInfo
          node={n}
          key={n.id}
        />
      ))}
    </>
  );
}
