import React, { ReactElement, useEffect, useState} from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../types/types';
import { ConstraintEnum, NodeAlarmEndpointData} from '../types';
import MultiSelector from './MultiSelector';
import { Trans, useTranslation, TFunction } from 'react-i18next';
import { Requester } from '../../../utils/Requester';
import { DispatchMessage } from '../components/DispatchMessage';

import styles from './AlarmEditModal.module.scss'
import PortalModalFooter from '../../PortalModal/PortalModalFooter';
import PortalModalHeader from '../../PortalModal/PortalModalHeader';
import PortalModalContent from '../../PortalModal/PortalModalContent';
import { specialTypeName } from '../util/utils';
import { NumericInput } from '../../Misc/NumericInput';
import { AlarmEnum, NodeTypes, ProbeTypes, getUnit, supportedAlarmTypes } from '../../../types/generated_types';
import { CloseButton } from '../../PortalModal/CloseButton';
import { ModalButton } from '../../PortalModal/ModalButton';

function isAlarmSupported(
  nodeType: NodeTypes, 
  probeType: ProbeTypes | null, 
  alarmType: AlarmEnum
): boolean{
  const supportedTypeData = supportedAlarmTypes[nodeType];
  
  // supportedAlarmTypes[nodeType] will contain Array of AlarmEnum for nodes that have no probes
  // otherwise it contains an Object containing AlarmEnum arrays each accessed by ProbeTypes as key 
  if (supportedTypeData instanceof Array) {
    return supportedTypeData.includes(alarmType);
  } else {
    if (!probeType) return true // If probe type is not provided allow adding all alarms
    return supportedTypeData[probeType].includes(alarmType);
  }
}

function alarmTypeUnit(alarm_type: AlarmEnum): string {
  switch (alarm_type) {
    case AlarmEnum.RED:
      return "";
    case AlarmEnum.WFL:
      return "";
    case AlarmEnum.PAS:
      return "";
    default:
      return getUnit(alarm_type);
  }
}

function constraintText(constraint: ConstraintEnum, t: TFunction): string {
  switch (constraint) {
    case ConstraintEnum.GREATER_THAN:
      return t("greater than");
    case ConstraintEnum.LESSER_THAN:
      return t("less than");
  }
}

interface Props{
  nodes: Array<NodeAlarmEndpointData>;
  selectedNode?: NodeAlarmEndpointData;
  onClose: () => void;
  onSave: () => void;
}

export default function AlarmEditModal({
  nodes,
  selectedNode,
  onClose,
  onSave,
}: Props): ReactElement {

  // States are initialised on remount and rely on remounting to re-initialise
  const [selectedNodes, setSelectedNodes] = useState<Array<NodeAlarmEndpointData>>([]);
  const [selectedAlarmType, setSelectedAlarmType] = useState<AlarmEnum>(AlarmEnum.PAS);
  const [selectedContraint, setSelectedContraint] = useState<ConstraintEnum>(ConstraintEnum.GREATER_THAN);
  const [selectedDelay, setSelectedDelay] = useState<number | undefined>(0);
  const [selectedAlarmValue, setSelectedAlarmValue] = useState<number>();

  const { t } = useTranslation();

  useEffect(() => {
    if(selectedNode) setSelectedNodes([selectedNode]);
  }, [selectedNode]);


  // Can have "Value"? Also "Delay" and "Constraint".
  const hasValue = selectedAlarmType !== AlarmEnum.PAS &&
                   selectedAlarmType !== AlarmEnum.WFL &&
                   selectedAlarmType !== AlarmEnum.RED


  return (
    <div>
      <PortalModalHeader
        title={t("Add sensor alarm")}
        close={onClose}
      />
      <PortalModalContent narrow>

        <MultiSelector
          options={nodes.map(node => ({
            value:node.id.toString(), 
            label:node.node_name, 
            red:!isAlarmSupported(node.node_type, node.probe_type, selectedAlarmType),
          }))}
          defaultValue={selectedNode?.id.toString()}
          onChange={options => setSelectedNodes(
            options.map(option => nodes.find(node => node.id.toString() === option.value)!)
          )}
        />
        <hr/>
        <div className={styles.row}>
          <p className={styles.label}>
            {t('Type')}
            {
              nodes.some(node => 
                selectedNodes.includes(node) && !isAlarmSupported(
                  node.node_type, 
                  node.probe_type, 
                  selectedAlarmType
                )
              ) &&
              <abbr
                title={t("This alarm type can't be added to sensors that have a red background")}
                className={styles.exclamation}
              >
                <i className="fas fa-exclamation-triangle" />
              </abbr>
            } 
          </p>
          <Select 
            className={styles.dropdown}
            defaultValue={{
              value: selectedAlarmType,
              label: specialTypeName(selectedAlarmType, t),
            }}
            options={Object.keys(AlarmEnum).map(key => ({
                value: key,
                label: specialTypeName(key, t),
              })
            )}
            onChange={(option) => {
              if(option){
                setSelectedAlarmType((option as SelectOption<AlarmEnum>).value)
              }
            }}
          />
        </div>
        <hr/>
        { hasValue &&
          <>
            <div className={styles.row}>
              <p className={styles.label}>{t('Constraint')}</p>
              <Select
                className={styles.dropdown}
                defaultValue={{
                  value: selectedContraint, 
                  label: constraintText(selectedContraint, t),
                }}
                options={Object.values(ConstraintEnum).map(value => ({
                    value: value,
                    label: constraintText(value, t),
                  })
                )}
                onChange={(option) => {
                  setSelectedContraint((option as SelectOption<ConstraintEnum>).value);
                }}
              />
            </div>
            <hr/>
            <div className={styles.row}>
              <p className={styles.label}>{t('Value')}
                { alarmTypeUnit(selectedAlarmType) &&
                  <span className="pl-1 light-grey-text">
                    {`(${alarmTypeUnit(selectedAlarmType)})`}
                  </span>
                }
              </p>
              <div className={styles.input}>
              <NumericInput
                onChange={value => setSelectedAlarmValue(value)}
                />
              </div>
            </div>
            <hr/>
            <div className={styles.row}>
              <p className={styles.label}>
                {t('Alarm delay')}
                <span className="pl-1 light-grey-text">({t("hours")})</span>
              </p>
              <div className={styles.input}>
                <NumericInput
                  nonNegative
                  initialValue={selectedDelay}
                  onChange={value => setSelectedDelay(value)}
                />
              </div>
            </div>
            <hr/>
          </>
        }
        <DispatchMessage
          alarmType={selectedAlarmType}
          alarmTypeUnit={alarmTypeUnit(selectedAlarmType)}
          constraint={selectedContraint}
          delay={selectedDelay}
          value={selectedAlarmValue}
        />
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          disabled={
            selectedNodes.length < 1
            || selectedNodes.filter(node => isAlarmSupported(
              node.node_type, 
              node.probe_type,
              selectedAlarmType
            )).length < 1
            || (selectedAlarmValue === undefined && hasValue)
            || (selectedDelay === undefined && hasValue)
          }
          onClick={() => {
            if (selectedAlarmType === AlarmEnum.PAS) {
              Requester.createPasSensAlarm({
                installation_node_fk: selectedNodes.map(node => node.id),
              }).then(() => {
                onClose();
                onSave();
              });
            } else {
              if ( (selectedDelay === undefined && hasValue)
                || (selectedAlarmValue === undefined && hasValue)
              ) return;
              
              Requester.createDataSensAlarm({
                threshold_value: selectedAlarmValue ?? 0,
                constraints: selectedContraint,
                alarm_type: selectedAlarmType,
                delay_value: selectedDelay ?? 0,
                installation_node_fk: selectedNodes.map(node => node.id),
              }).then(() => {
                onClose();
                onSave();
              });
            }
          }}
        >
          <Trans>Submit</Trans>
        </ModalButton>
        <CloseButton close={onClose} />
      </PortalModalFooter>
    </div>
  );
}
