import React, { ReactElement } from "react";

import { useTranslation } from "react-i18next";

import signalStrengthGood from "../../images/node-signal-strength-1.svg";
import signalStrengthBad from "../../images/node-signal-strength-2.svg";

import { DeviceNode } from "../../types/types";
import style from "./NodeInfo.module.scss";

interface Props {
  node: DeviceNode;
}

export function NodeSignalStrength({
  node,
}: Props): ReactElement {
  const { t } = useTranslation();

  let temp;
  if (node.link !== null) {
    if (node.link >= 100) {
      temp = (
        <abbr title={t("Good signal")}>
          <img src={signalStrengthGood} alt="Good signal" className={`${style.icon} `} />
        </abbr>
      );
    } else {
      temp = (
        <abbr title={t("Poor signal")}>
          <img src={signalStrengthBad} alt="Poor signal" className={`${style.icon} `} />
        </abbr>
      );
    }
  } else {
    temp = (<div />);
  }
  return (temp);
}
