import React, { ReactElement, useCallback, useRef, useState } from 'react';

import style from './RangeSlider.module.scss';

interface Props {
  min: number;
  max: number;
  start: number;
  end: number;
  rangeSelected: (min: number, max: number) => void;
  onOverslideStart?: () => void;
  // if true then start value will not be confined to range
  uncapStart?: boolean;
  // if true then end value will not be confined to range
  uncapEnd?: boolean;
  // if true then start slider will not be visible, disables dragging
  disableStart?: boolean;
  // if true then end slider will not be visible, disables dragging
  disableEnd?: boolean;
}

export function RangeSlider({
  min,
  max,
  start,
  end,
  rangeSelected,
  uncapStart,
  uncapEnd,
  disableStart,
  disableEnd,
}: Props): ReactElement {
  const containInRange = useCallback((value: number): number => {
    return Math.min(Math.max(value, min), max);
  }, [min, max]);

  // onMouseMove events are used to calculate slider values when dragged, controlStart
  // and controlEnd states are used register if user is taking control of start or end slider
  const [controlStart, setControlStart] = useState<boolean>(false);
  const [controlEnd, setControlEnd] = useState<boolean>(false);
  
  // Reference used to calculate values based on bounding box if the slider track <div> element
  const slider = useRef<HTMLDivElement>(null);

  // Calculate slider value based on bounding box of the slider track under the input components
  function calculateValue(boundingBox: DOMRect, x: number) : number {
    const left = boundingBox.left
    const width = boundingBox.width
    // Calculate X position relative slider track
    const sliderRelativeX = x - left;
    // Get fraction where 0 is left side of slider and 1 is right (values can go below or over)
    const progressFraction = sliderRelativeX / width;
    // Calculate selected value based on minimum and maximum values provided
    return Math.round(progressFraction * (max - min) + min); 
  }

  // When input is being dragged onMouseMove events are fired even outside bounding box
  // therefore it is used instead of onChange as it allows to determine values that
  // exceed the range
  const handleMoveStart = (clientX: number) => {
    if (controlStart && slider.current) {
      let value = calculateValue(slider.current?.getBoundingClientRect(), clientX);
      // if start is not uncapped value is confined to range
      if (!uncapStart) value = containInRange(value)
      // prevents pushing end value past max if end slider is present
      if (!disableEnd) value = Math.min(value, max);
      rangeSelected(
        // start value
        value,
        // end value, if start is greater than end, push end value forward to start
        Math.max(value, end),
      );
    }
  }

  const handleMoveEnd = (clientX: number) => {
    if (controlEnd && slider.current) {
      let value = calculateValue(slider.current?.getBoundingClientRect(), clientX);
      if (!uncapEnd) value = containInRange(value);
      if (!disableStart) value = Math.max(value, min);
      rangeSelected(
        Math.min(value, start),
        value,
      );
    }
  }

  return (
    <div 
      className={style.container}>
      <input
        className={[style.handle, style['handle-a']].join(' ')}
        type="range"
        min={min}
        max={max}
        value={start}
        onMouseMove={event => handleMoveStart(event.clientX)}
        onTouchMove={event => handleMoveStart(event.touches[0].clientX)}
        
        onMouseDown={() => setControlStart(true && !disableStart)}
        onMouseUp={() => setControlStart(false)}
        onTouchStart={() => setControlStart(true && !disableStart)}
        onTouchEnd={() => setControlStart(false)}
        // Resolve warning where browser expects onChange when value is provided
        // Since mouse events are used for control onChange is not going to be implemented
        readOnly
        data-hide={disableStart}
      />
      <input
        // this slider functions same as above but with inverse values logic for the end slider
        className={[style.handle, style['handle-b']].join(' ')}
        type="range"
        min={min}
        max={max}
        value={end}
        onMouseMove={event => handleMoveEnd(event.clientX)}
        onTouchMove={event => handleMoveEnd(event.touches[0].clientX)}

        onMouseDown={() => setControlEnd(true && !disableEnd)}
        onMouseUp={() => setControlEnd(false)}
        onTouchStart={() => setControlEnd(true && !disableEnd)}
        onTouchEnd={() => setControlEnd(false)}
        readOnly
        data-hide={disableEnd}
      />
      <div
        ref={slider}
        className={style.slider}
      >
        <div
        // Marks selected range between handles
        className={style.selection}
        style={{
          left: `${(containInRange(start) - min) / (max - min) * 100}%`,
          right: `${100 - ((containInRange(end) - min) / (max - min) * 100)}%`,
        }}
      />
      </div>
    </div>
  );
}