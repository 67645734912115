import React, { createContext } from "react";
import { InstallationConfigModal } from "../components/SensorData/InstallationConfigModal";

import { useChart } from "./ChartDataContext";

interface Props {
  children: JSX.Element;
}

export interface InstallConfigModalContextInterface {
  installationConfigModal: string;
}

export const InstallConfigModalContext = createContext<InstallConfigModalContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in InstallConfigModalContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as InstallConfigModalContextInterface, {
    apply: () => {
      throw new Error("You must wrap your component in an InstallConfigModalContextProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an InstallConfigModalContextProvider");
    },
  }),
);

function InstallConfigModalContextProvider({ children }: Props): React.ReactElement {
  const chart = useChart();
  const installationConfigModal = "installationConfigModal";
  return (
    <InstallConfigModalContext.Provider
      value={{
        installationConfigModal,
      }}
    >
      {children}
      <InstallationConfigModal
        modalId={installationConfigModal}
        installation={chart.installation}
        device_id={chart.device_id}
        onUpdate={chart.refreshInstallationInfo}
      />
    </InstallConfigModalContext.Provider>
  );
}

const useInstallConfigModal = (): InstallConfigModalContextInterface => React.useContext(InstallConfigModalContext);

export { InstallConfigModalContextProvider, useInstallConfigModal };
