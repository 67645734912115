import React, { useState } from "react";
import styles from "./EnergySavingPatternSelector.module.scss";

import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Requester } from "../../utils/Requester";
import { EnergySavingPattern } from "./EnergySavingView";
import { EnergySavingVisualization } from "./EnergySavingVisualization";
import { isMessageResponse } from "../../utils/utility";
import { ModalButton } from "../PortalModal/ModalButton";
import { EnergySavingPatternDescription } from "./EnergySavingPatternDescription";
import { useChart } from "../../contexts/ChartDataContext";

interface Pattern {
  value: number;
  label: string;
}

enum Status {
  NONE,
  LOADING,
  SUCCESS,
  ERROR,
}

interface Props {
  patternId: number,
  pattern: EnergySavingPattern,
  nodeId: number,
  socketID: number;
  showSocketLabel: boolean;
}

/**
 * Returns `EnergySavingPattern` based on provided pattern ID
 * 
 * @param patternID pattern ID
 * @returns 
 */
function getPattern(patternID: number): EnergySavingPattern | undefined {
  // Has to be updated if patterns in backend/installation/models.py is changed
  const patterns = [{
    elapsed_time: 0,
    id: 1,
    init_pattern: [
      {"on": 1440, "off": 0, "x": 2},
      {"on": 300, "off": 60, "x": 8},
      {"on": 300, "off": 120, "x": 7},
    ],
    loop_pattern: [
      {"on": 120, "off": 120, "x": 24},
    ]
  },
  {
    elapsed_time: 0,
    id: 2,
    "init_pattern": [
      {"on": 1440, "off": 0, "x": 3},
      {"on": 300, "off": 60, "x": 16},
      {"on": 300, "off": 120, "x": 10},
    ],
    "loop_pattern": [
        {"on": 120, "off": 120, "x": 30},
    ]
  },
  {
    elapsed_time: 0,
    id: 3,
    "init_pattern": [
      {"on": 1440, "off": 0, "x": 7},
      {"on": 300, "off": 60, "x": 12},
      {"on": 300, "off": 90, "x": 18},
      {"on": 300, "off": 120, "x": 18},
    ],
    "loop_pattern": [
        {"on": 120, "off": 120, "x": 48},
    ]
  }]
  
  return patterns.find(pattern => pattern.id === patternID)
}

/**
 * Provides interface to select energy saving pattern and displays information about
 * selected pattern.
 * 
 * @param patternId selected pattern ID
 * @param pattern selected pattern
 * @param socketID power socket for which the pattern is selected, begins at 1, always has at least 1
 * @param nodeID node ID for which the socket belongs to
 * @param showSocketLabel socket label before dropdown is displayed when true
 * @returns 
 */
export function EnergySavingPatternSelector({
  patternId,
  pattern,
  nodeId,
  socketID,
  showSocketLabel,
}: Props) {
  const { t } = useTranslation();

  const { updateEnergySavingPattern } = useChart();

  const [status, setStatus] = useState<Status>(Status.NONE);
  const [selectedPatternId, setSelectedPatternId] = useState(patternId);
  const [savedPatternId, setSavedPatternId] = useState(patternId);
  const [selectedPattern, setSelectedPattern] = useState<EnergySavingPattern | undefined>(pattern);

  // List of pattern IDs and names
  const patterns: Array<Pattern> = [
    {value: 0, label: t("No Energy Saving")},
    {value: 1, label: t("{{mode_name}} – {{savings}}", {
      // Light Damage – 29% Energy Saving in 10 days running time
      mode_name: t("Light Water Damage"),
      savings: t("around {{n}}% Energy Saving in {{count}}+ days running time", {n: 29, count: 10}),
    })},
    {value: 2, label: t("{{mode_name}} – {{savings}}", {
      // Moderate Damage – 27% Energy Saving in 15 days running time
      mode_name: t("Moderate Water Damage"),
      savings: t("around {{n}}% Energy Saving in {{count}}+ days running time", {n: 27, count: 15}),
    })},
    {value: 3, label: t("{{mode_name}} – {{savings}}", {
      // Extreme Damage – 25% Energy saving in 28 days running time
      mode_name: t("Extreme Water Damage"),
      savings: t("around {{n}}% Energy Saving in {{count}}+ days running time", {n: 25, count: 28}),
    })},
  ];

  return <div className={styles["pattern-row"]}>
    <div className={styles.container}>
      { showSocketLabel &&
        <span>{t("Outlet {{socketNumber}}", {socketNumber: socketID})}</span>
      }
      <div className={styles["select-wrapper"]} style={{flex: "1"}}>
        <Select
          value={patterns.find(a => a.value === selectedPatternId)}
          options={patterns}
          isLoading={status === Status.LOADING}
          onChange={pattern => {
            // Reassures TypeScript that this is Pattern object
            pattern = pattern as Pattern;
            // Reassures Typescript that in next instruction pattern.value exists
            if (!(pattern && pattern.value !== undefined)) return;
            const value = pattern.value;

            setSelectedPatternId(value);
            setSelectedPattern(getPattern(value))
          }}
          isClearable={false}
          backspaceRemovesValue={false}
        />
        { [Status.SUCCESS, Status.ERROR].includes(status) &&
          <span
            className={styles.status}
            data-success={status === Status.SUCCESS}
            onAnimationEnd={() => setStatus(Status.NONE)}
          >{status === Status.SUCCESS ? t("Saved") : t("Error")}</span>
        }
      </div>
      <ModalButton
        onClick={() => {
          setStatus(Status.LOADING);
          Requester.selectEnergySaving({
            id: nodeId,
            pattern_id: selectedPatternId,
            ste_n: socketID,
          })
          .then(response => {
            if (!isMessageResponse(response)) {
              setStatus(Status.SUCCESS);
              setSavedPatternId(response.pattern.id);
              setSelectedPattern(response.pattern);
              updateEnergySavingPattern(nodeId, response.pattern, socketID)
            }
            else {
              setStatus(Status.ERROR);
            }
          })
        }}
        disabled={selectedPatternId === savedPatternId}
      >
        {selectedPatternId === savedPatternId ? t("Saved") : t("Save")}
      </ModalButton>
    </div>
    { selectedPattern && selectedPattern.id !== 0 && // also hide when empty pattern with ID of 0
      <>
        <EnergySavingPatternDescription
          pattern={selectedPattern}
        />
        <EnergySavingVisualization
          pattern={selectedPattern}
        />
      </>
    }
  </div >
}