import React from "react";
import styles from "./EnergySavingView.module.scss";

import { EnergySavingPatternSelector } from "./EnergySavingPatternSelector";
import { useTranslation } from "react-i18next";
import { EnergySavingNodeCard } from "./EnergySavingNodeCard";
import { NodeTypes } from "../../types/generated_types";
import { useChart } from "../../contexts/ChartDataContext";

export interface NodeEnergySavingData {
  instNodeID: number;
  nodeID: number;
  nodeType: NodeTypes;
  name: string;
  patterns: Array<EnergySavingPattern>;
}

export interface EnergySavingPatternSegment {
  on: number;   // Hours on
  off: number;  // Hours off
  x: number;    // Multiplier
}

export interface EnergySavingPattern {
  id: number;
  elapsed_time: number;
  init_pattern: Array<EnergySavingPatternSegment>;
  loop_pattern: Array<EnergySavingPatternSegment>;
}

export function EnergySavingView() {
  const { t } = useTranslation();

  const { energySavingData } = useChart();

  return <div className={styles.container}>
    { energySavingData.length === 0 &&
      <p>{t("No products that support Energy Saving configuration are added to this installation")}</p>
    }
    {energySavingData.map(node => 
      <EnergySavingNodeCard
        nodeId={node.nodeID}
        nodeName={node.name}
        nodeType={node.nodeType}
        key={node.nodeID}
      >
        <div className={styles["patterns-list"]}>
          { node.patterns.map((pattern, index) =>
              <EnergySavingPatternSelector
                key={index}
                patternId={pattern.id ? pattern.id : 0}
                pattern={pattern}
                nodeId={node.instNodeID}
                socketID={index + 1}
                showSocketLabel={node.patterns.length > 1}
              />
          )}
        </div>
      </EnergySavingNodeCard>
    )}
  </div>
}