import React from "react";
import style from "./PageBaseWrapper.module.scss";

interface Props {
  children: JSX.Element | null;
}

export function PageBaseWrapper({ children }: Props): React.ReactElement {
  return (
    <>
      <div className={style["header-wrapper"]}></div>
      {children}
    </>
  );
}
