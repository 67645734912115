import React, { ReactElement, useState } from "react";
import { SwatchesPicker } from "react-color";
import { ColorChanger, DisplayMode } from "../components/NodeInfo/ColorChanger";
import { Materials } from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import {
  toggleColorPicker,
  updateColorGlobal,
  updateColorLocal,
} from "./ColorChangerContainer.helper";

interface Props {
  sensorEnabled: boolean;
  color: string;
  onSensorColorChange: (colorArg: string, sensorType: TSensorTypes) => void;
  nodeID: number;
  sensorType: TSensorTypes;
  latestData: string | null;
  displayMode: DisplayMode;
  material?: Materials;
}

export function ColorChangerContainer({
  sensorEnabled,
  color,
  onSensorColorChange,
  nodeID,
  sensorType,
  latestData,
  displayMode,
  material,
}: Props): ReactElement {
  const [pickerColorLocalState, setPickerColorLocalState] = useState<string>(
    color,
  );
  const [pickerEnabledState, setPickerEnabledState] = useState<boolean>(false);
  const newColorStyle = {
    backgroundColor: pickerColorLocalState,
  };
  const oldColorStyle = {
    backgroundColor: color,
  };
  const buttonStyle = {
    backgroundColor: color,
  };

  return (
    <ColorChanger
      sensorEnabled={sensorEnabled}
      sensorType={sensorType}
      latestData={latestData}
      pickerEnabled={pickerEnabledState}
      newColorStyle={newColorStyle}
      oldColorStyle={oldColorStyle}
      buttonStyle={buttonStyle}
      toggleColorPicker={() =>
        toggleColorPicker(
          color,
          pickerEnabledState,
          setPickerEnabledState,
          setPickerColorLocalState,
        )
      }
      updateColorGlobal={() =>
        updateColorGlobal(
          pickerColorLocalState,
          pickerEnabledState,
          onSensorColorChange,
          sensorType,
          color,
          setPickerEnabledState,
          setPickerColorLocalState,
        )
      }
      displayMode={displayMode}
      material={material}
    >
      <SwatchesPicker
        color={pickerColorLocalState}
        onChangeComplete={(newColor) =>
          updateColorLocal(newColor, setPickerColorLocalState)
        }
      />
    </ColorChanger>
  );
}
