import React, { KeyboardEvent, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";

import styles from './Card.module.scss';
import removeIcon from './trash_icon.svg';
import cancelIcon from './cancel_icon.svg';
import editIcon from './edit_icon.svg';
import saveIcon from './save_icon.svg';
import { Email, InstAddEmailData, InstChangeEmailData } from "../../types";
import { MessageResponseData } from "../../../../types/requesterTypes";
import { Requester } from "../../../../utils/Requester";
import { useTranslation } from "react-i18next";

interface Props {
  email?: Email;
  installationPk: number;
  disabled?: boolean;
  onDelete?: MouseEventHandler;
  onSave: (email: Email) => void;
  onCancel: () => void;
}

export default function EmailRow({
  email,
  installationPk,
  disabled,
  onDelete,
  onSave,
  onCancel,
}: Props): React.ReactElement {
  const [emailAddress, setEmailAddress] = useState(email?.email || "");
  const [errorCode, setErrorCode] = useState<string>();
  const { t } = useTranslation();

  // Start in edit mode if `email` is `undefined` (adding new email),
  // but not when it's defined (modifying existing email)
  const [editMode, setEditMode] = useState<boolean>(email === undefined);

  const inputRef = useRef<HTMLInputElement | null>(null);
  
  const onCancelHandler = () => {
    setEditMode(false);
    onCancel();
  }

  // When edit mode ends (by cancellation),
  // modification buffer resets and error state get cleared
  useEffect(() => {
    if (!editMode)  // Edit mode ends (or `email` changes)
    {
      setErrorCode(undefined);  // clear error state
      setEmailAddress(email?.email || "");
    }
  }, [editMode, email]);

  // Reset error on typing
  useEffect(() => {
    setErrorCode(undefined);  // clear error state
  }, [emailAddress]);

  // Scroll to the email row if active
  useEffect(() => {
    if (inputRef.current === document.activeElement) {
      inputRef.current?.scrollIntoView({behavior: "smooth", block: "nearest"});
    }
  });

  // Prep the error message
  const errorValLookup: () => string | undefined = useCallback(() => {
    switch (errorCode) {
      case "D_EMAIL400":
        return t("Email already exists");
      case "I_EMAIL400":
        return t("Invalid email address");
      case undefined:
        return undefined; // No error
      default:
        return t("Failed to update email");
    }
  }, [errorCode, t]);
  const errorVal = errorValLookup();


  function postEmail() {
    // Cancel edit and bail out early if address is unmodified
    if (emailAddress === email?.email) {
      onCancelHandler();
      return;
    }

    // Add/Modify email
    let promise: Promise<MessageResponseData>;
    if (!email) {  // Add
      const emailData: InstAddEmailData = {
        email: emailAddress,
        installation_fk: installationPk,
      }
      promise = Requester.addAlarmEmail({ data: emailData });
    } else {       // Modify
      const emailData: InstChangeEmailData = {
        email_pk: email.email_pk,
        email: emailAddress,
        installation_fk: installationPk,
      }
      promise = Requester.changeAlarmEmail({ data: emailData });
    }

    // When request completes,
    // invoke onSave() callback on success; set error state otherwise
    promise.then(
      (result) => {
        if (result.status === "success") {
          // set primary key of the new email as result data
          const newEmail: Email = {email: emailAddress, email_pk: result.data};
          if (email) {
            setEditMode(false);  // end the edit mode
          }
          setEmailAddress("");  // remove entered email to continue editing
          onSave(newEmail);
        } else {
          setErrorCode(result.transCode || "?");  // "?" for unknown error
        }
      }
    )
  }

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      postEmail();
    }
  }

  const handleClick = () => {
    postEmail();
    inputRef.current?.focus();
  }

  return (
    <div className={styles.row}>
      {editMode ?
        <>
        <div className={styles["input-list"]}>
          <input
            ref={inputRef}
            className={`${styles.input} ${errorVal ? styles.show : ''}`}
            onKeyDown={onKeyPress}
            onChange={(event) => setEmailAddress(event.target.value.trim())}
            value={emailAddress}
            autoFocus
          />
          <label className={`${styles["input-label"]} ${styles.error} ${errorVal? '' : styles.hide}`}>{errorVal}</label>
        </div>
          <button 
            className={styles.button}
            onClick={handleClick}>
            <img alt='Save' src={saveIcon}/>
          </button>
          <button className={styles.button} onClick={onCancelHandler}>
            <img alt='Cancel' src={cancelIcon}/>
          </button>
        </>
        :
        <>
          <p className={styles.text}>{email?.email}</p>
          <button 
            className={styles.button}
            hidden={disabled}
            onClick={() => {
              setEditMode(true);
            }}>
            <img alt='Edit' src={editIcon}/>
          </button>
          <button
            className={styles.button}
            hidden={disabled}
            onClick={onDelete}
          >
            <img alt='Delete' src={removeIcon}/>
          </button>
        </>
      }
    </div>
  );
}
