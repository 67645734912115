import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
  samplingRate: number;
}

export function SamplingRateUpdateMessage({samplingRate} : Props){
  const { t } = useTranslation();
  const samplingRateMinutes = Math.max((samplingRate / 60), 10);
  /*
    Since sampling rate is bottlenecked by the data upload interval it cannot
    be less than 10 minutes, hence we use max() to meet this condition
  */ 

  return (
    <p>
      {t("The action will be scheduled for the next data exchange in up to 10 minutes.")}
      <br />
      {samplingRateMinutes !== 60
        ? t("Data will update within {{count}} minute.", { count : samplingRateMinutes })
        : t("Data will update within 1 hour.")}
    </p>
  );
}
