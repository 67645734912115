import React, { ReactNode } from "react";
import styles from "./TabsContainer.module.scss";

interface Props {
  children: ReactNode,
}

/**
 * Container that provides the parent styling necessary for `<TabLink>` elements.
 * It is recommended to only add `<TabLink>` elements as children to this
 * container (or other components that share behavior) and to have
 * `<ContentBox>` or a similar element directly after this container - this
 * container will slightly overlap the following container to create seamless
 * background between selected tab and following container.
 */
export function TabsContainer({
  children,
}: Props) {
  return <div className={styles.container}>
    {children}
  </div>
}