import React from "react";
import styles from "./Card.module.scss";

interface Props {
  children: string,
}

export function NoAlarmsRow({
  children,
}: Props) {
  return <p className={`${styles.row} ${styles.text}`} style={{
    color: "lightgray",
  }}>{children}</p>
}