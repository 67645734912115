import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNonChartableRow } from "../utils/utility";
import { TranslateVisualSensorType } from "../components/Misc/TranslateVisualSensorType";
import { DeviceNode } from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import style from "./SensorCheckboxContainer.module.scss";

interface Props {
  nodes: Array<DeviceNode>;
  sensorToggle: (checkbox: Array<TSensorTypes>, state: boolean) => void;
}

// Checks if all sensors of a single type have been selected
export function AllSensorCheck(type: TSensorTypes, nodes: Array<DeviceNode>): boolean {
  return nodes
    .every(
      (item) => item.fields
        .filter((sensor) => sensor.sens_type === type)
        .every((sensor) => sensor.enabled),
    );
}

function sensorGen(nodes: Array<DeviceNode>): Array<TSensorTypes> {
  return nodes
    .flatMap((item) => item.fields)
    .map((fields) => fields.sens_type)
    .filter((val, index, array) => array.indexOf(val) === index) // only keeps unique entries
    .filter((sensorType) => !isNonChartableRow(sensorType)) // excludes non-chartable sensorTypes
    .sort();
}

export function SensorCheckboxContainer({
  nodes,
  sensorToggle,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const sensors = useMemo(() => sensorGen(nodes), [nodes]);

  const [checkboxState, setCheckboxState] = useState<Array<boolean>>(sensors.map((item) => AllSensorCheck(item, nodes)));

  useEffect(() => {
    const tempCheckboxState = sensors.map((item) => AllSensorCheck(item, nodes));
    setCheckboxState(tempCheckboxState);
  }, [nodes, setCheckboxState, sensors]);

  function checkboxOnChange(item: TSensorTypes, state: boolean): void {
    // turns the specific sensor on
    const sensorArray = [item];
    sensorToggle(sensorArray, state);
  }

  return (
    <>
      <div className={`${style["sensor-checkbox-checker"]}`}>
        <div className={`${style["checkbox-container"]}`}>
          {sensors.map((item, index) => (
            <div key={item}>
              {TranslateVisualSensorType(item)}
              <input
                type="checkbox"
                checked={checkboxState[index]}
                className={`${style["checkbox-style"]}`}
                onChange={(e) => checkboxOnChange(item, e.target.checked)}
              />
            </div>
          ))}
        </div>
        <div className={`${style["button-box"]}`}>
          <button
            type="button"
            className={`${style["sel-button"]}`}
            onClick={() => sensorToggle(sensors, true)}
          >
            {t("Select All")}
          </button>
          <button
            type="button"
            className={`${style["sel-button"]}`}
            onClick={() => sensorToggle(sensors, false)}
          >
            {t("Deselect All")}
          </button>
        </div>
      </div>
    </>
  );
}
