import React from "react";

interface Props {
  id: string,
  close?: boolean,
  children: JSX.Element | string,
}

export function ModalHeader({ children, close, id }: Props): React.ReactElement {
  return (
    <div className="modal-header">
      <h5 className="modal-title" id={id}>
        {children}
      </h5>
      {close ? (
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}
    </div>
  );
}
