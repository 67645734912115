import React from "react";
import { StaticHeader } from "./StaticHeader";
import i18n from "../../utils/language-layer";


export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <StaticHeader />
          <div className="alert alert-danger" style={{ marginTop: "10rem" }}>
            <h1>{i18n.t("Something went wrong.")}</h1>
            <h3>{i18n.t("If you believe this should not have happened then let us know!")}</h3>
            <h5>{i18n.t("Please try reloading the page.")}</h5>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}
