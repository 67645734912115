import React from "react";

import styles from "./Checkbox.module.scss";

interface Props {
  checked: boolean,
  onClick: () => void;
  highlightRed?: boolean;
}

export function Checkbox({
  checked,
  onClick,
  highlightRed,
}: Props) {
  return <div
      className={styles.checkbox}
      // data props used for conditional styling
      data-checked={checked}
      data-highlightRed={highlightRed}
      onClick={() => onClick()}
    >
    { checked &&
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 9L4 6L2 8L7 13L15 5L13 3L7 9Z" fill="white"/>
      </svg>
    }
  </div>
}