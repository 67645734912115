import React, { ReactNode } from "react"
import styles from "./InfoBanner.module.scss"

interface Props {
  children: ReactNode
}

export function InfoBanner({
  children,
}: Props) {
  return <div
    className={styles.banner}
  >{children}</div>
}