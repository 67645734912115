import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children: string | JSX.Element;
  closeFooter: boolean;
  closeUpper: boolean;
  modalHeader: string | JSX.Element;
  modalFooter?: string | JSX.Element;
  id: string;
  onClose?: () => void;
}

export function Modal({
  children,
  closeFooter,
  closeUpper,
  id,
  modalHeader,
  modalFooter,
  onClose,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      style={{zIndex: 100000}} // overrides bootstrap z-index, ensures it is above navigation bar
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
      onClick={() => {if (onClose) onClose()}}
    >
      <div 
        className="modal-dialog modal-lg" 
        role="document"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}Label`}>
              {modalHeader}
            </h5>
            {closeUpper ? (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {if (onClose) onClose()}}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            ) : null}
          </div>
          <div className="modal-body">{children}</div>
          {closeFooter ? (
            <div className="modal-footer">
              {modalFooter}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("Close")}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
