import React, { MouseEventHandler } from 'react';

import styles from './Card.module.scss';
import addIcon from './add_icon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: MouseEventHandler;
}

export default function AddAlarmRow({
  onClick,
}: Props): React.ReactElement{

  const { t } = useTranslation(); 

  return (
    <div 
      className={`${styles.row} ${styles.clickable}`}
      onClick={onClick}>
      <img alt='' className={styles.icon} src={addIcon}/>
      <p className={styles.text}>
        {t("Add alarm")}
      </p>
    </div>
  );
}