import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConstraintEnum } from "../types";
import { AlarmEnum } from '../../../types/generated_types';

interface Props {
  alarmType: AlarmEnum;
  constraint: ConstraintEnum;
  value?: number;
  alarmTypeUnit: string;
  delay?: number;
}

function constraintDispatchText(
  selectedConstraint: ConstraintEnum,
  t: (text: string) => string,
): string {
  switch(selectedConstraint){
    case ConstraintEnum.GREATER_THAN:
      return t("greater than");
    case ConstraintEnum.LESSER_THAN:
      return t("less than");
  }
}

export function DispatchMessage({
  alarmType,
  alarmTypeUnit,
  constraint,
  delay,
  value,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const message = useMemo(() => {
    /* WFL, RED, PAS have their special messages
     * and care none about delay/value/constraint
     */
    if (alarmType === AlarmEnum.WFL) {
      return (
        <span>
          <Trans>
            Alert will be dispatched when <b>water bucket is full</b>
          </Trans>
        </span>
      );
    }
    if (alarmType === AlarmEnum.RED) {
      return (
        <span>
          <Trans>
            Alert will be dispatched when <b>technical alarm is on</b>
          </Trans>
        </span>
      );
    }
    if (alarmType === AlarmEnum.PAS) {
      return (
        <span>
          <Trans>
            Alert will be dispatched when sensor <b>goes passive</b>
            {/* TODO: Get the value from useSettings() context */}
          </Trans>
        </span>
      );
    }
    /* Othewise `delay` and `value` are both required */
    if (delay === undefined || value === undefined) {
      return <span><Trans>All fields should be filled in</Trans></span>;
    }
    /* Message for types with units */
    if (alarmTypeUnit) {
      return (delay !== 0) ? ( /* for non-zero delay */
        <span>
          <Trans i18nKey="_dispatch_msg_with_delay" count={delay}>
            Alert will be dispatched
            when <b>{{sensor: alarmType}}</b> remain <b>{{
              greater_or_less_than: constraintDispatchText(constraint, t)
            }}
            </b> {{value}} {{unit: alarmTypeUnit}} for {{count: delay}} hour
          </Trans>
        </span>
      ) : (  /* for zero delay */
        <span>
          <Trans i18nKey="_dispatch_msg_zero_delay">
            Alert will be dispatched when <b>{{sensor: alarmType}}</b> is <b>{{
              greater_or_less_than: constraintDispatchText(constraint, t)
            }}
            </b> {{value}} {{unit: alarmTypeUnit}}
          </Trans>
        </span>
      );
    }
    /* Message for types without units (just IAQ for now) */
    return (delay !== 0) ? ( /* for non-zero delay */
      <span>
        <Trans i18nKey="_dispatch_msg_unitless_with_delay" count={delay}>
          Alert will be dispatched when <b>{{sensor: alarmType}}</b> level
          remains <b>{{
            greater_or_less_than: constraintDispatchText(constraint, t)
          }}
          </b> {{value}} for {{count: delay}} hour
        </Trans>
      </span>
    ) : (
      <span>
        <Trans i18nKey="_dispatch_msg_unitless_zero_delay">
          Alert will be dispatched when <b>{{sensor: alarmType}}</b> level
          is <b>{{
            greater_or_less_than: constraintDispatchText(constraint, t)
          }}
          </b> {{value}}
        </Trans>
      </span>
    );
  }, [alarmType, alarmTypeUnit, constraint, delay, value, t]);

  return (
    <p className="mb-0 mt-3 text-center pb-5">
      {message}
    </p>
  );
}
