import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./site.css";
import "./v2_remake.css";

import { HeadlessReportPage } from "./containers/HeadlessReportPage";
import { App } from "./App";
import { SpinnyThingy } from "./components/Misc/SpinnyThingy";
import { PolicyPage } from "./containers/PolicyPage";

ReactDOM.render(
  <Suspense fallback={(
    <SpinnyThingy />
    )}
  >
    <Router>
      <Switch>
        <Route path="/policy" render={() => <PolicyPage />} />
        <Route exact path="/headless/:id" component={HeadlessReportPage} />
        <Route
          exact
          path=""
          component={App}
        />
      </Switch>
    </Router>
  </Suspense>,
  document.getElementById("root"),
);
