import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import style from './SetpointModal.module.scss';
import { Requester } from '../../../utils/Requester';
import toast from 'react-hot-toast';
import { useChart } from "../../../contexts/ChartDataContext";

import { PointSlider } from '../PointSlider';
import PortalModalHeader from '../../PortalModal/PortalModalHeader';
import PortalModalContent from '../../PortalModal/PortalModalContent';
import PortalModalFooter from '../../PortalModal/PortalModalFooter';
import { CloseButton } from '../../PortalModal/CloseButton';
import { ModalButton } from '../../PortalModal/ModalButton';
import { RadioButtonSelector } from '../../Misc/RadioButtonSelector';
import { SelectorRow } from '../../PortalModal/SelectorRow';
import { WarningRow } from '../../PortalModal/WarningRow';
import { getProductName } from '../../../utils/utility';
import { DeviceNodeAD9 } from '../../../types/types';
import { InfoBubble } from '../../Misc/InfoBubble';
import { SamplingRateUpdateMessage } from '../SamplingRateUpdateMessage';

interface Props {
  node: DeviceNodeAD9;
  close: () => void;
}

enum Mode {
  RH,
  MANUAL,
  DEWPOINT,
}

const RANGE: [number, number] = [30, 99];

export function SetpointModal({
  node,
  close,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const [hmlValue, setHmlValue] = useState<number>(RANGE[0]);
  const [selectedMode, setSelectedMode] = useState<Mode>();
  const { refreshNodes } = useChart();
  const samplingRate = useChart().installation.sampling_rate;

  useEffect(() => {
    Requester.getDehumidifierMode(node.id)
    .then(res => {
      setSelectedMode(
        res.dew_point ? Mode.DEWPOINT :
        res.hml !== null ? Mode.RH : Mode.MANUAL
      )
      setHmlValue(res.hml || res.prev_hml || RANGE[0]);
    });
  }, [node]);

  return (
    <div className={style.form}>
      <PortalModalHeader
        title={t("Dehumidifier Mode")}
        close={close}
      />
      <PortalModalContent>
        { selectedMode !== undefined &&
          <>
            <SelectorRow
              label={t("Dehumidifier control mode:")}
            >
              <RadioButtonSelector
                options={[
                  { value: Mode.RH,
                    label: t("Relative humidity mode"),
                    disabled: selectedMode === Mode.DEWPOINT
                  },
                  { value: Mode.MANUAL,
                    label: t("Manual (continuous) mode"),
                    disabled: selectedMode === Mode.DEWPOINT
                  },
                  { value: Mode.DEWPOINT,
                    label: t("Dew point mode"),
                    disabled: selectedMode !== Mode.DEWPOINT,
                    additionalElement: selectedMode === Mode.DEWPOINT ?
                      <InfoBubble>
                        <p style={{ whiteSpace: "nowrap" }}>
                          {t("Dew point mode is locked by the dew point sensor in the AD9")}
                        </p>
                      </InfoBubble> :
                      <InfoBubble>
                        <p style={{ whiteSpace: "nowrap" }}>
                          {t("Dew point mode can only be enabled by attaching the dew point sensor to the AD9")}
                        </p>
                      </InfoBubble>
                  },
                ]}
                value={selectedMode}
                onChange={value => setSelectedMode(value as Mode)}
              />
            </SelectorRow>
            <hr/>
            <div style={{color: selectedMode !== Mode.RH ? "lightgray" : ""}}>
              <SelectorRow
                label={t("Set relative humidity level:")}
                >
                { selectedMode === Mode.RH
                  ? t("Activated at {{value}}%", {value: hmlValue})
                  : t("n/a")
                }
              </SelectorRow>
              <PointSlider
                range={RANGE}
                value={hmlValue}
                onChange={setHmlValue}
                enabled={selectedMode === Mode.RH}
              />
            </div>
            <br/>
            { node.active ?
              <SamplingRateUpdateMessage
                samplingRate={samplingRate}
              />
              :
              <WarningRow>
                {getProductName(node.node_type, t)} {t("is currently")} <strong>{t("passive")}</strong>.
                <br />
                {t("The action will be scheduled to happen when it comes online.")}
              </WarningRow>
            }
          </>
        }
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          onClick={() => {
            Requester.setDehumidifierMode({
              id: node.id,
              hml: selectedMode === Mode.RH ? hmlValue : null,
            })
            .then(() => {
              toast.success(t("Saved").toString());
              refreshNodes();
            })
            .catch(() => toast.error(t("Something went wrong").toString()));
            close();
          }}
          disabled={selectedMode === undefined || selectedMode === Mode.DEWPOINT}
        >
          <Trans>Submit</Trans>
        </ModalButton>
        <CloseButton close={close} />
      </PortalModalFooter>
    </div>
  );
}