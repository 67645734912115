import { TSensorTypes } from "../../types/generated_types";

export function TranslateVisualSensorType(sensorType: TSensorTypes): string {
  switch (sensorType) {
    case TSensorTypes.STE1:
      return "1";
    case TSensorTypes.STE2:
      return "2";
    case TSensorTypes.STE3:
      return "3";
    case TSensorTypes.STE4:
      return "4";
    case TSensorTypes.CO2:
      return "eCO2";  // "e" prefixed to emphasise the estimation
    case TSensorTypes.VOC:
      return "TVOC";
    case TSensorTypes.HML:
      return "SET";
    default:
      return sensorType;
  }
}
