import React from "react";

import { useTranslation } from "react-i18next";

import { NodeInfoHeader } from "./NodeInfoHeader";
import { DeviceNode } from "../../types/types";
import { SensorRow } from "./SensorRow";

import style from "./NodeInfo.module.scss";
import { useChart } from "../../contexts/ChartDataContext";
import { NodeTypes } from "../../types/generated_types";

interface Props {
  node: DeviceNode;
}

export function NodeInfo({
  node,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const chart = useChart();
  const { updateNodeName, updateSensorColor, sensorCheckboxToggle } = useChart();
  const archived = !!chart.installation.archived_time;
  return (
    <div
      className={`${style["node-info-box"]} ${
        node.active && !archived ? style["sensor-active"] : ""
      }`}
    >
      <NodeInfoHeader
        node={node}
        updateSensorName={(newName: string) => updateNodeName(node.id, newName)}
      />
      <div className={style.body}>
        {node.latest_data === null ? (
          <div className={style["no-data"]}>
            <div>({t("NO DATA")})</div>
          </div>
        ) : (
          node.fields.map((sensor) => {
            if (
              node.node_type === NodeTypes.AD9 &&
              node.model?.endsWith('S') &&
              sensor.sens_type === "SPEED"
            ) {
              return (
                null
              );
            }
            else if (
              node.node_type === NodeTypes.AD9 &&
              !node.model?.endsWith('S') &&
              sensor.sens_type === "HEAT"
            ) {
              return (
                null
              );
            }
            else {
              return (
                <SensorRow
                  dbId={node.id}
                  sensor={sensor}
                  node={node}
                  key={sensor.front_end_name}
                  onCheckboxChange={(e) =>
                    sensorCheckboxToggle(
                      node.id,
                      e.target.checked,
                      sensor.sens_type,
                    )
                  }
                  onSensorColorChange={(color, sensorType) =>
                    updateSensorColor(node.id, color, sensorType)
                  }
                  isArchived={archived}
                  latestData={sensor.latest_data}
                />
              );
            }
          })
        )}
      </div>
    </div>
  );
}
