import { AlarmEnum, NodeTypes, ProbeTypes } from "../../types/generated_types";

export interface SelectObject<K> {
  value: K;
  label: K;
}

export enum ConstraintEnum {
  GREATER_THAN = ">",
  LESSER_THAN = "<",
}

export interface AlarmTypes {
  alarm_types: Array<AlarmEnum>;
  alarm_constraints: Array<ConstraintEnum>;
}

export interface AlarmData {
  id: number;
  threshold_value?: number;
  constraints?: ConstraintEnum;
  delay_value?: number;
  alarm_type: AlarmEnum;
}

export interface NodeAlarmEndpointData {
  id: number;
  node_name: string;
  node_type: NodeTypes;
  probe_type: ProbeTypes;
  sensor_256_id: number;
  node_alarms: Array<AlarmData>;
}

export interface InstalAndAlarmPk {
  pk: number;
  alarm_id: number;
}

export interface NodeAndAlarmId {
  node_id: number;
  alarm_id: number;
}

export interface Email {
  email: string;
  email_pk: number;
}

export interface InstalAndEmailPk {
  pk: number;
  email_id: number;
}

export interface ConfigInstAlarmParams {
  alarm_type: AlarmEnum;
}

export interface ConfigDataSensAlarmParams {
  threshold_value: number | undefined;
  constraints: ConstraintEnum | undefined;
  alarm_type: AlarmEnum ;
  delay_value: number;
}

export interface InstAddEmailData {
  installation_fk: number;
  email: string;
}

export interface InstChangeEmailData extends InstAddEmailData {
  email_pk: number;
}

export interface AlarmsLogsSensorData {
  id: number;
  alarm_type: AlarmEnum;
  sensor_256_id: number;
  sensor_name: string;
  time_period: string;
  alarm_threshold: number;
  constraints: ConstraintEnum;
  alarm_delay?: number; // Only for data alarms
  real_value: string;
}

export interface AlarmsLogsInstalData {
  id: number;
  alarm_type: AlarmEnum;
  installation_last_online: string;
  detected_time: string;
}

export interface BootstrapModal extends JQuery<HTMLElement> {
  modal: (arg: string) => void;
}

export interface AlarmInstallation {
  pk: number;
  name: string;
  unique_id: string;
  email_count: number;
  alarm_count: number;
}
