import React from "react";
import styles from "./RadioButtonSelector.module.scss";

interface RadioOption {
  value: string | number // types accepted by input
  label: string
  disabled?: boolean
  additionalElement?: JSX.Element
}

interface Props {
  value: string | number
  options: Array<RadioOption>
  onChange: (value: string | number) => void
}

export function RadioButtonSelector({
  value,
  options,
  onChange,
}: Props) {
  return <div className={styles.container}>
    { options.map(option => 
      <label
        className={styles.option}
        onClick={(option.disabled) ? undefined : () => onChange(option.value)}
        style={(option.disabled) ? {color: "lightgray"} : {}}
      >
        <input
          type="radio"
          name="radio"
          value={option.value}
          checked={value === option.value}
          disabled={option.disabled}
        />
        {option.label}
        {option.additionalElement}
      </label>
    )}
  </div>
}