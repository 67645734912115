import { TFunction } from "react-i18next";
import { Requester } from "../../../utils/Requester";
import { TranslateVisualSensorType } from "../../Misc/TranslateVisualSensorType";
import {
  AlarmsLogsInstalData,
  AlarmsLogsSensorData,
  AlarmTypes,
  SelectObject,
} from "../types";
import { AlarmEnum, TSensorTypes } from "../../../types/generated_types";

export function specialTypeName(arg: string, t: TFunction<string>): string {
  switch (arg.toUpperCase()) {
    case AlarmEnum.WFL: {
      return t("Full water bucket");
    }
    case AlarmEnum.RED: {
      return t("Technical alarm");
    }
    case AlarmEnum.PAS: {
      return t("Passive");
    }
    default: {
      return TranslateVisualSensorType(arg as TSensorTypes);
    }
  }
}

export function isAlarmsLogsSensorData(
  arg: AlarmsLogsSensorData | AlarmsLogsInstalData,
): arg is AlarmsLogsSensorData {
  return (
    (arg as AlarmsLogsSensorData).alarm_threshold !== undefined
    && (arg as AlarmsLogsSensorData).constraints !== undefined
    && (arg as AlarmsLogsSensorData).real_value !== undefined
    && (arg as AlarmsLogsSensorData).sensor_name !== undefined
    && (arg as AlarmsLogsSensorData).time_period !== undefined
  );
}
export function isAlarmsLogsInstalData(
  arg: AlarmsLogsSensorData | AlarmsLogsInstalData,
): arg is AlarmsLogsInstalData {
  return !isAlarmsLogsSensorData(arg);
}

export function createSelectObj<K>(arg: K): SelectObject<K> {
  return { value: arg, label: arg };
}

// This function fetches all the defined alarm types from the backend
export async function fetchAlarmTypes(): Promise<AlarmTypes> {
  const result = await Requester.getAlarmTypes();
  result.data.alarm_types.push(AlarmEnum.PAS);
  return result.data;
}

// This regex checks if the input is in the correct format, they can also be negative numbers
// This selects the correct format numbers: -56.2 or +23,45
export const negativeNumberInputRegex = /^[-+]?\d*([,.]\d*)?$/;

