import React from "react";

interface Props {
  children: JSX.Element | Array<JSX.Element>
  className?: string;
}

export function ModalContent({ children, className }: Props): React.ReactElement {
  return <div className={`modal-content ${className !== undefined ? className : ""}`}>{children}</div>;
}
