import React, { ReactElement, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { InstallationInfoPatch } from "../../types/requesterTypes";
import {
  InstallationInfo,
  InstallationSamplingRates,
} from "../../types/types";
import { Requester } from "../../utils/Requester";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";
import { ModalContent } from "../Modal/ModalContent";
import { ModalHeader } from "../Modal/ModalHeader";

interface Props {
  installation: InstallationInfo;
  device_id: string;
  modalId: string;
  onUpdate: () => void;
}

type SampleRates = { value: InstallationSamplingRates; label: string };

export function InstallationConfigModal({
  modalId,
  installation,
  device_id,
  onUpdate,
}: Props): ReactElement {
  const { t } = useTranslation();

  const options: Array<SampleRates> = [
    {
      value: InstallationSamplingRates.RATE1,
      label: `5 ${t("minutes")} (${t("additional cost applies")})`,
    },
    {
      value: InstallationSamplingRates.RATE2,
      label: `15 ${t("minutes")} (${t("additional cost applies")})`,
    },
    {
      value: InstallationSamplingRates.RATE3,
      label: `1 ${t("hour")}`,
    },
  ];

  const samplingRate = installation.sampling_rate;
  const initialRate = options.find((a) => a.value === samplingRate)
    || {
      value: samplingRate,
      label: `${t("Custom interval")}: ${Math.floor(samplingRate / 60)} min ${samplingRate % 60} sec`,
    };

  const [newRate, setRate] = useState<SampleRates>(initialRate);

  return (
    <Modal id={modalId}>
      <ModalContent>
        <>
          <ModalHeader close id={modalId}>
            <>
              {t("Configure {{installation_name}} ({{installation_id}})", {
                installation_name: installation.name,
                installation_id: device_id })}
            </>
          </ModalHeader>
          <ModalBody>
            <>
              <h3 className="mt-2">{t("Sampling interval")}</h3>
              <Select
                closeMenuOnSelect
                value={newRate}
                options={options}
                onChange={(newRateValue) => {
                  setRate(newRateValue as SampleRates);
                }}
              />
              <button
                type="button"
                className="btn btn-primary btn-block mt-4"
                data-dismiss="modal"
                onClick={() => {
                  const patchObject: InstallationInfoPatch = {
                    installationID: installation.pk,
                    sampling_rate: newRate.value,
                  };

                  void Requester.patchInstallationInfo(patchObject)
                    .then(() => toast.success(
                      t("Installation settings updated!").toString()
                    ))
                    .then(() => onUpdate())
                    .catch(() => toast.error("Failed updating settings!")); // There is no backend written in the case that saving settings fails, so this part is an unreachable.
                }}
              >
                {t("Save")}
              </button>
            </>
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
}
