/* WARNING: DO NOT EDIT THIS GENERATED FILE */

export enum NodeTypes {
  IMS = "IMS",
  IMR = "IMR",
  IMI = "IMI",
  IMRMk2 = "IMRMk2",
  CC4 = "CC4",
  IMSMk2 = "IMSMk2",
  AD9 = "AD9",
}

export enum TSensorTypes {
  TMP = "TMP",
  HUM = "HUM",
  PWR = "PWR",
  HML = "HML",
  WCT = "WCT",
  DWT = "DWT",
  PDV = "PDV",
  GPK = "GPK",
  BATT = "BATT",
  VOC = "VOC",
  CO2 = "CO2",
  IAQ = "IAQ",
  OHM = "OHM",
  WME = "WME",
  MC = "MC",
  STE = "STE",
  STE1 = "STE1",
  STE2 = "STE2",
  STE3 = "STE3",
  STE4 = "STE4",
  HEAT = "HEAT",
  FMODE = "FMODE",
  OHR = "OHR",
  SPEED = "SPEED",
}

export enum ProbeTypes {
  RH = 1,
  VOC = 2,
  OHM = 3,
}

export enum AlarmEnum {
  TMP = "TMP",
  HUM = "HUM",
  PWR = "PWR",
  HML = "HML",
  RED = "RED",
  WFL = "WFL",
  WCT = "WCT",
  DWT = "DWT",
  PDV = "PDV",
  GPK = "GPK",
  BATT = "BATT",
  VOC = "VOC",
  CO2 = "CO2",
  IAQ = "IAQ",
  OHM = "OHM",
  WME = "WME",
  PAS = "PAS",
}

export const supportedAlarmTypes =  {
  [NodeTypes.IMS]: [
    AlarmEnum.DWT,
    AlarmEnum.HUM,
    AlarmEnum.PDV,
    AlarmEnum.TMP,
    AlarmEnum.WCT,
    AlarmEnum.GPK,
    AlarmEnum.PAS,
  ],
  [NodeTypes.IMR]: [
    AlarmEnum.PWR,
    AlarmEnum.PAS,
  ],
  [NodeTypes.IMI]: [
    AlarmEnum.DWT,
    AlarmEnum.HML,
    AlarmEnum.HUM,
    AlarmEnum.PDV,
    AlarmEnum.PWR,
    AlarmEnum.TMP,
    AlarmEnum.WCT,
    AlarmEnum.GPK,
    AlarmEnum.WFL,
    AlarmEnum.RED,
    AlarmEnum.PAS,
  ],
  [NodeTypes.IMRMk2]: [
    AlarmEnum.PWR,
    AlarmEnum.PAS,
  ],
  [NodeTypes.CC4]: [
    AlarmEnum.PWR,
    AlarmEnum.PAS,
  ],
  [NodeTypes.IMSMk2]: {
    [ProbeTypes.RH]: [
      AlarmEnum.DWT,
      AlarmEnum.HUM,
      AlarmEnum.PDV,
      AlarmEnum.TMP,
      AlarmEnum.WCT,
      AlarmEnum.GPK,
      AlarmEnum.BATT,
      AlarmEnum.PAS,
    ],
    [ProbeTypes.VOC]: [
      AlarmEnum.IAQ,
      AlarmEnum.VOC,
      AlarmEnum.CO2,
      AlarmEnum.BATT,
      AlarmEnum.PAS,
    ],
    [ProbeTypes.OHM]: [
      AlarmEnum.TMP,
      AlarmEnum.OHM,
      AlarmEnum.WME,
      AlarmEnum.BATT,
      AlarmEnum.PAS,
    ],
  },
  [NodeTypes.AD9]: [
    AlarmEnum.DWT,
    AlarmEnum.HUM,
    AlarmEnum.PDV,
    AlarmEnum.PWR,
    AlarmEnum.TMP,
    AlarmEnum.WCT,
    AlarmEnum.GPK,
    AlarmEnum.WFL,
    AlarmEnum.RED,
    AlarmEnum.HML,
    AlarmEnum.PAS],
}

// TSensorTypes as union of all keys in enum
// acts more like a `string` type
type TSensorTypeStrings = keyof typeof TSensorTypes;

export function getUnit(type: TSensorTypeStrings) {
  switch(type) {
    case "TMP":
      return "°C"
    case "HUM":
      return "%rH"
    case "PWR":
      return "kW"
    case "HML":
      return "%rH"
    case "WCT":
      return "g/m³"
    case "DWT":
      return "°C"
    case "PDV":
      return "mBar"
    case "GPK":
      return "g/kg"
    case "BATT":
      return "V"
    case "VOC":
      return "µg/m³"
    case "CO2":
      return "ppm"
    case "OHM":
      return "MΩ"
    case "WME":
      return "%"
    case "MC":
      return "%"
    case "OHR":
      return "h"
    default:
      return ""
  }
}