import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetpointModal } from "../components/NodeInfo/SetpointModal/SetpointModal";
import { PortalModal } from "../components/PortalModal/PortalModal";
import { GearButton } from "../components/NodeInfo/IconButton/GearButton";
import { DeviceNodeAD9 } from "../types/types";

interface Props {
  node: DeviceNodeAD9;
}

export default function SetpointContainer({node} : Props): ReactElement {
  const { t } = useTranslation();

  const [showConfigModal, setShowConfigModal] = useState(false);

  return (
    <>
      <PortalModal
        isOpen={showConfigModal}
        close={() => setShowConfigModal(false)}
      >
        <SetpointModal
          node={node}
          close={() => {
            setShowConfigModal(false);
          }}
        />
      </PortalModal>
      <GearButton
        onClick={() => {
          setShowConfigModal(true);
        }}
        disabled={!node.cloud_control}
        title={t("Configure relative humidity set point")}
      />
    </>
  )
}