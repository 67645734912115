import React, { ReactElement, ReactNode } from 'react';

import styles from './PortalModalContent.module.scss';

interface Props {
  children: ReactNode;
  narrow?: boolean;
}

export default function PortalModalContent({
  children,
  narrow,
}: Props): ReactElement {
  return <div 
    className={styles.content}
    data-narrow={narrow}
  >{children}</div>
}