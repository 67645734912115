import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartTypes } from "../types/types";

interface DatePickerType {
  format: string,
  timeDropdown: boolean,
  timeInput: boolean,
  showMonthYearPicker: boolean,
  labelText: string,
  displayString: string,
}

export function useChartDataType(initType: ChartTypes):
[
  DatePickerType,
  (arg0: ChartTypes) => void,
  Array<{valueString: ChartTypes, displayString: string}>
] {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(initType);

  const datepickerTypes = {
    Normal: {
      format: "d/MM/yyyy HH:mm",
      timeDropdown: false,
      timeInput: true,
      showMonthYearPicker: false,
      labelText: "Date",
      displayString: t("Normal"),
    },
    Hourly: {
      format: "d/MM/yyyy HH:mm",
      timeDropdown: true,
      timeInput: false,
      showMonthYearPicker: false,
      labelText: "Date",
      displayString: t("Hourly Avg"),
    },
    Daily: {
      format: "d/MM/yyyy",
      timeDropdown: false,
      timeInput: false,
      showMonthYearPicker: false,
      labelText: "Date",
      displayString: t("Daily Avg"),
    },
    Weekly: {
      format: "MM/yyyy",
      timeDropdown: false,
      timeInput: false,
      showMonthYearPicker: true,
      labelText: "Month",
      displayString: t("Weekly Avg"),
    },
    Monthly: {
      format: "MM/yyyy",
      timeDropdown: false,
      timeInput: false,
      showMonthYearPicker: true,
      labelText: "Month",
      displayString: t("Monthly Avg"),
    },
  };
  const dropdownItems = Object
    .entries(datepickerTypes)
    .map(([key, val]) => ({ valueString: key as ChartTypes, displayString: val.displayString }));
  return [datepickerTypes[selectedType], setSelectedType, dropdownItems];
}
