import React, { ReactElement, ReactNode } from 'react';

import styles from './PortalModalFooter.module.scss';

interface Props {
  children: ReactNode;
}

export default function PortalModalFooter({
  children,
}: Props): ReactElement{
  return (
  <div>
    <hr/>
    <div className={styles['footer-content']}>
      {children}
    </div>
  </div>
  )
}