import React, { ReactNode } from 'react';

import style from './TitleBar.module.scss';

interface Props {
  children?: ReactNode;
  title: string;
}

export default function TitleBar({
  children,
  title,
}: Props): React.ReactElement {
  return (
    <div
      className={style['component-wrapper']}
    >
      <p
        className={style['title']}
      >
        {title} 
      </p>
      <div
        className={style['content']}
      >
        {children}
      </div>
    </div>
  )
}