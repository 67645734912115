import React, { useState } from "react";
import { SocketSelector } from "../Misc/SocketSelector";
import WeekdaySelector from "../Misc/WeekdaySelector/WeekdaySelector";
import { RangeSlider } from "../Misc/RangeSlider/RangeSlider";

import styles from "./QuietTimeSelection.module.scss";
import trashIcon from './TimeRangeModal/trash_icon_plain.svg';


// Slider selects time in day by 10 minute steps
export const TIME_UNITS_PER_DAY = 144;


// Prepares time of day in human redable format basing calculation on TIME_UNITS_PER_DAY
function generateTimeDisplay(value: number) {
  const MILLIS_IN_HOUR = 3600000;
  // hardcoded value - milliseconds in day
  const valueInMillis = 86400000 / TIME_UNITS_PER_DAY * value;
  
  const hours = Math.floor(valueInMillis / MILLIS_IN_HOUR);
  // hardcoded value - milliseconds in minute
  const minutes = valueInMillis % MILLIS_IN_HOUR / 60000;
  
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padEnd(2, "0")}`;
}

interface Props {
  days: number;
  startTime: number;
  duration: number;
  sockets: number;
  onSelectionChange: (days: number, startTime: number, duration: number, sockets: number) => void;
  socketCount: number;
  helpMode: boolean;
  onDelete: () => void;
}

export function QuietTimeSelector({
  days,
  startTime,
  duration,
  sockets,
  onSelectionChange,
  helpMode,
  socketCount,
  onDelete,
}: Props) {
  const [secondSlider, setSecondSlider] = useState(startTime + duration > TIME_UNITS_PER_DAY);

  return <div className={styles.component}>
    <div className={styles["selector-box"]}>
      { (socketCount > 0) && 
        <div className={styles["socket-selector-box"]}>
          <SocketSelector
            sockets={sockets}
            socketCount={socketCount}
            highlightRed={helpMode && !sockets}
            onChange={newSockets => onSelectionChange(
              days,
              startTime,
              duration,
              newSockets,
            )}
          />
        </div> 
      }
      <div className={styles["weekday-selector-box"]}>
        <WeekdaySelector
          days={days}
          highlightRed={helpMode && !days}
          onChange={newDays => onSelectionChange(
            newDays,
            startTime,
            duration,
            sockets,
          )}
        />
      </div>
      <div className={styles["time-range-selector-box"]}>
        <div className={styles["time-range-selection"]}>
          <label>{generateTimeDisplay(startTime)}</label>
          <RangeSlider
            min={0}
            max={TIME_UNITS_PER_DAY}
            start={startTime}
            end={startTime + duration}
            rangeSelected={(min, max) => {
              onSelectionChange(
                days, 
                min,
                Math.min(max - min, TIME_UNITS_PER_DAY * 2 - min), 
                sockets
              );
              if (!secondSlider && max > TIME_UNITS_PER_DAY) setSecondSlider(true);
            }}
            uncapEnd
            disableEnd={TIME_UNITS_PER_DAY < startTime + duration}
          />
          <label>
            {generateTimeDisplay(Math.min(startTime + duration, TIME_UNITS_PER_DAY))}
          </label>
        </div>
        { secondSlider &&
          <>
            <div className={styles["small-weekday-box"]}>
              <WeekdaySelector 
                // rotate bits left by one 
                // shift all left, combine with last bit shifted right to start to do the rotation
                days={(days << 1) | (days >> 6)}
                small
                disabled
              />
            </div>
            <div className={styles["time-range-selection"]}>
              <label>
                { // this will always be zero because start point cannot be dragged onto second slider
                  generateTimeDisplay(0)
                }
              </label>
              <RangeSlider
                min={TIME_UNITS_PER_DAY}
                max={TIME_UNITS_PER_DAY * 2}
                start={startTime}
                end={startTime + duration}
                rangeSelected={(min, max) => {
                  onSelectionChange(
                    days,
                    Math.max(min, 0),
                    Math.min(max - min, TIME_UNITS_PER_DAY * 2 - min), 
                    sockets);
                }}
                uncapStart
                uncapEnd
                disableStart
                disableEnd={TIME_UNITS_PER_DAY >= startTime + duration}
              />
              <label>
                {generateTimeDisplay(Math.max(startTime + duration - TIME_UNITS_PER_DAY, 0))}
              </label>
            </div> 
          </>
        }
      </div>
    </div>
    <button
      className={styles.delete}
      onClick={() => onDelete()}
    >
      <img src={trashIcon} alt="Delete"/>
    </button>
  </div>
}