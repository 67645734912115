import { History, State as HistoryState } from "history";
import { isMessageResponse } from "../utils/utility";
import { MessageResponse } from "../types/requesterTypes";


export async function loginCheck(
  username: string | undefined,
  password: string | undefined,
  setUsername: (arg: string) => void,
  setPassword: (arg: string) => void,
  logIn: (username: string, password: string) => Promise<MessageResponse | undefined>,
  history: History<HistoryState>,
  setRequestLoginErr: (arg: MessageResponse | undefined) => void,
): Promise<void> {
  setRequestLoginErr(undefined);
  if (
    username !== ""
    && username !== undefined
    && password !== ""
    && password !== undefined
  ) {
    const result = await logIn(username, password);
    if (isMessageResponse(result)) {
      setRequestLoginErr(result);
    }

    history.push("/dashboard/list/active");
  } else {
    setUsername(username === undefined ? "" : username);
    setPassword(password === undefined ? "" : password);
  }
}
