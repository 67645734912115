import {
  DeviceNode,
  QueryNode,
} from "../types/types";


export function exportQueryDataFromNodes(
  nodes: Array<DeviceNode>,
): Array<QueryNode> {
  // Only needed data for proper node data info gathering is
  // InstallationNodes.id and sensor types.
  // Creating a get param like: [{types: ["HUM",...], id: 3911}, ...]
  // Filtering out entries where no sensor `types` are actively selected

  const queryNodes: Array<QueryNode> = nodes
    .map((node) => {
      const item: QueryNode = { types: [], id: node.id };
      node.fields.forEach((sensor) => {
        if (sensor.enabled) {
          item.types.push(sensor.sens_type);
        }
      });
      return item;
    })
    .filter((el) => el.types.length > 0);
  return queryNodes;
}
