import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ChartTypes } from "../../types/types";
import { useChartDataType } from "../../hooks/UseChartDataType";
import style from "./CommonStyles.module.scss";

interface Props {
  chartType: ChartTypes;
  chartTypeChange: (
    e: ChartTypes
  ) => void;
}

export function ChartTypeWrapper({
  chartType,
  chartTypeChange,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [, setTypes, options] = useChartDataType(chartType);

  useEffect(() => {
    setTypes(chartType);
  }, [chartType, setTypes]);
  return (
    <div className={style.wrapper}>
      <div className={style.label}>{t("Chart Type")}</div>
      <div className={style.content}>
      <select
        className="custom-select"
        id="inputDropdownChartType"
        name="chartType"
        onChange={(e) => chartTypeChange(e.target.value as ChartTypes)}
        value={chartType}
      >
        {options.map((el) => (
          <option key={el.valueString} value={el.valueString}>
            {el.displayString}
          </option>
        ))}
      </select>
      </div>
    </div>
  );
}
