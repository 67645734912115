import React from "react";

export interface ExtremeButtonsProps {
  text: string;
  sign: string;
  enabled?: boolean;
  click: () => void;
}

export function ExtremeButtons({
  click,
  sign,
  text,
}: ExtremeButtonsProps): React.ReactElement {
  return (
    <button
      type="button"
      className="page-link"
      aria-label={text}
      onClick={click}
    >
      <span aria-hidden="false">{sign}</span>
      <span className="sr-only">{text}</span>
    </button>
  );
}

export function range(
  start: number,
  stop: number,
  step: number,
): Array<number> {
  const a = [start];
  let b = start;
  while (b < stop) {
    a.push((b += step || 1));
  }
  return a;
}
