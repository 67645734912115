import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { TabsContainer } from "../Misc/TabsNavigation/TabsContainer";
import { TabLink } from "../Misc/TabsNavigation/TabLink";
import { useChart } from "../../contexts/ChartDataContext";
import SensorDataContainer from "../../containers/SensorDataContainer";
import InstallationAlarmsView from "../Alarms/InstallationAlarmsView";
import { ContentBox } from "../Misc/ContentBox";
import { PageWrapper } from "../Misc/PageWrapper";
import { useTranslation } from "react-i18next";
import { EnergySavingView } from "../EnergySaving/EnergySavingView";
import { InstallationVersions } from "../../types/types";

const AlarmLogsManager = React.lazy(
  () => import("../AlarmsLogs/AlarmLogsManager"),
);

export function InstallationPage() {
  const { params, url, path } = useRouteMatch<{id: string}>();
  const { t } = useTranslation();
  const { installation } = useChart();

  const energySavingSupported = installation.version === InstallationVersions.FOUR;

  return <PageWrapper>
    <TabsContainer>
      <TabLink
        path={`${url}/chartview`}
        title={t("Chart view")}
      />
      <TabLink
        path={`${url}/alarm-levels`}
        title={t("Alarms")}
      />
      <TabLink
        path={`${url}/alarm-logs`}
        title={t("Alarm log")}
      />
      { energySavingSupported &&
        <TabLink
          path={`${url}/energy-saving`}
          title={t("Energy Saving")}
        />
      }
    </TabsContainer>
    <ContentBox>
      <Switch>
        <Route
          exact
          path={`${path}/chartview`}
          render={() =>
            <SensorDataContainer />
          }
        />
        <Route
          exact
          path={`${path}/alarm-levels`}
          render={() =>
            <InstallationAlarmsView
              isArchived={installation.archived_time !== null}
              installationPk={Number(params.id)}
              updateCounts={() => {}} // Installation page does not display this information
            />
          }
        />
        <Route
          exact
          path={`${path}/alarm-logs`}
          component={AlarmLogsManager}
        />
        <Route
          exact
          path={`${path}/energy-saving`}
          render={() => <EnergySavingView />}
        />
        <Redirect 
          from={path}
          to={`${path}/chartview`}
        />
      </Switch>
    </ContentBox>
  </PageWrapper>
}