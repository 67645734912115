import React from "react";
import loading from "../../images/loading.gif";

export function SpinnyThingy(): React.ReactElement {
  return (
    <img
      alt="Loading"
      className="img img-fluid mx-auto d-block"
      src={loading}
    />
  );
}
