import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Requester } from "../utils/Requester";
import { Settings } from "../types/types";
import { SpinnyThingy } from "../components/Misc/SpinnyThingy";

interface Props {
  children: JSX.Element;
}

export interface SettingsContextInterface {
  dashboardSettings: Settings;
  updateSettings: () => Promise<void>;
}

export const SettingsContext = createContext<SettingsContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in SettingsContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as SettingsContextInterface, {
    apply: () => {
      throw new Error(
        "You must wrap your component in an SettingsContextProvider",
      );
    },
    get: () => {
      throw new Error(
        "You must wrap your component in an SettingsContextProvider",
      );
    },
  }),
);

function SettingsContextProvider({ children }: Props): React.ReactElement {
  const [dashboardSettings, setDashboardSettings] = useState<Settings>();
  const refreshSettigns = useCallback(
    () => Requester.getDashboardSettings().then((res) => setDashboardSettings(res.data)),
    [setDashboardSettings],
  );
  useEffect(() => {
    void refreshSettigns();
  }, [refreshSettigns]);

  // Stall the loading of subcompoennts until the settings are available!
  if (dashboardSettings === undefined) {
    return <SpinnyThingy />;
  }
  return (
    <SettingsContext.Provider
      value={{
        dashboardSettings,
        updateSettings: () => refreshSettigns(),
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

const useSettings = (): SettingsContextInterface => React.useContext(SettingsContext);

export { SettingsContextProvider, useSettings };
