import React, {
  createContext, useState, useCallback, useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { TranslateVisualSensorType } from "../components/Misc/TranslateVisualSensorType";
import { ModalSensorData } from "../components/NodeInfo/ModalSensorData";

import {
  DeviceNode,
  IndividualSensorData,
} from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import { Requester } from "../utils/Requester";
import { calculateMCValue } from "../utils/utility";
import { useChart } from "./ChartDataContext";

interface Props {
  children: JSX.Element;
}

export interface ModalSensorDataContextInterface {
  openSensorDataModal: (node: DeviceNode, sensorType: TSensorTypes) => void
}

export const ModalSensorDataContext = createContext<ModalSensorDataContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in ModalSensorDataContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as ModalSensorDataContextInterface, {
    apply: () => {
      throw new Error("You must wrap your component in an ModalSensorDataContextProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an ModalSensorDataContextProvider");
    },
  }),
);

function ModalSensorDataContextProvider({ children }: Props): React.ReactElement {
  const { t } = useTranslation();
  const { materialMap } = useChart();
  const [localID, setLocalID] = useState("");  

  const [modalHeaderState, setModalHeaderState] = useState<string>("");
  const [modalSensorDataSpinnyThingy, setModalSensorDataSpinnyThingy] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [endDate, setEndDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>();
  const [maxPages, setMaxPages] = useState(1);
  const [modalData, setModalData] = useState<Array<IndividualSensorData>>([]);

  const nodeCount = 10;
  const nodeStartPage = 1;

  const [nodeIDState, setNodeIDState] = useState<number>(-1);
  const [sensorTypeState, setSensorTypeState] = useState<string>("");
  const [nodeNameState, setNodeNameState] = useState<string>("");

  const switchToPageCallback = useCallback(
    async (newPage: number) => {
      if (nodeIDState > 0) {
        // Setting this before the request to replace the previous nodes name from the modal.
        setModalHeaderState(
          // Converting sensorTypeState from a string into TSensorTypes and then translating it to it's appropriate name
          `${t("Sensor Data for")} ${TranslateVisualSensorType(TSensorTypes[sensorTypeState as keyof typeof TSensorTypes])} ${nodeNameState}`,
        );
        setModalSensorDataSpinnyThingy(true);
        const result = await Requester.getIndividualSensor({
          id: nodeIDState,
          count: nodeCount,
          page: newPage,
          // If MC then get WME data instead, MC will be calculated from WME data
          type: sensorTypeState === TSensorTypes.MC ? TSensorTypes.WME : sensorTypeState,
          startDate,
          endDate,
        });
        setModalSensorDataSpinnyThingy(false);
        setModalData(result.data);
        setMaxPages(result.total_pages);
      }
    },
    [nodeIDState, sensorTypeState, startDate, endDate, t, nodeNameState],
  );

  const openSensorDataModal = (node: DeviceNode, sensorType: TSensorTypes): void => {
    setNodeIDState(node.id);
    setSensorTypeState(sensorType);
    setNodeNameState(node.sensor_name);
    setLocalID(node.local_id);

    setEndDate(undefined);
    setStartDate(undefined);
    setCurrentPage(nodeStartPage);
  };

  useEffect(() => {
    void switchToPageCallback(currentPage);
  }, [switchToPageCallback, currentPage]);

  return (
    <ModalSensorDataContext.Provider
      value={{
        openSensorDataModal,
      }}
    >
      {children}
      <ModalSensorData
        endDate={endDate}
        startDate={startDate}
        modalData={{
          current_page: currentPage,
          data: sensorTypeState === TSensorTypes.MC 
            ? modalData.map(item => ({
              ...item,
              sensor_type: TSensorTypes.MC,
              value: calculateMCValue(Number.parseFloat(item.value), materialMap.get(localID)!).toString(),
            }))
            : modalData,
          total_pages: maxPages,
        }}
        switchToPage={(e, startDateNew, endDateNew) => {
          setCurrentPage(e);
          setStartDate(startDateNew);
          setEndDate(endDateNew);
        }}
        modalHeader={modalHeaderState}
        modalSensorDataSpinnyThingy={modalSensorDataSpinnyThingy}
      />
    </ModalSensorDataContext.Provider>
  );
}

const useModalSensorData = (): ModalSensorDataContextInterface => React.useContext(ModalSensorDataContext);

export { ModalSensorDataContextProvider, useModalSensorData };
