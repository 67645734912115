import { ColorResult } from "react-color";
import { TSensorTypes } from "../types/generated_types";


export function toggleColorPicker(
  color: string,
  pickerEnabledState: boolean,
  setPickerEnabledState: (arg0: boolean) => void,
  setPickerColorLocalState: (arg0: string) => void,
): void {
  setPickerEnabledState(!pickerEnabledState);
  setPickerColorLocalState(color);
}

export function updateColorLocal(
  newColor: ColorResult,
  setPickerColorLocalState: (arg0: string) => void,
): void {
  setPickerColorLocalState(newColor.hex);
}

export function updateColorGlobal(
  pickerColorLocalState: string,
  pickerEnabledState: boolean,
  onSensorColorChange: (
    colorArg: string,
    sensorType: TSensorTypes,
  ) => void,
  sensorType: TSensorTypes,
  color: string,
  setPickerEnabledState: (arg0: boolean) => void,
  setPickerColorLocalState: (arg0: string) => void,
): void {
  // TODO wrap onSensorColorChange inside the parent component before
  // so this component would no longer be attached to the dashboard node and
  // sensor concept. This will allow the component to be more reusable if
  // the need ever arrases.
  onSensorColorChange(
    pickerColorLocalState,
    sensorType,
  );
  toggleColorPicker(
    color,
    pickerEnabledState,
    setPickerEnabledState,
    setPickerColorLocalState,
  );
}
