import React from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "../Misc/Pagination";
import { SpinnyThingy } from "../Misc/SpinnyThingy";
import { Modal } from "../Misc/Modal";
import { IndividualSensorResponse } from "../../types/requesterTypes";
import { DatePickerWrapper } from "../ChartTypePicker/DatePickerWrapper";
import { ChartTypes } from "../../types/types";
import { cleanUpDateTime, roundData } from "../../utils/utility";
import { TranslateVisualSensorType } from "../Misc/TranslateVisualSensorType";
import { getUnit } from "../../types/generated_types";

interface Props {
  modalSensorDataSpinnyThingy: boolean,
  modalData: IndividualSensorResponse,
  switchToPage: (e: number, startDate?: Date, endDate?: Date) => void,
  modalHeader: string,
  startDate?: Date,
  endDate?: Date,
}

export function ModalSensorData({
  modalSensorDataSpinnyThingy, modalData, modalHeader, switchToPage, startDate, endDate,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  // If being rendered with any data at all
  let tableWithData;
  if (!modalSensorDataSpinnyThingy) {
    if (modalData.data.length) {
      const formattedData = modalData.data.map((el) => (
        <tr className="modal-sensor-data-row" key={el.id}>
          <td>{el.page_idx}</td>
          <td>
            {roundData(el.value, el.sensor_type, t)}
            {" "}
            {getUnit(el.sensor_type)}
          </td>
          {/* Transforming sensor type to its display name */}
          <td>{TranslateVisualSensorType(el.sensor_type)}</td>
          <td>{cleanUpDateTime(el.captured_datetime)}</td>
        </tr>
      ));

      const pagination = (
        <Pagination
          key="pagination"
          currentPage={modalData.current_page}
          totalPages={modalData.total_pages}
          switchToPage={(page: number) => switchToPage(page, startDate, endDate)}
          oldestNewestVisable
        />
      );

      tableWithData = (
        <>
          <table className="table table-striped table-bordered table-hover table-sm">
            <thead>
              <tr key="modal-head">
                <th scope="col">#</th>
                <th scope="col">{t("Value")}</th>
                <th scope="col">{t("Sensor Type")}</th>
                <th scope="col">{t("Captured Date Time")}</th>
              </tr>
            </thead>
            <tbody>{formattedData}</tbody>
          </table>
          {pagination}
        </>
      );
    } else {
      tableWithData = t("NO DATA");
    }
  } else {
    // TODO get the spinny thingy from props.children
    tableWithData = <SpinnyThingy />;
  }

  return (
    <Modal
      closeFooter
      closeUpper
      modalHeader={modalHeader}
      id="individualSensorDataModal"
      modalFooter={
        (
          <>
            <div className="col">
              <DatePickerWrapper
                label="Start"
                chartType={ChartTypes.Daily}
                selected={startDate}
                onChange={(arg) => switchToPage(modalData.current_page, arg, endDate)}
                maxDate={endDate}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                // minDate={endDate}
              />
            </div>
            <div className="col">
              <DatePickerWrapper
                label="End"
                chartType={ChartTypes.Daily}
                selected={endDate}
                onChange={(arg) => switchToPage(modalData.current_page, startDate, arg)}
                // maxDate={maxDate}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                selectsEnd
              />
            </div>
          </>
        )
      }
    >
      <>
        {tableWithData}
      </>
    </Modal>
  );
}
