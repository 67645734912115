import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { EmissionsData, EnergyData } from "../types/types";
import { getProductName } from "../utils/utility";

import style from './EnergyTable.module.scss';

interface Props {
  co2?: EmissionsData;
  tableData:Array<EnergyData>
}

export function EnergyTable({
  co2,
  tableData,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const total = tableData.reduce((prev, curr) => prev + (curr.total || 0), 0);
  const co2_data = co2 && {...co2, emissions: co2.intensity * total};

  return (
    <div className="text-center">
      <table className={`table table-sm ${style["table-fixed"]}`}>
        <thead>
          <tr>
            <th scope="col">{t("Type")}</th>
            <th scope="col">{t("ID")}</th>
            <th scope="col">{t("Name")}</th>
            <th scope="col">{t("Start")}</th>
            <th scope="col">{t("End")}</th>
            <th scope="col">{t("Total")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((el, index) => (
            <tr key={el.name + String(index)}>
              <th scope="row">{getProductName(el.type, t)}</th>
              <td>{el.id7}</td>
              <td>{el.name}</td>
              <td>{el.start !== null ? `${el.start.toFixed(2)} kWh` : "NaN"}</td>
              <td>{el.end !== null ? `${el.end.toFixed(2)} kWh` : "NaN"}</td>
              <td>{el.total.toFixed(2)} kWh</td>
            </tr>
          ))}
          <tr key="TotalKwh">
            <td colSpan={4} />
            <th className="table-info">{t("Total kWh")}</th>
            <th className="table-info">{`${total.toFixed(2)} kWh`}</th>
          </tr>
          {co2_data &&
          <tr key="co2">
            <td colSpan={4} />
            <th className="table-info" style={{"fontSize": "10px"}}>
              <span style={{"fontSize": "14px"}}>
              <Trans>Estimated CO₂ emissions</Trans>
              </span>
              <br/>
              <a href={co2_data.url}>
                <Trans>
                  Using {{entity: co2_data.entity}} data
                  for {{year: co2_data.year}}
                </Trans>
              </a>
            </th>
            <th className="table-info">
              {co2_data.emissions.toFixed(2)} kg
            </th>
          </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
