import React, { ReactNode, useRef } from "react"

interface Props {
  children: ReactNode
  show: boolean
}

/**
 * Animated expandable container
 * 
 * @param show Set `true` when contents need to be shown
 */
export function ExpandableContainer({
  children,
  show,
}: Props) {
  const container = useRef<HTMLDivElement>(null)

  return <div
    style={{
      transition: "all 0.2s",
      height: show ? container.current?.clientHeight : "0px",
      opacity: show ? "1" : "0",
    }}
  >
    <div ref={container}>
      {children}
    </div>
  </div>
}