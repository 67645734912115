import React from "react";
import styles from "./CloseButton.module.scss";
import { Trans } from "react-i18next";

interface Props {
  close: () => void;
}

export function CloseButton({
  close,
}:Props) {
  return <button 
    className={styles.button}
    onClick={() => close()}
  >
    <Trans>Close</Trans>
  </button>
}