/* eslint-disable import/no-default-export */
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { InstallationVersions } from "../types/types";
import { RightSideButton } from "../components/Misc/RightSideButton";
import { ChartDataManager } from "./ChartDataManager";
import { NodeInfoManager } from "../components/NodeInfo/NodeInfoManager";
import { formatDate } from "./SensorDataContainer.helper";

import style from "./SensorData.module.scss";
import { useChart } from "../contexts/ChartDataContext";

import { ModalSensorDataContextProvider } from "../contexts/ModalSensorDataContext";
import { InstallConfigModalContextProvider } from "../contexts/InstallConfigModalContextProvider";
import { PageTitleBox } from "../components/Misc/PageTitleBox";


export default function SensorDataContainer(): React.ReactElement {
  const chart = useChart();
  const history = useHistory();

  const { nodes } = useChart();

  const selectedRangeFormatted = useMemo(
    () =>
      `${formatDate(chart.chartParams.x1)}-${formatDate(chart.chartParams.x2)}`,
    [chart.chartParams.x1, chart.chartParams.x2],
  );

  return (
    <ModalSensorDataContextProvider>
      <InstallConfigModalContextProvider>
        <>
          <PageTitleBox
            rightContainer={<RightSideButton onClick={() => history.go(-1)} />}
          >
            <div style={{display: "inline-block"}}>
              <div className="row">
                <div className="col">
                  <h4 className="inst-title">
                    {chart.installation.name} (ID:&nbsp;{chart.device_id})
                  </h4>
                  <h4 className="inst-title insttime-title">
                    [&nbsp;{selectedRangeFormatted}&nbsp;]
                  </h4>
                </div>
                {chart.installation.version
                  === InstallationVersions.FOUR && (
                  <div className={style.cogs}>
                    <button
                      type="button"
                      id="load-data-table"
                      data-toggle="modal"
                      data-target="#installationConfigModal"
                    >
                      <i className="fas fa-cogs" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </PageTitleBox>
          <ChartDataManager />
          <div className={style["node-info-container"]}>
            <NodeInfoManager
                nodes={nodes}
              />
          </div>
        </>
      </InstallConfigModalContextProvider>
    </ModalSensorDataContextProvider>
  );
}
