import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { DeviceNodeIMR, STESubSensors, ScheduleDeviceNode } from "../../types/types";
import { TranslateVisualSensorType } from "../Misc/TranslateVisualSensorType";

import style from "./PowerButtonModal.module.scss";

import exclamation_triangle from "../../images/exclamation_triangle.svg";
import { getProductName } from "../../utils/utility";
import { useChart } from "../../contexts/ChartDataContext";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import PortalModalContent from "../PortalModal/PortalModalContent";
import { CloseButton } from "../PortalModal/CloseButton";
import { ButtonStyle, ModalButton } from "../PortalModal/ModalButton";
import { SelectorRow } from "../PortalModal/SelectorRow";
import { NodeTypes } from "../../types/generated_types";

interface Props {
  outlet: STESubSensors,
  node: ScheduleDeviceNode | DeviceNodeIMR,
  isOn: boolean,
  close: () => void;
}

export function PowerButtonModal({
  outlet,
  node,
  isOn,
  close,
}: Props): React.ReactElement {
  const { nodePowerStateToggle } = useChart();
  const { t } = useTranslation();

  return (
    <>
      <PortalModalHeader
        title={`${t("Confirmation")}: ${isOn ? t("TURN OFF") : t("TURN ON")} ${node.sensor_name} (${node.local_id})`}
        close={close}
      />
      <PortalModalContent narrow>
        <SelectorRow label={t("Current state")}>
          {isOn ? t("ON") : t("OFF")}
        </SelectorRow>
        <SelectorRow label={t("Socket no.")}>
          {TranslateVisualSensorType(outlet)}
        </SelectorRow>
        { node.node_type !== NodeTypes.IMR &&
        <SelectorRow label={t("Time schedule")}>
          {(node.quiet_times && node.quiet_times.length > 0) ? "configured" : "not set"}
        </SelectorRow>

        }

        <div className={`${style["text-box"]}`}>
          {node.active ? (
            <>
              <div className={`${style.content}`}>
                {t("The action will be scheduled for the next data exchange in up to 10 minutes.")}
              </div>
            </>
          ) : (
            <>
              <img src={exclamation_triangle} alt="WARNING" className={`${style.icon}`} />
              <div className={`${style.content}`}>
                {getProductName(node.node_type, t)} {t("is currently")} <strong>{t("passive")}</strong>.
                <br />
                {t("The action will be scheduled to happen when it comes online.")}
              </div>
            </>
          )}
        </div>
        { (isOn && node && node.node_type !== NodeTypes.IMR && node.quiet_times.length > 0) &&
          <div className={style["text-box"]}>
            <img src={exclamation_triangle} alt="WARNING" className={style.icon} />
            <div className={style.content}>
              <b>
              <Trans>
                If {{productName: getProductName(node.node_type, t)}} is turned off
                the time schedule will not be observed.
              </Trans>
              </b>
            </div>
          </div>
        }
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton 
          buttonStyle={isOn ? ButtonStyle.RED : ButtonStyle.GREEN}
          onClick={() => {
            nodePowerStateToggle(node.id, !isOn, outlet);
            close();
          }}
        >
          {isOn ? t("TURN OFF") : t("TURN ON")}          
        </ModalButton>
        <CloseButton close={close}/>
      </PortalModalFooter>
    </>
  );
}
