import React, { ReactNode } from "react";

import styles from "./InfoBubble.module.scss";

interface Props {
  children: ReactNode;
}

export function InfoBubble({
  children,
}: Props) {
  return <div
    className={styles["component-wrapper"]}
    >
      <i className="fas fa-info-circle"></i>
      <div className={styles["tooltip-wrapper"]}>
        <div className={styles.tooltip}>
          {children}  
        </div>
        <div className={styles.triangle}/>
      </div>
    </div>
}