import React, { ReactElement } from 'react';

//TODO: investigate if implementation of this component and styles can be improved
//current implementation based on https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
import styles from './ToggleSwitch.module.scss';

interface Props{
  checked: boolean;
  disabled?: boolean
  onChange: (checked: boolean) => void;
}

export default function ToggleSwitch({
  checked,
  disabled,
  onChange,
}: Props): ReactElement{
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
      />
      <span className={styles.slider}></span>
    </label>
  )
}