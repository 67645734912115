import React, { ReactElement, useState } from "react";

import style from "./NumericInput.module.scss";

const numberInputRegex = /^[-+]?\d*([,.]\d*)?$/;
const nonNegativeNumberRegex = /^\d+([.,]\d*)?$/;

/* 
  Note: The fact that onChange can return undefined is 100% intentional and desired
  for following reasons:

  - To keep states in components that depend on returned value up to date. 
  If <NumericInput> "eats" invalid values this can result in mismatch between waht
  user sees on input field and what is in state.
  Example: "-9" is entered, "9" is deleted but onChange is not called because "-" results in NaN.
  
  To avoid this onChange is called every time when <input> value is updated, including
  when that value not a valid number - undefined is returned instead.

  - Give ability to distinguish between 0 and no input.
  You are able to distinguish this with regular <input> because you can check for empty string,
  but this component returns number therefore undefined is used to make distinction.
  This allows changes of functionality depending on whether value is provided or not.
  Example: Chart can automatically determine max value when specific value is not provided.
  Example: We can keep track of whether user has filled all fields of a form.

  The choice to return number instead of string was made in order to avoid constant need to
  convert strings that contain number into numbers and the type will better represent its contents.
*/

// Props that provide features but are not required for component to function are
// set to optional since there is no need to force to provide them when these
// features are not used
interface Props {
  initialValue?: number;
  disabled?: boolean;
  nonNegative?: boolean;
  onChange: (value: number | undefined) => void;
}

export function NumericInput({
  initialValue,
  disabled,
  nonNegative,
  onChange
}: Props) : ReactElement {
  const [value, setValue] = useState(Number.isFinite(Number(initialValue)) ? initialValue : "");

  return <input
    className={style.input}
    value={value}
    onChange={(e) => {
      if (!e.target.value) {
        setValue("");
        onChange(undefined); // return undefined when there is no number
      } else if (nonNegative
        // disallow "-" sign if nonNegative is set
        ? nonNegativeNumberRegex.test(e.target.value)
        // regular number regex
        : numberInputRegex.test(e.target.value)
      ) {
        setValue(e.target.value);
        let replacedValue = Number(e.target.value.replace(",", "."));
        // will either return valid number or undefined if current input does not
        // parse as number, but always notify of change to avoid outdated states
        // that are mismatching what is visible in input field
        onChange(Number.isFinite(replacedValue) ? replacedValue : undefined);
      } // ignore non-empty values that fail the regex - neither update input nor call onChange
    }}
    type="text"
    disabled={disabled}
    maxLength={64}
  />
}