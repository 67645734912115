import React, { useEffect, useState } from "react";
import styles from "./QuietModeScheduler.module.scss";
import addIcon from './TimeRangeModal/add_icon.svg';

import { DeviceNodeAD9, QuietModeScheduleTime } from "../../types/types";
import { PortalModal } from "../PortalModal/PortalModal";
import { QuietTimeSelector, TIME_UNITS_PER_DAY } from "./QuietTimeSelector";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import { Trans, useTranslation } from "react-i18next";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { Requester } from "../../utils/Requester";
import toast from "react-hot-toast";
import { useChart } from "../../contexts/ChartDataContext";
import { CloseButton } from "../PortalModal/CloseButton";
import { ModalButton } from "../PortalModal/ModalButton";
import { TimeButton } from "./IconButton/TimeButton";

interface QuietModeSelection {
  // Weekdays are represented by bits in single number from right to left (low bit being Monday)
  days: number,
  // Start time of selected range between 0 to 144 (minutes per day / 10)
  startTime: number,
  // duration
  duration: number,
}

interface Props {
  node: DeviceNodeAD9
}

export function QuietModeScheduler({
  node
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [helpMode, setHelpMode] = useState(false);
  
  const [selections, setSelections] = useState<Map<number, QuietModeSelection>>(new Map());

  const { t } = useTranslation();
  const { refreshNodes } = useChart();
  
  useEffect(() => {
    const map = new Map<number, QuietModeSelection>();

    let index = 0;
    while (index < node.quiet_fan_modes.length) {
      let item = node.quiet_fan_modes[index];
      let nextItem = node.quiet_fan_modes[index + 1]; // should be undefined if out of bounds
      
      // determine if item can be merged with nextItem
      if (
        nextItem
        && nextItem.days === (((item.days << 1) & 127) | (item.days >> 6))
        && item.end_time/600 === TIME_UNITS_PER_DAY
        && nextItem.start_time === 0
      ) {
        map.set(Date.now() + index, {
          days: item.days,
          startTime: item.start_time / 600,
          duration: (item.end_time - item.start_time + nextItem.end_time)/600,
        });
        // increment index additional time since nextItem is merged and is to be skipped next iteration
        index++;
      } else {
        map.set(Date.now() + index, {
          days: item.days,
          startTime: item.start_time / 600,
          duration: (item.end_time - item.start_time)/600,
       });
      }
      index++;
    }
    setSelections(map);
  }, [node]);

  function isSaveDisabled() {
    for (let item of Array.from(selections.values())) {
      if (!item.days) return true; // no days set will have value 0 which acts as false
    }
    return false;
  }

  return <>
    <TimeButton
      onClick={() => setShowModal(true)}
      disabled={!node.cloud_control || !node.timezone}
      green={node.quiet_fan_modes.length > 0}
    />
    <PortalModal
      isOpen={showModal}
      close={() => setShowModal(false)}
    >
      <PortalModalHeader 
        title={t("Quiet Fan Mode schedule")}
        close={() => setShowModal(false)}
      />
      <PortalModalContent>
        <div className={styles.header}>
          <label> 
            <Trans>Enable Quiet Fan Mode when:</Trans>
          </label>
          <label className={styles.timezone}>
            <Trans>Times in {{timezone: node.timezone?.join("/")}}</Trans>
          </label>
        </div>
        <hr/>
        <div className={styles.list}>
          { Array.from(selections.entries()).map(([key, selection]) =>
            <div className={styles["list-item-container"]} key={key}>
              <QuietTimeSelector
                days={selection.days}
                startTime={selection.startTime}
                duration={selection.duration}
                helpMode={helpMode}
                onDelete={() => {
                  const map = new Map(selections);
                  map.delete(key);
                  setSelections(map);
                }}
                onSelectionChange={(days, startTime, duration)=> {
                  const map = new Map(selections);
                  map.set(
                    key,
                    {
                      days: days,
                      startTime: startTime,
                      duration: duration,
                    }
                  );
                  setSelections(map);
                }}
                socketCount={0} // no sockets for quiet mode
                sockets={0} // no sockets for quiet mode
              />
              <hr />
            </div>
          )}
          <button 
            className={styles['add-button']}
            onClick={() => {
              const map = new Map(selections);
              map.set(
                Date.now(),
                {
                  days: 0,
                  startTime: 40,
                  duration: 60,
                }
              );
              setSelections(map);
            }}
          >
            <img src={addIcon} alt="Add"/>
            <Trans>Add Time</Trans>
          </button>
        </div>
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          disabled={isSaveDisabled()}
          onClick={() => {
            if(isSaveDisabled()) {
              setHelpMode(true);
              return;
            }
            
            // setHelpmode(false);
            const requestArray = new Array<QuietModeScheduleTime>();
            Array.from(selections.values()).forEach(value => {
              requestArray.push({
                start_time: value.startTime * 600,
                end_time: Math.min(value.startTime + value.duration, TIME_UNITS_PER_DAY) * 600,
                days: value.days,
              });
              // if duration reaches over end of the day add entry for next day
              if(value.startTime + value.duration > TIME_UNITS_PER_DAY) {
                requestArray.push({
                  start_time: 0,
                  end_time: (value.startTime + value.duration - TIME_UNITS_PER_DAY) * 600,
                  // days rotated left by one
                  days: ((value.days << 1) & 127) | (value.days >> 6),
                })
              }
            });

            Requester.setQuietModeSchedule({
              id: node.id,
              quiet_fan_modes: requestArray,
            })
            .then(response => {
              if (response.status === "error") throw response.message;
              toast.success(t("Saved").toString());
              refreshNodes();
              setShowModal(false);
            })
            .catch(() => toast.error(t("Something went wrong").toString()));
          }}
        >
          <Trans>Submit</Trans>
        </ModalButton>
        <CloseButton close={() => setShowModal(false)} />
      </PortalModalFooter>

    </PortalModal>
  </>
}