import React, { useState } from "react";
import styles from "./ScheduleButton.module.scss";

import { ScheduleDeviceNode } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { TimeRangeModal } from "./TimeRangeModal";
import { PortalModal } from "../../PortalModal/PortalModal";

interface Props {
  node: ScheduleDeviceNode,
  isScheduled: boolean;
}

// Opens modal window for Relay Box quiet time schedule configuration
export function ScheduleButton({
  node,
  isScheduled,
}: Props) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  return <>
    <button
      className={`${styles["header-button"]} ${isScheduled ? styles.on : ""}`}
      // timezone is not provided when location is not set
      title={node.timezone ?
        undefined :
        t("Feature unavailable. Installation is missing geo-location.")
      }
      disabled={!node.timezone}
      onClick={() => setShowModal(true)}
    >
      <i className="fas fa-clock"></i>
      {t("Schedule")}
    </button>
    <PortalModal
      isOpen={showModal}
      close={() => setShowModal(false)}
    >
      <TimeRangeModal
        node={node}
        close={() => setShowModal(false)}
      />
    </PortalModal>
  </>;
}