import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { DatePickerInput } from "./DatePickerInput";
import { ChartTypes } from "../../types/types";
import { useChartDataType } from "../../hooks/UseChartDataType";

import style from "./ChartType.module.scss";

interface Props {
  label: string;
  chartType: ChartTypes;
  visibleDate?: boolean;
  selected: Date | null | undefined;
  onChange: (arg: Date) => void;
  maxDate?: Date | null | undefined;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  minDate?: Date | null | undefined;
  selectsStart?: boolean;
  selectsEnd?: boolean;
}

export function DatePickerWrapper({
  chartType,
  endDate,
  label,
  maxDate,
  onChange,
  selected,
  startDate,
  minDate,
  visibleDate = true,
  selectsStart,
  selectsEnd,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [types, setTypes] = useChartDataType(chartType);
  const {
    format,
    timeDropdown,
    timeInput,
    labelText,
    showMonthYearPicker,
  } = types;

  useEffect(() => {
    setTypes(chartType);
  }, [chartType, setTypes]);

  return (
    <DatePicker
      withPortal
      calendarClassName={`${style["calendar-style"]}`}
      showTimeSelect={timeDropdown}
      timeInputLabel={t("Time")}
      timeFormat="HH:mm"
      showTimeInput={timeInput}
      // NOTE: The date is shown in local time for the end user!
      selected={selected}
      onChange={onChange}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
      dateFormat={format}
      minDate={minDate}
      showMonthYearPicker={showMonthYearPicker}
      customInput={
        // Permutations for datepicker labelling:
        // t("Start Date")
        // t("End Date")
        // t("Start Month")
        // t("End Month")
        <DatePickerInput visibleDate={!!visibleDate}>{t(`${label} ${labelText}`)}</DatePickerInput>
      }
    />
  );
}
