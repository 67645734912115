import React from 'react';
import ToggleSwitch from '../../../Misc/ToggleSwitch/ToggleSwitch';

import styles from './Card.module.scss';

interface Props{
  title: string;
  active: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

export default function ControlUnitAlarmRow({
  title,
  active,
  disabled,
  onChange,
}: Props): React.ReactElement{

  return (
    <div className={styles.row}>
      <p className={styles.text}>{title}</p>
      <ToggleSwitch 
        checked={active}
        disabled={disabled}
        onChange={(checked) => {
          onChange(checked);
        }}
      />
    </div>
  )
}