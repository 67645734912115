import moment from "moment";
import React, {
  useState,
  useEffect,
  PropsWithChildren,
  CSSProperties,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { ChartData } from "../components/ChartData";
import { useChart } from "../contexts/ChartDataContext";
import { SpinnyThingy } from "../components/Misc/SpinnyThingy";
import {
  DeviceNode,
  ChartTypes,
  SensorData,
  SensorColours,
  Materials,
} from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import { Requester } from "../utils/Requester";
import { calculateMCValue } from "../utils/utility";
import { exportQueryDataFromNodes } from "./DisplayChart.helper";

interface Props {
  nodes: Array<DeviceNode>;
  noNodesAvailable: boolean;
  selSensorTypes: Array<TSensorTypes>;
  secondaryAxis: TSensorTypes | undefined;
  constantTime: boolean;
  startDate: Date;
  endDate: Date;
  legend: boolean;
  chartType: ChartTypes;
  forcedTimezone: string;
  style?: CSSProperties;
  loading: boolean;
  setLoading: (arg: boolean) => void;
  yMaxMain?: number;
  yMinMain?: number;
  yMaxSecond?: number;
  yMinSecond?: number;
  mcList?: Map<string, Materials>;
  isThisReport?: boolean;
}

interface ProcessedSensorData extends SensorData {
  dt: Date;
}

export function DisplayChart({
  nodes,
  noNodesAvailable,
  selSensorTypes,
  secondaryAxis,
  constantTime,
  startDate,
  endDate,
  chartType,
  style,
  legend,
  forcedTimezone,
  loading,
  yMaxMain,
  yMinMain,
  yMaxSecond,
  yMinSecond,
  mcList,
  setLoading,
  isThisReport,
}: PropsWithChildren<Props>): React.ReactElement {
  const [sortedData, setSortedData] = useState<Array<ProcessedSensorData>>([]);
  const [wmeData, setWmeData] = useState<Array<ProcessedSensorData>>([]);
  const [mcData, setMCData] = useState<Array<ProcessedSensorData>>([]);
  const chart = useChart();
  const { t } = useTranslation();

  const colours = useMemo(() => {
    const colorsNew: SensorColours = {};
    nodes.forEach((node) => {
      node.fields.forEach((sens) => {
        colorsNew[sens.front_end_name.toString()] = sens.sensor_color;
      });
    });
    return colorsNew;
  }, [nodes]);

  useEffect(() => {
    // Query all nodes when changed chart type/ start ime/ end time

    /* Pre-process parameters */
    // If we have MC selected, it should imply WME so we have source data
    // to calculate the MC from, but if WME wasn't explicity asked already
    // mark them for exclusion from chart -- "phantom".
    // We also mark WME "needed" if MC is selected to
    // prevent "WME implies MC" if WME was selected but MC was not
    const getParamNodes = exportQueryDataFromNodes(nodes);
    let phantomData: string[] = [];  // exclude these from `sortedData`
    let neededforMC: string[] = [];  // include *only* these in `wmeData`
    const processedParams = getParamNodes.map((n) => {
      if (!n.types.includes("MC"))
        return n;  // No processing if there's no MC
      // NOTE: We might filter out "MC", but it's harmless to pass forward
      //       as no MC data is stored in DB
      neededforMC.push(`WME_${n.id}`);  // mark WME as "needed"
      if (!n.types.includes("WME")) {
        // Add WME if it wasn't asked already and mark them "phantom"
        phantomData.push(`WME_${n.id}`)
        return {...n, types: [...n.types, "WME"]};
      }
      return n;
    });

    if (nodes.length > 0) {
      const getParams = {
        chartType,
        x1: startDate,
        x2: endDate,
        nodes: JSON.stringify(processedParams),
      };
      setLoading(true);
      void Requester.getChartDataCall(getParams)
        .then((data) => {
          const adjustedData = data.data.map((e) => {
            return {...e, dt: new Date(e.captured_datetime)};
          })
          .map((e) => {
            // Shift the timezone for sensor data
            e.captured_datetime = moment(e.captured_datetime)
              .tz(forcedTimezone)
              .format("YYYY-MM-DD HH:mm:ss");
            return e;
          });
          // Set data for charting
          // exluding the WME artificially added to the request
          setSortedData(adjustedData.filter(
            (e) => !phantomData.includes(e.distinct_chart_line)
          ));
          // Separate out data for MC calculation
          setWmeData(adjustedData.filter(
            (e) => neededforMC.includes(e.distinct_chart_line)
          ));
        })
        .then(() => setLoading(false));
    } else if (noNodesAvailable) {
      setLoading(false);
    }
  }, [chartType, startDate, endDate, forcedTimezone, nodes, noNodesAvailable, setLoading]);

  useEffect(() => {
    if (!mcList) return;
    let newMCData = Array<ProcessedSensorData>();
    // Chart color data uses node primary key, this will be used to look them up
    const nodeIdMap = new Map();
    for (const node of nodes) {
      nodeIdMap.set(node.local_id, node.id);
    }
    for (let data of wmeData) {
      const material = mcList.get(data.node_id);
      if (material) {
        newMCData.push({
          ...data,
          distinct_chart_line: `MC_${nodeIdMap.get(data.node_id)}`,
          sensor_type: TSensorTypes.MC,
          value: calculateMCValue(parseFloat(data.value), material).toString(),
        })
      }
    }
    setMCData(newMCData);
  }, [mcList, nodes, wmeData, setMCData]);

  const loadAllData = () => {
    void Requester.getInstallMaxRange({
      installationID: chart.installation.pk,
    }).then((res) => {
      chart.loadDataRange(
        new Date(res.data.min_time),
        new Date(res.data.max_time),
        true,
        false,
      );
    });
  }

  return (
    <ChartData
      selSensorTypes={selSensorTypes}
      secondaryAxis={secondaryAxis}
      legend={legend}
      style={style}
      data={sortedData.concat(mcData).sort((a, b) => {
        return a.dt.getTime() - b.dt.getTime();
      })}
      colors={colours}
      loading={loading}
      setLoading={setLoading}
      constantTime={constantTime}
      t={t}
      yMaxMain={yMaxMain}
      yMinMain={yMinMain}
      yMaxSecond={yMaxSecond}
      yMinSecond={yMinSecond}
      loadAllData={loadAllData}
      isThisReport={isThisReport}
    >
      <div className="container spinny-thingy-container" style={{height:"35rem"}}>
        <SpinnyThingy />
      </div>
    </ChartData>
  );
}
