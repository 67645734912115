import React, { ReactElement } from 'react';
import style from './PointSlider.module.scss';

interface Props {
  range: [number, number];
  value: number;
  enabled: boolean;
  onChange: (value: number) => void;
}

export function PointSlider({
  range,
  value,
  enabled,
  onChange
}: Props): ReactElement {
  const [min, max] = range;

  return (
    <div className={style["slider-container"]}>
      <div className={style["container"]}>
        <div className={style.background}/>
        <div
          className={style.range} 
          style={{
            left: `calc(${min}% + 11px)`, // + half of the handle
            right: `calc(${100-max}% + 11px)`
          }}
        />
        <input
          type="range"
          id="myRange"
          className={style["slider"]}
          min={min}
          max={max}
          step="1"
          value={value}
          disabled={!enabled}
          title={enabled? "" : "Check Enabled to configure"}
          onChange={(e) => {
            const val = Number(e.target.value);
            if (val >= min && val <= max) {
              onChange(val);
            }
          }}
          style={{
            width: `${max-min}%`,
            marginLeft: `${min}%`,
            marginRight: `${100-max}%`
          }}
        />
      </div>
      <div className={style["slider-label"]}>
        <label>0%</label>
        <label>100%</label>
      </div>
    </div>
  )
}