import React, { MouseEventHandler} from 'react';

import styles from './Card.module.scss';
import addIcon from './add_icon.svg';
import { Trans } from 'react-i18next';

interface Props {
  onAddEmail: MouseEventHandler;
}

export default function AddEmailRow({
  onAddEmail,
}: Props): React.ReactElement {
  return (
    <div 
      className={`${styles.row} ${styles.clickable}`}
      onClick={onAddEmail}
      >
      <img alt='' className={styles.icon} src={addIcon}/>
      <p className={styles.text}>
        <Trans>Add new e-mail</Trans>
      </p>
    </div>
  );
}