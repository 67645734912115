import React from "react";
import style from "./Footer.module.scss";

export function Footer(): React.ReactElement {
  return (
    <footer className={style["footer-real"]}>
      <>&copy; Dantherm Denmark A/S {new Date().getFullYear()}</>
    </footer>
  );
}
