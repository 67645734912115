import React, { ReactNode } from "react";
import style from "./WarningRow.module.scss";
import exclamation_triangle from "../../images/exclamation_triangle.svg";


interface Props {
  children: ReactNode
}

export function WarningRow({
  children,
}: Props) {
  return <div className={style.row}>
    <img src={exclamation_triangle} alt="WARNING" className={style.icon} />
    <p>{children}</p>
  </div>
}