import React, { ReactNode } from "react";
import styles from "./PageWrapper.module.scss";

interface Props {
  children: ReactNode;
}

// This, <ContentBox> and <PageTitleBox> are 3 containers were created as a
// replacement for <PaddedPageWrapper> splitting responsibilities allowing for
// more flexibility and reusability

/**
 * Page wrapper that provides grey background and page padding.
 * For white containers where content is placed see `<ContentBox>`
 */
export function PageWrapper({children}: Props) {
  return <div className={styles.page}
  >{children}</div>
}