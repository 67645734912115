import React, { ReactElement } from "react";

import styles from "./ColorLevelBar.module.scss";

interface Props {
  //represents how many colors will be displayed: 0 - none, 1 - first item in colorList, etc.
  level: number | null; 
  
  // List of CSS color strings for color bar segments
  // Length of colorList array also determines the number of segments ColorLevelBar will have
  colorList: Array<string>;  
}



export default function ColorLevelBar({
  level,
  colorList,
}: Props): ReactElement {
  return (
  <div className={styles["level-container"]}>
    { level 
    // create a div for each color string provided, if level is defined
    ? colorList.map((color, index) => 
      <div 
        className={styles.level}
        /* 
          As map function iterates through colorList the index of current element is used 
          to check against level to determine whether to draw current div in provided color 
          or disabled color (which is currently hard coded).
        */ 
        style={{backgroundColor: (index < level) ? color : "#eeeeee"}}
      />
    )
    // show "--" if level is undefined
    : <p className={styles["no-data"]}>--</p>
    }
  </div>
  );
}