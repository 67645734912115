import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './PortalModal.module.scss';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
}

export function PortalModal({
    children,
    isOpen,
    close,
}: Props): React.ReactElement {

  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) setDisplay(true);
  }, [isOpen]);

  return display ? ( 
    createPortal(
      <div 
        className={styles.wrapper}
        data-show={isOpen}
        onClick={() => close()}
        onScroll={event => event.stopPropagation()}
        >
        <div 
          className={styles.modal}
          data-show={isOpen}
          onClick={(event) => event.stopPropagation()}
          onAnimationEnd={() => !isOpen && setDisplay(false)}
        >
          {children}
        </div>
      </div>,
      document.body,
    )
  ) : <></>;
}