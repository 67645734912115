import moment from "moment";
import "moment-timezone";
import { useEffect, useState, useCallback } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  ChartGetParams,
  ChartTypes,
  ChartGetParamsStatic,
} from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import { Requester } from "../utils/Requester";
import { usePrevious } from "./UsePrevious";

export const TWO_WEEK_MILLISECONDS = 12096e5;

function stringQueryToObject(query: string): ChartGetParamsStatic {
  const currentGetParams: URLSearchParams = new URLSearchParams(query);

  const newParamObj: ChartGetParamsStatic = {
    // NOTE: These _don't_ set the defaults, all of these get overwritten
    //       by code below.
    allData: false,
    timezone: moment.tz.guess(),
    chartType: ChartTypes.Normal,
    linearData: false,
    nodes: [],
    x1: new Date(Date.now() - TWO_WEEK_MILLISECONDS),
    x2: new Date(),
    // ...except this one. This is changed only _conditionally._
    visibleDates: false,
  };

  const chartType: ChartTypes = currentGetParams.get("chartType") as ChartTypes;

  switch (chartType) {
    case ChartTypes.Normal:
      newParamObj.chartType = ChartTypes.Normal;
      break;
    case ChartTypes.Hourly:
      newParamObj.chartType = ChartTypes.Hourly;
      break;
    case ChartTypes.Daily:
      newParamObj.chartType = ChartTypes.Daily;
      break;
    case ChartTypes.Weekly:
      newParamObj.chartType = ChartTypes.Weekly;
      break;
    case ChartTypes.Monthly:
      newParamObj.chartType = ChartTypes.Monthly;
      break;
    default:
      break;
  }

  // NOTE: These do set the defaults. Be sure to define the `null` case.
  newParamObj.allData = currentGetParams.get("allData") === "true";
  newParamObj.secondaryAxis = currentGetParams.get("secondaryAxis") as TSensorTypes || undefined;
  newParamObj.timezone = currentGetParams.get("timezone") || moment.tz.guess();
  newParamObj.linearData = currentGetParams.get("linearData") !== "false";
  newParamObj.nodes = JSON.parse(currentGetParams.get("nodes") || "{}");

  if (currentGetParams.get("mMax") !== null) {
    newParamObj.yMaxMain = Number(currentGetParams.get("mMax"));
  }
  if (currentGetParams.get("mMin") !== null) {
    newParamObj.yMinMain = Number(currentGetParams.get("mMin"));
  }
  if (currentGetParams.get("sMax") !== null) {
    newParamObj.yMaxSecond = Number(currentGetParams.get("sMax"));
  }
  if (currentGetParams.get("sMin") !== null) {
    newParamObj.yMinSecond = Number(currentGetParams.get("sMin"));
  }

  const x1 = currentGetParams.get("x1");
  const x2 = currentGetParams.get("x2");
  if (x1 && x2) {
    newParamObj.x1 = new Date(x1);
    newParamObj.x2 = new Date(x2);
    newParamObj.visibleDates = true;
  } else {
    newParamObj.x1 = new Date(Date.now() - TWO_WEEK_MILLISECONDS);
    newParamObj.x2 = new Date();
  }

  return newParamObj;
}

export function generateQueryParamString(
  newParams: ChartGetParams,
  includeDates: boolean,
  includeTimezone = false,
): string {
  const newURLGetParams = new URLSearchParams();
  let newSearch = "";

  const nodes = encodeURIComponent(JSON.stringify(newParams.nodes || []));
  newURLGetParams.set("nodes", nodes);
  newSearch += `?nodes=${newURLGetParams.get("nodes")}`;

  if (includeDates) {
    newURLGetParams.set("x1", newParams.x1?.toISOString() || String(false));
    newURLGetParams.set("allData", String(false));

    newSearch += `&x1=${newURLGetParams.get("x1")}`;

    newURLGetParams.set("x2", newParams.x2?.toISOString() || String(false));
    newURLGetParams.set("allData", String(false));

    newSearch += `&x2=${newURLGetParams.get("x2")}`;
  }

  newURLGetParams.set("allData", String(newParams.allData || false));
  newSearch += `&allData=${newURLGetParams.get("allData")}`;

  newURLGetParams.set("linearData", String(newParams.linearData || true));
  newSearch += `&linearData=${newURLGetParams.get("linearData")}`;

  newURLGetParams.set(
    "chartType",
    newParams.chartType?.toString() || ChartTypes.Normal.toString(),
  );
  newSearch += `&chartType=${newURLGetParams.get("chartType")}`;

  if (includeTimezone) {
    newURLGetParams.set(
      "timezone",
      newParams.timezone?.toString() || moment.tz.guess(),
    );
    newSearch += `&timezone=${newURLGetParams.get("timezone")}`;
  }

  if (newParams.secondaryAxis !== undefined) {
    newURLGetParams.set("secondaryAxis", newParams.secondaryAxis);
    newSearch += `&secondaryAxis=${newURLGetParams.get("secondaryAxis")}`;
  }

  if (newParams.yMaxMain !== undefined) {
    newURLGetParams.set("mMax", newParams.yMaxMain.toString());
    newSearch += `&mMax=${newURLGetParams.get("mMax")}`;
  }

  if (newParams.yMinMain !== undefined) {
    newURLGetParams.set("mMin", newParams.yMinMain.toString());
    newSearch += `&mMin=${newURLGetParams.get("mMin")}`;
  }

  if (newParams.yMaxSecond !== undefined) {
    newURLGetParams.set("sMax", newParams.yMaxSecond.toString());
    newSearch += `&sMax=${newURLGetParams.get("sMax")}`;
  }

  if (newParams.yMinSecond !== undefined) {
    newURLGetParams.set("sMin", newParams.yMinSecond.toString());
    newSearch += `&sMin=${newURLGetParams.get("sMin")}`;
  }

  return newSearch;
}

export function queryParamFactory(newParams: ChartGetParams): string {
  // This is intended for use when there's no need to update the actual URL
  // parameters, just render a new string based off of parameters
  return generateQueryParamString(newParams, true);
}

export function useChartGetParams(): [
  ChartGetParamsStatic,
  (arg0: ChartGetParams) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const params: { id: string | undefined } = useParams();
  const [updateParams, setUpdateParams] = useState<ChartGetParamsStatic>(
    stringQueryToObject(location.search),
  );
  const previousAllData = usePrevious(updateParams.allData);

  // When page is refreshed then the custom hook usePrevious inital returned
  // value will be undefined and using this we can distinguish between
  // components being mounted plus rendered and components that react to user   
  useEffect(() => {
    if (
      updateParams.allData !== previousAllData 
      && previousAllData === undefined 
      && updateParams.allData === true
    ) {
      void Requester.getInstallMaxRange({
        installationID: Number(params.id) || -1,
      }).then((res) => {
        const updateObj = { x1: new Date(res.data.min_time), x2: new Date(res.data.max_time) };
        setUpdateParams(prevState => {
          return { ...prevState, ...updateObj }
        });
      });
    }
  }, [previousAllData, updateParams.allData, params.id]);

  const updateChartParams = useCallback((newParams: ChartGetParams) => {
      setUpdateParams(prevState => {
        return { ...prevState, ...newParams }
      });
  }, []);

  useEffect(() => {
    const newSearch = generateQueryParamString(updateParams, updateParams.visibleDates);
    history.replace({
      pathname: "",
      search: newSearch,
    });
  }, [history, updateParams]);

  return [updateParams, updateChartParams];
}
