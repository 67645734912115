import React from "react";
import { useTranslation } from "react-i18next";
import style from "./ColorChanger.module.scss";
import { Materials } from "../../types/types";
import { TSensorTypes } from "../../types/generated_types";

import { isNonChartableRow, roundData } from "../../utils/utility";
import ColorLevelBar from "./ColorLevelBar";
import { getUnit } from "../../types/generated_types";

export enum DisplayMode {
  Text,
  IAQLevel,
}

interface Props {
  sensorEnabled: boolean;
  pickerEnabled: boolean;
  buttonStyle: React.CSSProperties;
  newColorStyle: React.CSSProperties;
  oldColorStyle: React.CSSProperties;
  children: React.ReactNode;
  toggleColorPicker: () => void;
  updateColorGlobal: () => void;
  latestData: string | null;
  sensorType: TSensorTypes;
  displayMode: DisplayMode;
  material?: Materials;
}

export function ColorChanger({
  sensorEnabled,
  buttonStyle,
  children,
  newColorStyle,
  oldColorStyle,
  pickerEnabled,
  toggleColorPicker,
  updateColorGlobal,
  latestData,
  sensorType,
  displayMode,
  material,
}: Props):React.ReactElement {
  const { t } = useTranslation();
  const latestDataRounded = roundData(latestData, sensorType, t, material);
  // This is here so that the text in the latest data box scales with its length.
  const latestDataString = `${latestDataRounded} ${getUnit(sensorType)}`;

  const maxTextWidth = 9;
  const maxFontSize = 0.8;

  const latestDataFontSize = maxTextWidth / latestDataString.length;

  const fontSizeStyle = {
    fontSize: latestDataFontSize > maxFontSize ? `${maxFontSize}rem` : `${latestDataFontSize}rem`,
  };

  let latestDataContents;

  switch(displayMode) {
    case DisplayMode.Text:
      latestDataContents = latestDataString;
      if (sensorType === TSensorTypes.WME && latestData !== null) {
        if (Number(latestData) >= 30) latestDataContents = "≥" + latestDataContents;
        if (Number(latestData) <= 10) latestDataContents = "≤" + latestDataContents;
      }
      break;
    case DisplayMode.IAQLevel:
      latestDataContents = (
        <ColorLevelBar 
        // Empty strings also fail condition therefore passing null instead in that case
        level={latestData ? Number.parseInt(latestData) : null}
        colorList={[
          "#4CAF50",
          "#CDDC39",
          "#FFEB3B",
          "#FF9800",
          "#FF5722",
        ]}
        />
      );
      break;
  }

  return (
    <div className={style["color-wrapper"]}>
      <div className={style["data-color-container"]}>
        <div
          className={style["latest-data-style"]}
          style={fontSizeStyle}
        >
          {latestDataContents}
        </div>
        <div
          className={style["color-picker-button-style"]}
          role="button"
          aria-label="Toggle color picker"
          style={sensorEnabled ? buttonStyle : { ...buttonStyle, cursor: "not-allowed" }}
          onClick={sensorEnabled ? toggleColorPicker : () => null}
          tabIndex={-1}
          hidden={isNonChartableRow(sensorType)}
        />
      </div>
      {pickerEnabled ? (
        <div className={style["popover"]}>
          {/* The div below is responsible for disabling the color picker if
          clicked outside of it */}
          <div
            className="color-picker-disabler"
            role="button"
            aria-label="Toggle color picker"
            onClick={toggleColorPicker}
            tabIndex={-1}
          />
          <div className="card">
            <div className="card-header p-0 font-weight-bold">
              <div className="row pb-0 mb-0">
                <div className="col-6 pr-0 pb-0 mb-0">
                  <button
                    type="button"
                    style={newColorStyle}
                    className="p-0 m-0 update-color-global color-changer-style"
                    onClick={updateColorGlobal}
                  >
                    {t("Save Color")}
                  </button>
                </div>
                <div className="col-6 pl-0">
                  <button
                    type="button"
                    style={oldColorStyle}
                    className="p-0 m-0 keep-old-color color-changer-style"
                    onClick={toggleColorPicker}
                  >
                    {t("Keep Old")}
                  </button>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
}
