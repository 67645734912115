import { DeviceNode } from "../types/types";

export function toggleNameEdit(
  nameEditOnState: boolean,
  setNameEditOnState: (arg0: boolean) => void,
): void {
  setNameEditOnState(!nameEditOnState);
}

export function validateNewName(value: string): boolean {
  // Check if not too short and not too long
  let valid = value.length <= 25 && value.length >= 1
  return valid;
}

export function revertChanges(
  setNameEditOnState: (arg0: boolean) => void,
  setDisplayErrorState: (arg0: boolean) => void,
  setNewNameState: (arg0: string) => void,
): void {
  setNameEditOnState(false);
  setDisplayErrorState(false);
  setNewNameState("");
}

export function updateSensorNameWrapper(
  node: DeviceNode,
  newNameState: string,
  setNameEditOnState: (arg0: boolean) => void,
  displayErrorState: boolean,
  updateSensorName: (newName: string, nodeID: string) => void,
): void {
  if (!displayErrorState) {
    setNameEditOnState(false);
    updateSensorName(newNameState, node.id.toString());
  }
}
