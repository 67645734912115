import React, { ReactNode } from "react";
import styles from "./ContentBox.module.scss";

interface Props {
  children: ReactNode;
}

// This, <PageWrapper> and <PageTitleBox> are 3 containers were created as a
// replacement for <PaddedPageWrapper> splitting responsibilities allowing for
// more flexibility and reusability

/**
 * Container in which to display page content, a white container with border
 * and inner padding, intended to be placed on grey background.
 * Recommended to be placed in `<PageWrapper>` which gives page a grey background
 * and provides page padding. Optionally can be preceeded with `<TabsContainer>`
 * if tabs are desired.
 */
export function ContentBox({children}: Props) {
  return <div className={styles.box}>{children}</div>
}