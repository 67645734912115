// https://stackoverflow.com/a/54343182
import { History } from "history";
import $ from "jquery";
import React, { useEffect, ReactElement } from "react";
import { withRouter } from "react-router-dom";

interface Props {
  history: History,
  children: ReactElement
}

function ScrollToTop({ history, children }: Props): ReactElement {
  useEffect(() => {
    const unlisten = history.listen(() => {
      // Remove any modals left from the previous page. Horrible quickfix. 
      // TODO: Remake all modals to have a context and rely on useState to determine if it should be up.
      $(".modal").hide();
      $(".modal").removeClass("show");
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "");
      $(".fixed-top").css("padding-right", "");

      // Don't scroll to top on installation views
      if (
        history.location.pathname.startsWith("/dashboard/active/installation/")
        || history.location.pathname.startsWith("/dashboard/archive/installation/")) {
        return;
      }
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

// eslint-disable-next-line import/no-default-export
export default withRouter(ScrollToTop as any);
