import React from "react";
import styles from "./EnergySavingVisualization.module.scss";
import { EnergySavingPattern } from "./EnergySavingView";

interface Props {
  pattern: EnergySavingPattern;
}

function PatternSVGGen(pattern: EnergySavingPattern, xScale: number) {
  var svgElements: Array<JSX.Element> = [];
  var patterns = [pattern.init_pattern, pattern.loop_pattern];

  var xOffset = 0;    // Essentially functions as a sort of "current width"
  var xStartLoop = 0;
  var currState = true;
  var lineLen = 0;
  var linePath = "M 0 0.5 "
  patterns.forEach((segments, index) => {
    for (var i = 0; i < segments.length; i++) {
      for (var x = 0; x < segments[i].x; x++) {
        if (segments[i].on > 0) {
          if (currState === false)
            linePath += `V 0.5 `;

          lineLen = segments[i].on / 60 * xScale;
          xOffset += lineLen;
          linePath += `H ${xOffset} `;

          currState = true;
        }

        if (segments[i].off > 0) {
          if (currState === true)
            linePath += `V 12 `;

          lineLen = segments[i].off / 60 * xScale;
          xOffset += lineLen;
          linePath += `H ${xOffset} `;

          currState = false;
        }

      }
    }
    if (index+2 === patterns.length) {
      xStartLoop = xOffset;
      svgElements.push(<line x1={xStartLoop} y1="0" x2={xStartLoop} y2="20" stroke="#FF0000" />);
    }

  });

  svgElements.push(<path d={linePath} fill="transparent" stroke="black" />);

  // The "Ruler"
  var majorDivision = 12; // Major line and number every x hours
  var minorDivision = 2;  // Small line every x hours
  var hours = 0
  var majorDivisionPath = "";
  var minorDivisionPath = "";
  for (var x = 0; x < xOffset; x += xScale) {
    if (hours % majorDivision === 0) {
      majorDivisionPath += `M ${x} 16 V 19 `;
      svgElements.push(<text x={x} y="25" fill="black" fontSize="6" textAnchor="middle">{hours}</text>);
  } else if (hours % minorDivision === 0) { 
    minorDivisionPath += `M ${x} 19 V 25 `;
  }
    hours++;
  }

  svgElements.push(<path d={majorDivisionPath} fill="transparent" stroke="#C7C7C7" />);
  svgElements.push(<path d={minorDivisionPath} fill="transparent" stroke="#E0E0E0" />);

  // The current "position" of the device in the pattern
  var xGreenLine = pattern.elapsed_time / 60 * xScale;
  if (xGreenLine > xStartLoop) {
    var loopLen = xOffset - xStartLoop;
    var xOnLoop = (xGreenLine - xStartLoop) % loopLen;
    xGreenLine = xStartLoop + xOnLoop;
  }

  svgElements.push(<line x1={xGreenLine} y1="0" x2={xGreenLine} y2="20" stroke="#00B100" />);

  return <svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox={`-1.5 0 ${xOffset} 30`}>
    {svgElements}
  </svg>;
}

export function EnergySavingVisualization({
  pattern,
}: Props) {
  return <div className={styles.container}>
    {PatternSVGGen(pattern, 3) }
  </div>;
}