import React, { createContext, useState, useEffect } from "react";
import { Requester } from "../utils/Requester";
import { isMessageResponse } from "../utils/utility";
import { MessageResponse } from "../types/requesterTypes";
import { User } from "../types/types";

interface Props {
  children: JSX.Element;
}

export interface UserContextInterface {
  user: User | null;
  fetchingUser: boolean;
  fetchUser: () => void;
  logIn: (
    username: string,
    password: string
  ) => Promise<MessageResponse | undefined>;
  logOut: () => void;
}

export const ctxt = createContext<UserContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in UserContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as UserContextInterface, {
    apply: () => {
      throw new Error("You must wrap your component in an UserContextProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an UserContextProvider");
    },
  }),
);

const { Provider } = ctxt;

function UserContextProvider({ children }: Props): React.ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const [fetchingUser, setFetchingUser] = useState<boolean>(true);

  // fetch data about the user logged in right now
  useEffect(() => {
    Requester.getUser().then((loggedInUser) => {
      if (!isMessageResponse(loggedInUser)) {
        if (loggedInUser.data) {
          setUser(loggedInUser.data);
        } else {
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setFetchingUser(false);
    }).catch((err) => {
      setUser(null);
      setFetchingUser(false);
    });
  }, [fetchingUser]);

  return (
    <Provider
      value={{
        user,
        fetchingUser,
        fetchUser: () => setFetchingUser(true),
        logIn: async (username: string, password: string) => {
          setUser(null);
          const loggedInUser = await Requester.loginUser({
            username,
            password,
          }).catch((err) => {
            if (isMessageResponse(err)) {
              return err;
            }
            return undefined;
          });
          if (!isMessageResponse(loggedInUser)) {
            if (loggedInUser && loggedInUser.data) {
              setUser(loggedInUser.data);
            } else {
              setUser(null);
            }
            return undefined;
          }
          return loggedInUser;
        },
        logOut: () => {
          setUser(null);
          Requester.logoutUser().catch(
            (err) => console.log(err)
          ).finally(
            () => window.location.reload()
          );
        },
      }}
    >
      {children}
    </Provider>
  );
}

const useUser = (): UserContextInterface => React.useContext(ctxt);

export { UserContextProvider, useUser };
