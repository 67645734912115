import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: () => void;
}

export function RightSideButton({ onClick }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      type="button"
      className="btn btn-light btn-lg btn-outline-dark btn-wizard"
      style={{borderRadius: "0px"}}
    >
      <i className="pr-2 fas fa-arrow-left" /> {t("BACK")}
    </button>
  );
}
