import React, { useState } from "react";
import styles from "./CloudControlButton.module.scss";
import cloudOn from "../../images/cloud_on.svg";
import cloudOff from "../../images/cloud_off.svg";
import cloudDisabled from "../../images/cloud_disabled.svg";

import { useTranslation } from "react-i18next";
import { PortalModal } from "../PortalModal/PortalModal";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { DeviceNodeAD9 } from "../../types/types";
import { SelectorRow } from "../PortalModal/SelectorRow";
import { Requester } from "../../utils/Requester";
import { useChart } from "../../contexts/ChartDataContext";
import { CloseButton } from "../PortalModal/CloseButton";
import { ButtonStyle, ModalButton } from "../PortalModal/ModalButton";
import toast from "react-hot-toast";

interface Props {
  node: DeviceNodeAD9,
}

export function CloudControlButton({
  node,
}: Props){
  const [showModal, setShowModal] = useState(false);
  const chart = useChart();

  const { t } = useTranslation();

  return <>
    <button
      className={styles.button}
      onClick={() => setShowModal(true)}
      disabled={node.latest_data === null}
      data-off={!node.cloud_control}
    >
      <img
        src={
          node.latest_data === null ? cloudDisabled
          : node.cloud_control ? cloudOn
          : cloudOff
        }
        alt="" />
      <span>{node.cloud_control ? t("ON") : t("OFF")}</span>
    </button>
    <PortalModal
      isOpen={showModal}
      close={() => setShowModal(false)}
    >
      <PortalModalHeader
        title={node.cloud_control ? t("Deactivate cloud control") : t("Activate cloud control")}
        close={() => setShowModal(false)}
      />
      <PortalModalContent narrow>
        <SelectorRow
          label={t("Current state")}
        >
          {node.cloud_control ? t("ON") : t("OFF")}
        </SelectorRow>

      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          onClick={() => {
            Requester.setCloudControl({
              ino_pk: node.id,
              cloud_control: !node.cloud_control, // toggle cloud control
            })
            .then(() => {
              setShowModal(false);
              toast.success(t("Saved").toString());
              chart.refreshNodes();
            })
            .catch(() => toast.error(t("Something went wrong").toString()));
          }}
          data-isOn={node.cloud_control}
          buttonStyle={node.cloud_control ? ButtonStyle.RED : ButtonStyle.GREEN}
        >
          {node.cloud_control ? t("TURN OFF") : t("TURN ON")}
        </ModalButton>
        <CloseButton close={() => setShowModal(false)} />
      </PortalModalFooter>
    </PortalModal>
  </>
}