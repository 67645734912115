import React, { PureComponent } from "react";
import style from "./CommonStyles.module.scss";

interface Props {
  onClick?: () => void;
  value?: string;
  children: string;
  visibleDate: boolean;
}

export class DatePickerInput extends PureComponent<Props> {
  render(): React.ReactNode {
    const { children, onClick, value, visibleDate } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.label}>{children}</div>
        <div className={style.content}>
          <input
            // This on change is just a "hack" so the browsers console does not
            // throw errors that a component has a value field without
            // "onChange" property.
            onChange={() => {}}
            value={visibleDate ? value : ""}
            onClick={onClick}
            type="text"
            className="form-control"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>
    );
  }
}
