import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import logo from "../../images/logo_80px.png";
import { useUser } from "../../contexts/UserContext";

import style from "./Header.module.scss";
import styleBottom from "./HeaderBottom.module.scss";

import {
  AdminPanelOptionsComplete,
  DashboardMainOptions,
  HeaderBottomComplete,
} from "./HeaderBottom";
import { Roles } from "../../types/types";
import { Requester } from "../../utils/Requester";


enum Expandable {
  ADMIN,
  DASHBOARD,
  NONE,
}

export function Header(): React.ReactElement {
  const { user, logOut } = useUser();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // Defines the currently expanded item from the menu list
  const [expandable, setExpandable] = useState<Expandable>(Expandable.NONE);
  const [burgerExpand, setBurgerExpand] = useState(false);
  const [languages, setLanguage] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    Requester.getLanguageList().then((result) => setLanguage(result));
  }, []);

  // Pre-determine the selected page
  const isAdmin = history.location.pathname.startsWith("/dashboard/admin");
  const isAccount = history.location.pathname.startsWith("/dashboard/account");
  const isDashboard = !(isAdmin || isAccount);

  useEffect(() => {
    setBurgerExpand(false);
    if (isAdmin) setExpandable(Expandable.ADMIN);
    if (isDashboard) setExpandable(Expandable.DASHBOARD);
  }, [history.location, isAdmin, isDashboard]);

  // save in local cache the default selected language and use that one
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      void i18n.changeLanguage(lang);
    }
  }, [i18n, languages]);

  useEffect(() => {
    localStorage.setItem("lang", i18n.language);
  }, [i18n.language]);

  const liClassName = style.fade;
  return (
    <div className={`fixed-top ${style["stack-order-ham"]}`}>
      <nav className={style.navbar} role="navigation">
        <div className={style.left}>
          <a href={user !== null ? "/dashboard/list/active" : ""}>
            <img src={logo} alt="Simplify®" />
          </a>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={style.hamburger}
          onClick={() => setBurgerExpand(!burgerExpand)}
        >
          <div className={style.line} />
          <div className={style.line} />
          <div className={style.line} />
        </div>
        <ul
          className={`${style["nav-links"]} ${burgerExpand ? style.open : ""}`}
        >
          {user !== null ? (
            <>
              <li className={liClassName}>
                <div className={isDashboard ? style.selected : ""}>
                  <NavLink
                    to="/dashboard/list/active"
                    className={styleBottom["hide-on-mobile"]}
                  >
                    <div className={style["nav-btn-wrapper"]}>
                      <i className="fas fa-chart-area mr-1" />
                      {t("Dashboard")}
                    </div>
                  </NavLink>
                  <div
                    tabIndex={0}
                    role="button"
                    className={style.carret}
                    onClick={() =>
                      setExpandable(
                        expandable === Expandable.DASHBOARD
                          ? Expandable.NONE
                          : Expandable.DASHBOARD,
                      )
                    }
                  >
                    <i className="fas fa-chart-area mr-1" />
                    {t("Dashboard")}
                    <i
                      className={`fas fa-caret-${
                        expandable === Expandable.DASHBOARD ? "down" : "right"
                      } ${styleBottom["show-on-mobile"]}`}
                    />
                  </div>
                </div>
              </li>
              <DashboardMainOptions
                aActiveClassName={style.active}
                liClassName={`${liClassName} ${style["sub-par-menu"]} ${
                  expandable === Expandable.DASHBOARD ? "" : "d-none"
                }`}
              />
              {user.role !== Roles.User && (
                <>
                  <li className={liClassName}>
                    <div className={isAdmin ? style.selected : ""}>
                      <NavLink
                        to="/dashboard/admin/users"
                        className={styleBottom["hide-on-mobile"]}
                      >
                        <div className={style["nav-btn-wrapper"]}>
                          <i className="fas fa-user-shield mr-1" />
                          {t("Admin")}
                        </div>
                      </NavLink>
                      <div
                        tabIndex={0}
                        role="button"
                        className={style.carret}
                        onClick={() =>
                          setExpandable(
                            expandable === Expandable.ADMIN
                              ? Expandable.NONE
                              : Expandable.ADMIN,
                          )
                        }
                      >
                        <i className="fas fa-user-shield mr-1" />
                        {t("Admin")}
                        <i
                          className={`fas fa-caret-${
                            expandable === Expandable.ADMIN ? "down" : "right"
                          } ${styleBottom["show-on-mobile"]}`}
                        />
                      </div>
                    </div>
                  </li>
                  <AdminPanelOptionsComplete
                    aActiveClassName={style.active}
                    liClassName={`${liClassName} ${style["sub-par-menu"]} ${
                      expandable === Expandable.ADMIN ? "" : "d-none"
                    }`}
                  />
                </>
              )}
              <li className={liClassName}>
                <NavLink
                  activeClassName={style.selected}
                  to="/dashboard/account"
                >
                  <div className={style["nav-btn-wrapper"]}>
                    <i className="fas fa-user-circle mr-1" />
                    {`${t("Account")} (${user.username})`}
                  </div>
                </NavLink>
              </li>
            </>
          ) : null}
          <li className={liClassName}>
            <div className={`dropdown ${style.dropdown}`}>
              <div
                className={`btn-account d-flex ${style["nav-btn-wrapper"]}`}
                role="button"
                id="languageDropdown"
                data-toggle="dropdown"
              >
                <i className="fas fa-globe-europe mr-1" />
                {languages[i18n.language]}
                <div className="triangle-const-width">
                  <i
                    className={`fas fa-caret-right ${style["margin-reset"]}`}
                  />
                </div>
              </div>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                {Object.keys(languages).map((k) => (
                  <button
                    key={k}
                    type="button"
                    className="dropdown-item"
                    onClick={() => i18n.changeLanguage(k)}
                  >
                    {languages[k]}
                  </button>
                ))}
              </div>
            </div>
          </li>

          {user !== null ? (
            <li className={liClassName}>
              <div
                role="button"
                tabIndex={0}
                className={`btn ${style.logout}`}
                onClick={() => logOut()}
              >
                <div className={style["nav-btn-wrapper"]}>
                  <i className="fas fa-door-open mr-1" />
                  {t("Logout")}
                </div>
              </div>
            </li>
          ) : null}
        </ul>
      </nav>
      <div
        id="cst-secondary-nav"
        className={`col-lg-12 hidden-xs hidden-sm shadow ${styleBottom["hide-on-mobile"]}`}
      >
        <div className="container-fluid">
          <ul className="nav nav-pills">
            <HeaderBottomComplete />
          </ul>
        </div>
      </div>
    </div>
  );
}
