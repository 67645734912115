import React, { ReactNode } from 'react';

import style from './Card.module.scss';

interface Props {
  children: ReactNode;
}

export default function CardContainer({children}: Props): React.ReactElement {
  return (
    <div className={style['card-container']}>
      {children}
    </div>
  );
}