import React, { MouseEventHandler } from 'react';

import style from './TitleBarButton.module.scss';

interface Props {
  text: string;
  onClick: MouseEventHandler;
}

export default function TitleBarButton({
  text,
  onClick,
}: Props): React.ReactElement {
  return (
    <button
      className={style['button']}
      onClick={onClick}
    >
      {text}
    </button>
  );
}