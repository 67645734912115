/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { ColorChangerContainer } from "../../containers/ColorChangerContainer";
import {
  NodeFields,
  DeviceNode,
  Materials,
  isSTESubsensor,
} from "../../types/types";
import { NodeTypes, TSensorTypes } from "../../types/generated_types";

import { Requester } from '../../utils/Requester';

import { useModalSensorData } from "../../contexts/ModalSensorDataContext";

import style from "./NodeInfo.module.scss";
import { PowerButton } from "./PowerButton";
import { useChart } from "../../contexts/ChartDataContext";

import editIcon from "../../images/pencil-thick.svg";
import saveIcon from "../../images/tick.svg";
import cancelIcon from "../../images/cross.svg";
import { TypeTag } from "../Misc/TypeTag";
import { DisplayMode } from "./ColorChanger";
import { MCRow } from "./MCRow";
import { calculateMCValue, isNonChartableRow } from "../../utils/utility";
import { MoistureIndicator } from "./MoistureIndicator";
import { InfoBubble } from "../Misc/InfoBubble";
import SetpointContainer from "../../containers/SetpointContainer";
import { QuietModeScheduler } from "./QuietModeScheduler";
import { HeaterConfigButton } from "./HeaterConfigButton";
import { FanCConfigButton } from "./FanCConfigButton";

interface Props {
  sensor: NodeFields;
  dbId: number;
  onCheckboxChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
  onSensorColorChange: (
    color: string,
    sensorType: TSensorTypes,
  ) => void;
  node: DeviceNode;
  isArchived: boolean;
  latestData: string | null;
}

export function UpdateRelayLabel(
  nodeId: number,
  sensorType: TSensorTypes,
  newName: string,
  setEditLabel: (arg0: boolean) => void,
  updateSensorName: (nId: number, localId: number, newLabel: string) => void,
): void {
  if (validateLabel(newName)) {
    setEditLabel(false);
    const localId = relayLocalId(sensorType);
    if (localId !== undefined) {
      updateSensorName(nodeId, localId, newName);
    }
  } else {
    toast.error("Unable to save label.");
  }
}

export function relayLocalId(sensorType: string): number | undefined {
  switch (sensorType) {
    case TSensorTypes.STE1: {
      return 1;
    }
    case TSensorTypes.STE2: {
      return 2;
    }
    case TSensorTypes.STE3: {
      return 3;
    }
    case TSensorTypes.STE4: {
      return 4;
    }
    default: {
      return undefined;
    }
  }
}

export function validateLabel(value: string): boolean {
  // Check if the given label isn't too long
  const valid = value.length <= 25;
  return valid;
}

function prepareMCValue(node: DeviceNode, materialMap: Map<string, Materials>) {
  const wme = node.fields.find(field => field.sens_type === TSensorTypes.WME)?.latest_data;
  const material = materialMap.get(node.local_id);
  if (!wme) return null;
  if (!material) return null;
  // Resulting MC values can't be shown as negative
  return Math.max(0, 
    calculateMCValue(Number.parseFloat(wme), material)).toString();
}

function getWMEValue(node: DeviceNode) {
  const wmeValue = node.fields.find(field => field.sens_type === TSensorTypes.WME)?.latest_data;
  // when WME is null (or not found) it will return null instead of Number(null) which would 
  // result in 0
  return wmeValue ? Number(wmeValue) : null;
}

export function SensorRow({
  dbId,
  node,
  onCheckboxChange,
  onSensorColorChange,
  sensor,
  isArchived,
  latestData,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [state, color] = sensor.enabled
    ? [style["enabled-type"], sensor.sensor_color]
    : [style["disabled-type"], "#c2c2c2"];
  const sensorDataModal = useModalSensorData();

  const [editLabel, setEditLabel] = useState(false);
  const [newLabel, setNewLabel] = useState(sensor.label ? sensor.label : "");

  const { updateRelayLabel, materialMap } = useChart();

  const [expectedMode, setExpectedMode] = useState("");
  const [hml, setHml] = useState<number>();
  const latestDataBool = latestData === "1" ? true: false;

  let isPending = false;
  useEffect(()=>{
    if (sensor.sens_type === TSensorTypes.HML) {
      Requester.getDehumidifierMode(node.id)
        .then(res => {
          setHml(res.hml);
          res.dew_point ? setExpectedMode("3") :
          res.hml !== null ? setExpectedMode("1") : setExpectedMode("0")
        })
    }
  },[node, sensor.sens_type])

  if (node.node_type === NodeTypes.AD9 && node.cloud_control) {
    if (sensor.sens_type === TSensorTypes.HEAT) {
      isPending = latestDataBool !== node.ohtr;
    }
    if (sensor.sens_type === TSensorTypes.FMODE) {
      isPending = latestDataBool !== node.fanc;
    }
    if (sensor.sens_type === TSensorTypes.HML) {
      isPending = expectedMode !== node.omod
      if (node.omod === "1") {
        isPending = Number(latestData) !== hml;
      }
    }
  }

  const relayLabel = editLabel === true ? (
    <>
      <div className={style.label}>
        <span>
          {t("Label")}{": "}
        </span>
        <input
          maxLength={25}
          type="text"
          className={style["input-box"]}
          value={newLabel}
          placeholder={t("New Label")}
          name="label"
          onChange={(e) => {
            setNewLabel(e.target.value);
          }}
        />
      </div>
      <div className={style["button-container"]}>
        <button
          type="button"
          className={style.button}
          onClick={() => UpdateRelayLabel(
            node.id,
            sensor.sens_type,
            newLabel,
            setEditLabel,
            updateRelayLabel,
          )}
        >
          <img src={saveIcon} alt={t("Save")} className={style["label-icons"]} />
          {/* {t("Save")} */}
        </button>
        <button
          type="button"
          className={style.button}
          onClick={() => {
            setEditLabel(false);
            setNewLabel(sensor.label || "");
          }}
        >
          <img src={cancelIcon} alt={t("Cancel")} className={style["label-icons"]} />
        </button>
      </div>
    </>
  ) : (
    <>
      <div>
        <span className={style.label}>
          {t("Label")}{": "}
        </span>
        {/* sensor.label can be either None or "" (empty string) either way its going count as false */}
        <span className={sensor.label ? style.label : style["no-label"]}>
          {sensor.label ? sensor.label : "(no label)"}
        </span>
      </div>
      <div>
        <button
          type="button"
          className={style.button}
          onClick={() => {
            setEditLabel(true);
            setNewLabel(sensor.label || "");
          }}
        >
          <img src={editIcon} alt={t("Edit")} className={style["label-icons"]} />
          {/* {t("Edit")} */}
        </button>
      </div>
    </>
  );

  const latestDataProp = sensor.sens_type === TSensorTypes.MC 
  ? prepareMCValue(node, materialMap)
  : latestData;

  return (
    <>
      <div className={`${state} ${style["sensor-row"]}`}>
        <div className={style.left}>
          <span className={style["default-input"]}>
            <input
              id={sensor.front_end_name}
              type="checkbox"
              checked={sensor.enabled}
              onChange={onCheckboxChange}
              data-hidden={isNonChartableRow(sensor.sens_type)}
            />
            <TypeTag sensorType={sensor.sens_type} />
          </span>
          <div className="color-pick">
            <ColorChangerContainer
              sensorEnabled={sensor.enabled}
              latestData={latestDataProp}
              material={materialMap.get(node.local_id)}
              nodeID={dbId}
              sensorType={sensor.sens_type}
              color={color}
              onSensorColorChange={(
                colorArg: string,
                sensorType: TSensorTypes,
              ) => onSensorColorChange(colorArg, sensorType)}
              displayMode={sensor.sens_type === TSensorTypes.IAQ
                ? DisplayMode.IAQLevel
                : DisplayMode.Text
              }
            />
          </div>
          { sensor.sens_type === TSensorTypes.WME &&
            <>
              <MoistureIndicator
                wmeValue={getWMEValue(node)}
                />
            </>
          }
          { sensor.sens_type === TSensorTypes.MC &&
            getWMEValue(node) !== null &&
            Number(latestDataProp) <= 0 &&
            <div className={style["infobubble-container"]}>
              <InfoBubble>
                <p style={{width: "13rem", textAlign: "left"}}>
                  <Trans i18nKey="_abnormally_dry">
                    <b>Abnormally dry!</b> Check if correct material has
                    been selected or if probe is placed correctly.
                  </Trans>
                </p>
              </InfoBubble>
            </div>
          }
          { node.node_type === NodeTypes.AD9 && 
            <>
              { sensor.sens_type === TSensorTypes.HML &&
                <>
                  <SetpointContainer node={node}/>
                  { isPending && 
                    <InfoBubble>
                      <p style={{whiteSpace: "nowrap"}}>
                        {t("Change pending")}
                      </p>
                    </InfoBubble>
                  }
                </>
              }
              { sensor.sens_type === TSensorTypes.HEAT &&
                <>
                <HeaterConfigButton
                  node={node}
                  disabled={!node.cloud_control}
                />
                  { isPending && 
                    <InfoBubble>
                      <p style={{whiteSpace: "nowrap"}}>
                        {t("Change pending")}
                      </p>
                    </InfoBubble>
                  }
                </>
              }
              { sensor.sens_type === TSensorTypes.FMODE &&
                <>
                  <FanCConfigButton
                    node={node}
                    disabled={!node.cloud_control}
                  />
                  { isPending && 
                    <InfoBubble>
                      <p style={{whiteSpace: "nowrap"}}>
                        {t("Change pending")}
                      </p>
                    </InfoBubble>
                  }
                </>
              }
              { sensor.sens_type === TSensorTypes.SPEED &&
                <QuietModeScheduler node={node} />
              }
            </>
          }
          { (node.node_type === NodeTypes.IMI
              || node.node_type ===  NodeTypes.IMRMk2
              || node.node_type ===  NodeTypes.CC4
              || node.node_type === NodeTypes.IMR
            ) &&
            isSTESubsensor(sensor.sens_type) &&
            !isArchived &&
            <PowerButton
              isOn={sensor.state_is_on || false}
              outlet={sensor.sens_type}
              node={node}
            />
          }
        </div>
        <button
          type="button"
          className={style["view-all-data"]}
          data-toggle="modal"
          data-target="#individualSensorDataModal"
          onClick={() => {
            sensorDataModal.openSensorDataModal(node, sensor.sens_type);
          }}
          hidden={isNonChartableRow(sensor.sens_type)}
        >
          {t("See all data")}
        </button>
        { /* Display the label row only if the sensor type has a local id for it */ }
        { relayLocalId(sensor.sens_type) && <div className={style["second-row"]}>{relayLabel}</div> }

        {/* Add material selector for MC */
          (sensor.sens_type === TSensorTypes.MC) &&
            <div className={style["second-row"]}>
              <MCRow node={node} />
            </div>
        }
      </div>
    </>
  );
}
