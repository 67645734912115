import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoginInput } from "./LoginInput";
import { useUser } from "../contexts/UserContext";
import { loginCheck } from "./LogInManager.helper";
import { MessageResponse } from "../types/requesterTypes";
import style from "./Login.module.scss";

export function LogInManager(): React.ReactElement {
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [requestLoginErr, setRequestLoginErr] = useState<MessageResponse>();

  const userContext = useUser();
  const history = useHistory();

  // Wraps loginCheck() pre-populating all its parameters
  function loginCheckPointer() {
    loginCheck(
      username,
      password,
      setUsername,
      setPassword,
      userContext.logIn,
      history,
      setRequestLoginErr,
    );
  }

  return (
    <div className={style["login-wrapper"]}>
      <h1>{t("Login")}</h1>
      <p>{t("Please fill out the following fields to login")}:</p>
      <div className={style["login-content"]}>
        <LoginInput
          fieldDescriber={t("Username")}
          errMessage={t("Username cannot be blank.")}
          labelTxt="username"
          onChange={setUsername}
          value={username}
          onEnter={loginCheckPointer}
        />
        <LoginInput
          fieldDescriber={t("Password")}
          errMessage={t("Password cannot be blank.")}
          labelTxt="password"
          onChange={setPassword}
          value={password}
          onEnter={loginCheckPointer}
        />
        <div className="col-sm">
          {requestLoginErr !== undefined && (
            <div className="error-box-color pt-2 pb-2">
              <p className="mb-0 ml-3 text-danger">{requestLoginErr.message}</p>
            </div>
          )}
        </div>
        <button
          className="mt-1 mb-4 btn btn-success"
          type="button"
          onClick={loginCheckPointer}
        >
          {t("Login")}
        </button>
          <div>
            <h3>Dantherm Denmark A/S</h3>
            <p className="pl-0 mb-0">
              <i className="fas fa-map-marker-alt mr-1" />
              Kirkebjerg Parkvej 12, 2605 Brøndby, Denmark
            </p>
            <p className="pl-0 mb-0">
              <i className="fas fa-phone-square mr-1" />
              +45 96143700
            </p>
            <p className="pl-0 mb-0">
              <i className="fas fa-envelope mr-1" />
              info@danthermgroup.com
            </p>
            <p className="pl-0 mb-0">
              <i className="fas fa-book mr-1" />
              CVR.: 20864591
            </p>
          </div>
      </div>
    </div>
  );
}
