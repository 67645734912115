import React from "react";
import styles from "./EnergySavingPatternDescription.module.scss";

import { EnergySavingPattern } from "./EnergySavingView";
import { useTranslation } from "react-i18next";


interface Props {
  pattern: EnergySavingPattern
}

/**
 * Generates a description text of schedule stored in `EnergySavingPattern` 
 * 
 * @param pattern The `EnergySavingPattern` to be described 
 * @returns 
 */
export function EnergySavingPatternDescription({
  pattern,
}: Props) {
  const { t } = useTranslation()

  var offset = 0;
  
  const description: JSX.Element[] = []
  const patternSegments = [...pattern.init_pattern, ...pattern.loop_pattern];

  for ( var index = 0; index < patternSegments.length; index++) {
    const segment = patternSegments[index];
    const duration = (segment.on + segment.off) * segment.x;
    // Ensure keys are different for description elmeents of different patterns and in each iteration
    const keyPrefix = `${pattern.id}-${index}`

    // Describe power saving pattern segmment duration
    // todo: Handle multi-segment loop patterns
    if ((index + 1) === patternSegments.length) {  // Last segment, repeated indefinitely
      const from = Math.round(offset/60);
      description.push(
        <p key={`${keyPrefix}-0a`}>{t("After {{count}} hours", {count: from})}</p>
      );
    } else {
      // Regular segments (not last) have start and end time
      const from = Math.round(offset/60);
      const to = Math.round((duration+offset)/60);
      description.push(
        <p key={`${keyPrefix}-0b`}>
          {`${t("{{count}} hours", {count: from})}
          –
          ${t("{{count}} hours", {count: to})}`}
        </p>
      )
    }

    // Describe the schedule imposed by power saving pattern segment when active
    if (segment.off === 0) // Device is always on
      description.push(<p key={`${keyPrefix}-1a`}>{t("Equipment is running constantly")}</p>)
    else if (segment.on === 0) // Device is always off
      description.push(<p key={`${keyPrefix}-1b`}>{t("Equipment is off")}</p>)
    else {
      // Describe on/off pattern here
      // Translated hours segments to be inserted into larger sentence
      const onHours = segment.on/60;
      const offHours = t("_xHours", {count: segment.off/60});
      description.push(
        <p key={`${keyPrefix}-1c`}>
          {t("Equipment is turned off {{xHours}} after every {{count}} hours", {
            xHours: offHours,
            count: onHours,
          })}
        </p>
      );
    }
    offset += duration;
  }

  return <div className={styles.container}>{description}</div>
}