import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useChart } from "../contexts/ChartDataContext";
import { cleanUpDateTime } from "../utils/utility";

import style from "./EnergyTabs.module.scss";

interface Props {
  children: JSX.Element;
  allDataTab: boolean;
  setAllDataTab: (arg: boolean) => void;
}

export function EnergyTabs({
  children,
  allDataTab,
  setAllDataTab,
}: Props): ReactElement {
  const { t } = useTranslation();
  const chart = useChart();

  return (
    <>
      <div className={style["tab-wrapper"]}>
        <button
          type="button"
          className={
            allDataTab ? style["btn-selected"] : style["btn-not-selected"]
          }
          onClick={() => {
            setAllDataTab(true);
          }}
        >
          {t("All time")}
        </button>
        <button
          type="button"
          className={`${
            allDataTab ? style["btn-not-selected"] : style["btn-selected"]
          }`}
          onClick={() => {
            setAllDataTab(false);
          }}
        >
          {cleanUpDateTime(chart.chartParams.x1.toISOString())} -{" "}
          {cleanUpDateTime(chart.chartParams.x2.toISOString())} {t("range")}
        </button>
      </div>
      {children}
    </>
  );
}
