import { useSpring, animated } from "react-spring";

import React, { useState } from "react";
import style from "./ExpandableButtonContainer.module.scss";
import buttonStyle from "./SensorData.module.scss";

import expandIcon from "../images/expand.svg";
import collapseIcon from "../images/collapse.svg";

interface Props {
  children: React.ReactNode,
  title: string,
}

export function ExpandableButtonContainer({
  children,
  title,
}: Props): React.ReactElement {
  const [visible, setVisible] = useState<boolean>(false);

  const anim = useSpring({ from: { anim: 0 }, anim: visible ? 1 : 0, config: { duration: 300 } });

  function toggleVisability(): void {
    setVisible(!visible);
  }

  return (
    <div className={style["expandable-button-container"]}>
      <button
        type="button"
        className={`${style["expandable-button"]} ${buttonStyle["chart-button"]} ${(visible) ? style["collapsed-true"] : style["collapsed-false"]}`}
        onClick={toggleVisability}
      >
        <div className={style.title}>{title}</div>
        <div className={style["expandable-icon-container"]}>
          <img 
            src={visible ? collapseIcon : expandIcon}
            alt=""
          />
        </div>
      </button>
      <animated.div
        className={style["collapsable-content"]}
        style={{ maxHeight: anim.anim.interpolate({ range: [0, 1], output: ["0rem", "20rem"] }) }}
      >
        <div className={style["content-wrapper"]}>
          {children}
        </div>
        
      </animated.div>
    </div>
  );
}
