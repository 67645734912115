import React from "react";
import { Trans } from "react-i18next";
import { Checkbox } from "./Checkbox";

import styles from "./SocketSelector.module.scss";

interface Props {
  sockets: number;
  socketCount: number;
  onChange: (sockets: number) => void;
  highlightRed: boolean;
}

export function SocketSelector({
  sockets,
  socketCount,
  onChange,
  highlightRed,
}: Props) {
  // 
  const checkboxes = [];

  for(let index = 0; index < socketCount; index++){
    checkboxes.push(
      <div className={styles.socket}>
        <Checkbox 
          // sockets are represented as bits from lowest to highest (right to left)
          // create new number with bit matching index set to 1 and perform AND operation between
          // it and sockets - non-zero outcome will act as true, 
          // zero outcome acts as false (yay javascript)
          checked={sockets & (1 << index) ? true : false}
          // similarly to above - return value with flipped bit matching the index using XOR operation
          onClick={() => onChange(sockets ^ (1 << index))}
          highlightRed={highlightRed}
        />
        <label><Trans>Outlet {{socketNumber: index + 1}}</Trans></label>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {checkboxes}
    </div>
  )
}