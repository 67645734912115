import React, { MouseEventHandler, ReactNode } from 'react';

import styles from './Card.module.scss';
import removeIcon from './trash_icon.svg';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  onDelete: MouseEventHandler;
}

export default function AlarmsRow({
  children,
  disabled,
  onDelete,
}: Props): React.ReactElement {
  return (
    <div className={styles.row}>
      <p className={styles.text}>
        {children}
      </p>
      <button
        className={styles.button}
        onClick={onDelete}
        hidden={disabled}
      >
        <img alt='Delete' src={removeIcon}/>
      </button>
    </div>
    
  );
}