import React, { ReactNode } from "react";
import styles from "./SelectorRow.module.scss";

interface Props {
  children: ReactNode
  label: string
}

export function SelectorRow({
  children,
  label
}: Props) {
  return <div className={styles.row}>
    <label className={styles.label}>{label}</label>
    <div className={styles.container}>
      {children}
    </div>
  </div>
}