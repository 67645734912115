import React from "react";
import { DatePickerWrapper } from "./DatePickerWrapper";
import { ChartTypeWrapper } from "./ChartTypeWrapper";
import { ChartTypes } from "../../types/types";

interface Props {
  chartType: ChartTypes;
  chartTypeChange: (e: ChartTypes) => void;
  endDate?: Date;
  startDate?: Date;
  visibleDate?: boolean;
  alterDateEnd: (arg: Date) => void;
  alterDateStart: (arg: Date) => void;
  toggleConstantTime: () => void;
  constantTime: boolean;
}

export function ChartTypePickerWidget({
  alterDateEnd,
  alterDateStart,
  chartType,
  chartTypeChange,
  constantTime,
  endDate,
  visibleDate,
  startDate,
  toggleConstantTime,
}: Props): React.ReactElement {
  return (
    <>
      <div>
        <ChartTypeWrapper
          chartType={chartType}
          chartTypeChange={chartTypeChange}
        />
      </div>
      <div>
        <DatePickerWrapper
          label="Start"
          chartType={chartType}
          selected={startDate}
          onChange={alterDateStart}
          maxDate={endDate}
          startDate={startDate}
          endDate={endDate}
          visibleDate={visibleDate}
          selectsStart
          />
      </div>
      <div>
        <DatePickerWrapper
          label="End"
          chartType={chartType}
          selected={endDate}
          onChange={alterDateEnd}
          minDate={startDate}
          startDate={startDate}
          endDate={endDate}
          visibleDate={visibleDate}
          selectsEnd
        />
      </div>
      {/* <div>
        <LineTimeInputCheckboxWrapper
          checked={constantTime}
          onChange={toggleConstantTime}
        />
      </div> */}
    </>
  );
}
