import React, { useCallback, useEffect, useState } from 'react';
import { CreateInstAlarmRequest } from '../../types/requesterTypes';
import { Requester } from '../../utils/Requester';
import Card from './components/Card/Card';
import CardContainer from './components/Card/CardContainer';
import AddAlarmRow from './components/Card/AddAlarmRow';
import AlarmRow from './components/Card/AlarmRow';
import TitleRow from './components/Card/TitleRow';
import EmailRow from './components/Card/EmailRow';
import TitleBar from './components/TitleBar/TitleBar';
import TitleBarButton from './components/TitleBar/TitleBarButton';
import AddEmailRow from './components/Card/AddEmailRow';
import ControlUnitAlarmRow from './components/Card/ControlUnitAlarmRow';
import { SpinnyThingy } from '../Misc/SpinnyThingy';
import AlarmEditModal from './modal/AlarmEditModal';
import {
  AlarmData,
  Email,
  InstalAndAlarmPk,
  InstalAndEmailPk,
  NodeAlarmEndpointData,
  NodeAndAlarmId
} from './types';
import { specialTypeName } from './util/utils';
import { TFunction, useTranslation } from "react-i18next";
import { getProductName } from '../../utils/utility';
import toast from 'react-hot-toast';
import { PortalModal } from '../PortalModal/PortalModal';
import { AlarmEnum } from '../../types/generated_types';
import { getUnit } from '../../types/generated_types';
import { NoAlarmsRow } from './components/Card/AlarmCardTextRow';
import { InfoBanner } from '../Misc/InfoBanner';
import { ExpandableContainer } from '../Misc/ExpandableContainer';

interface Props {
  installationPk: number;
  isArchived?: boolean;
  updateCounts: (alarmCount: number, emailCount: number) => void;
}

function generateAlarmText(alarm: AlarmData, t: TFunction<string>): string{
  switch(alarm.alarm_type){
    case AlarmEnum.PAS:
    case AlarmEnum.RED:
    case AlarmEnum.WFL:
      return specialTypeName(alarm.alarm_type, t);
    default:
      return `${specialTypeName(alarm.alarm_type, t)} ${alarm.constraints} ${alarm.threshold_value} 
      ${getUnit(alarm.alarm_type)}`;
  }
}

export default function InstallationAlarmsView({
  installationPk,
  isArchived,
  updateCounts,
}: Props): React.ReactElement{
  const [installationAlarms, setInstallationAlarms] = useState<Array<AlarmData>>();
  const [sensorNodes, setSensorNodes] = useState<Array<NodeAlarmEndpointData>>();
  const [installationEmailData, setInstallationEmailData] = useState<Array<Email>>([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState<NodeAlarmEndpointData>();

  const [addRowActive, setAddRowActive] = useState<boolean>(false);

  const { t } = useTranslation();
  
  const refresh = useCallback(() => {
    Requester.instalAndNodeAlarms({pk: installationPk})
    .then((result) => {
      setInstallationAlarms(result.inst_alarms)
      setSensorNodes(result.node_data.sort((a, b) => a.sensor_256_id - b.sensor_256_id));
    })
  },[installationPk]);

  // Remove recipients email from the installation
  const removeEmail = useCallback((emailId: number) => {
    const emailData: InstalAndEmailPk = { 
      email_id: emailId, 
      pk: installationPk 
    }
    // Remove email from the database
    Requester.removeAlarmEmail(emailData).then(
      (result) => {
        if (result.status === 'success') {
          // Remove and display the emails in the frontend
          setInstallationEmailData((prev) => {
            const emailDataList: Email[] = prev.filter(
              email => email.email_pk !== emailId
            )
            return emailDataList;
          });
        } else {
          const err_msg = t("Failed to remove email")
          toast.error(err_msg);
        }
      }
    );
  }, [installationPk, t]);

  // Make sure refresh is run when it is updated with new installationPk
  useEffect(() => {
    setInstallationAlarms(undefined);
    refresh();
  }, [refresh]);

  useEffect(() => {
    if(installationPk){
      Requester.getEmailList(installationPk)
      .then((result) => {
        setInstallationEmailData(result.data);
      });
    }
  }, [installationPk]);

  // Update counts for parent (<AlarmsPage>) whenever they change
  useEffect(() => {
    // Skip if any are undefined
    if (installationAlarms === undefined)
      return;
    if (sensorNodes === undefined)
      return;
    // Get counts
    const installationAlarmsCount = installationAlarms.length;
    const sensorAlarmsCount = sensorNodes.reduce((acc, i) => {
      // take sum of node_alarm lengths over all the nodes
      return acc + i.node_alarms.length;
    }, 0);
    const emailCount = installationEmailData.length;
    updateCounts(installationAlarmsCount + sensorAlarmsCount, emailCount);
  }, [installationAlarms, sensorNodes, installationEmailData, updateCounts]);

  if (!sensorNodes || !installationAlarms)
    return <SpinnyThingy/>;

  const emailWarning = sensorNodes.some(node =>
    installationEmailData.length === 0 &&
    (installationAlarms.length > 0 || node.node_alarms.length > 0)
  );

  return (
    <>
      <PortalModal
        isOpen={showEditModal}
        close={() => setShowEditModal(false)}
      >
        <AlarmEditModal 
          nodes={sensorNodes}
          selectedNode={selectedNode}
          onClose={() => {
            setShowEditModal(false);
            setSelectedNode(undefined);
          }}
          onSave={() => refresh()}
        />
      </PortalModal>
      <ExpandableContainer show={emailWarning}>
        <InfoBanner>
          <i className="fas fa-exclamation-triangle"/>
          {t("There are no assigned recipients to receive the alarms")}
        </InfoBanner>
        <br/>
      </ExpandableContainer>
      <TitleBar
        title={t('Installation alarms')}
      />
      <CardContainer>
        <Card wide>
          <ControlUnitAlarmRow 
            title={t("Passive (Control Unit only)")}
            disabled={isArchived}
            active={installationAlarms.some(alarm => 
              alarm.alarm_type === AlarmEnum.PAS)
            }
            // Using requests designed for old alarms page implementation
            onChange={(checked) => {
              if(checked) {
                Requester.createInstAlarm({
                  alarm_type: AlarmEnum.PAS,
                  installation_fk: [installationPk],
                } as CreateInstAlarmRequest).then(refresh);
              }
              else {
                Requester.removeInstAlarm({
                  alarm_id: installationAlarms.find(
                    (alarm) => alarm.alarm_type === AlarmEnum.PAS
                  )?.id,
                  pk: installationPk,
                } as InstalAndAlarmPk).then(refresh);
              }
            }}
          />
          <ControlUnitAlarmRow
            title={t("Passive (all sensors)")}
            disabled={!sensorNodes.length || isArchived} // No nodes or archived
            active={sensorNodes.every(
              node => node.node_alarms.some(
                alarm => alarm.alarm_type === AlarmEnum.PAS
              )
            )} // All nodes are passive
            // Using requests designed for old alarms page implementation
            onChange={(checked) => {
              if(checked) {
                Requester.createPasSensAlarm({
                  installation_node_fk: sensorNodes.map(node => node.id)
                }).then(refresh);
              }
              else {
                const passiveNodeData: Array<NodeAndAlarmId> = sensorNodes.flatMap(
                  node => node.node_alarms.filter(alarm => alarm.alarm_type === AlarmEnum.PAS).map(
                    alarm => {
                      return {
                        node_id: node.id,
                        alarm_id: alarm.id
                      }
                    }
                  )
                )
                // Wrapping requests in Promise.allSettled() so `refresh` is called only once
                Promise.allSettled(
                  passiveNodeData.map(data =>
                    Requester.removeNodePasAlarm(data)
                  )
                ).then(refresh)
              }
            }}
          />
        </Card>
      </CardContainer>
      <TitleBar
        title={t('Sensor alarms')}
      >
        { !isArchived &&
          <TitleBarButton 
            text={t('Add alarm')}
            onClick={() => {
              setShowEditModal(true);
            }}
          />
        }
      </TitleBar>
      <CardContainer>
        {sensorNodes.map((node)=> 
          <Card key={node.id}>
            <TitleRow 
              title={`${getProductName(node.node_type, t)} ${node.sensor_256_id}`}
              name={node.node_name} 
            />
            {node.node_alarms.map((alarm) => 
              <AlarmRow 
                key={alarm.id}
                disabled={isArchived}
                onDelete={()=>{
                  if (alarm.alarm_type === AlarmEnum.PAS) {
                    Requester.removeNodePasAlarm({
                      node_id: node.id,
                      alarm_id: alarm.id,
                    }).then(refresh);
                  } else {
                    Requester.removeNodeDataAlarm({
                      node_id: node.id,
                      alarm_id: alarm.id,
                    }).then(refresh);
                  }
                }}
              >
                <div>{generateAlarmText(alarm, t)}</div>
                { (alarm.delay_value !== undefined && alarm.delay_value > 0) &&
                  <div>
                    {
                      t("{{label}}: {{value}}", {
                        label:  t("Alarm delay"),
                        value: t("{{count}} hours", {count: alarm.delay_value}),
                      })
                    }
                  </div>
                }
              </AlarmRow>
            )}
            { isArchived ?
              <>
                { node.node_alarms.length === 0 &&
                  <NoAlarmsRow>{t("No alarms assigned")}</NoAlarmsRow>
                }
              </>
              :
              <AddAlarmRow
                onClick={() => {
                  setSelectedNode(node)
                  setShowEditModal(true);
                }}
              />
            }
          </Card>
        )}
      </CardContainer>
      <TitleBar
        title={t('Recipients')}
      />
      <CardContainer>
        <Card wide>
          {installationEmailData.map((email, i) =>
            <EmailRow 
              key={Number(email.email_pk)}
              installationPk={installationPk}
              email={email}
              disabled={isArchived}
              onDelete={() => {
                removeEmail(email.email_pk)
              }}
              onSave={(newEmail) => {
                // Replace email with the modified one
                // NOTE: IMCS-199 this could use functional updater, but
                //       given it also depends on array ordering by `i`,
                //       it might be safer left as is
                installationEmailData[i] = newEmail
                setInstallationEmailData([...installationEmailData]);
              }}
              onCancel={() => { /* Do nothing */ }}
            />
          )}
          {(addRowActive) ?
            <EmailRow
              email={undefined}
              installationPk={installationPk}
              onSave={(email) => {
                // Append the added email to the array
                setInstallationEmailData((prev) => [...prev, email]);
              }}
              onCancel={() => {
                setAddRowActive(false);
              }}
            />
            :
            <>
              { !isArchived &&
                <AddEmailRow
                  onAddEmail={() => setAddRowActive(true)}
                />
              }
            </>
          }
        </Card>
      </CardContainer>
    </>
  );
}
