import React from "react";
import infoIcon from "../../images/info-icon.svg";

interface Props {
children: string,
}

export function InfoText({
  children,
}: Props): React.ReactElement {
  return (
    <div className="d-flex flex-row m-2">
      <img 
        src={infoIcon}
        className="mr-2"
        alt=""
        />
      <p>{children}</p>
    </div>  
  )
}