import React from "react";
import styles from "./PolicyPage.module.scss";

export function PolicyPage() {

  return <div className={styles["prose-block"]}>
    <h1>Privacy Policy</h1>
    <p>
        Simplify Climate Solutions® app is developed and published by
        Dantherm A/S and their partners. It is used to interact with and operate
        equipment of Simplify monitoring solution. Certain information is
        collected and sent to Dantherm's servers to facilitate app's primary
        functionality and to aid developers making improvements as required.
        This policy describes what information is being collected and for what
        purpose.
    </p>

    <h2>Information collected</h2>
    <p>The following information is collected by the app and submitted for
        processing by explicit user action:</p>
    <ul>
        <li>
            <p>
                <b>Username and password</b><br/>
                required to authorise user in the system and determine access rights
                to equipment being interacted with.
            </p>
        </li>
        <li>
            <p>
                <b>Equipment identifiers and names</b><br/>
                as read by QR scanner and entered by user manually, required to
                interact with the equipment and make modifications to configuration
                of the monitoring solution.
            </p>
        </li>
        <li>
            <p>
                <b>Precise location</b><br/>
                is used to establish equipment whereabouts in point of time that is
                later accessible to the user. User may opt-out and disable location
                collection thereby reducing functionality for parts of service that
                rely on location data.
            </p>
        </li>
    </ul>

    <p>Additionally, the following information may be collected automatically:</p>
    <ul>
        <li>
            <p>
                <b>Crash logs</b><br/>
                are sent if crashes of the app occur and are used for diagnosing and
                fixing problems and can include data such as device model, operating
                system version, app version, state of the app at time of crash, but
                is otherwise anonymised and not linked to specific user.
            </p>
        </li>
        <li>
            <p>
                <b>Access logs</b><br/>
                are collected by Dantherm servers when app connects to them to upload
                any of the above listed information. A log entry contains IP address,
                app version, accessed URL and time of access, but is otherwise
                anonymised and not linked to specific user or data being uploaded.
            </p>
        </li>
    </ul>

    <h2>Security</h2>
    <p>
        Dantherm A/S recognises the importance of data security to maintain
        trust of our users. All communication between the app and Dantherm
        servers is encrypted in-transit using industry-standard technologies.
    </p>

    <h2>Scope of the policy</h2>
    <p>
        The scope of this privacy policy is limited to data collected using
        Simplify Climate Solutions® app. Use of Dantherm website and other
        provided services are covered by their own respective privacy policies.</p>
    <p>
        The app is published using Google Play (Android version) and App Store
        (iOS version). Information about usage of the app may be collected by
        either of the services and is subject to their respective privacy
        policies.
    </p>

    <h2>Data processing policies</h2>
    <p>
        Dantherm A/S stores and processes collected data in accordance with
        EU Regulation 2016/679 General Data Protection Regulation (GPDR). The
        handling of data may further be subject to Data Processing Agreement
        between Dantherm A/S and the user.
    </p>

    <h2>Contact us</h2>
    <p>
        If you have questions or suggestions about our Privacy Policy, don't
        hesitate to contact us.
    </p>
    <p>
        Dantherm A/S <br/>
        CVR 20864591 <br/>
        Marienlystvej 65 <br/>
        DK-7800 Skive, Denmark <br/>
        E-mail: <a href="mailto:info@danthermgroup.com">
            info@danthermgroup.com
        </a>
    </p>
  </div>;
}
