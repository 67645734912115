import React from 'react';

import styles from './TitleRow.module.scss';

interface Props {
  title: string;
  name: string;
}

export default function TitleRow({
  title,
  name,
}: Props): React.ReactElement {
  return (
    <div className={styles.row}>
      <div>
        <p className={styles.title}>{title}</p>
        <p>{name}</p>
      </div>
    </div>
  );
}