import React, { createContext } from "react";
import { User } from "../types/types";

interface Props {
  children: JSX.Element;
  user: User;
}

export interface UserInfoContextInterface {
  user: User;
}

export const ctxt = createContext<UserInfoContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in UserContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as UserInfoContextInterface, {
    apply: () => {
      throw new Error("You must wrap your component in an UserContextProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an UserContextProvider");
    },
  }),
);

const { Provider } = ctxt;

function UserInfoContextProvider({ children, user }: Props): React.ReactElement {

  return (
    <Provider value={{ user }}>
      {children}
    </Provider>
  );
}

const useUserInfo = (): UserInfoContextInterface => React.useContext(ctxt);

export { UserInfoContextProvider, useUserInfo };
