/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  InstallationPages,
} from "../../types/types";
import { SpinnyThingy } from "../Misc/SpinnyThingy";
import ScrollToTop from "../../utils/ScrollToTop";
import { InstallationPage } from "./InstallationPage";
import { ChartDataContextProvider } from "../../contexts/ChartDataContext";

const LazyAdmin = React.lazy(() => import("../Admin/AdminRoutes"));
const DeviceListPageManager = React.lazy(
  () => import("../../components/DeviceList/DeviceListPageManager"),
);
const UserPageManager = React.lazy(
  () => import("../../containers/UserPageManager"),
);
const AlarmsPage = React.lazy(
  () => import("../Alarms/AlarmsPage"),
);

interface MatchParams {  // for List View and Installation page URLs
  mode: InstallationPages;
  id?: string;
}

export function DashboardRoutes(): React.ReactElement {
  const { t } = useTranslation();
  const matchInstallView = useRouteMatch<MatchParams>(
    "/dashboard/:mode/installation/:id?",
  );
  const matchDashboard = useRouteMatch<MatchParams>(
    "/dashboard/list/:mode",
  );
  const matchAccount = useRouteMatch("/dashboard/account");

  useEffect(() => {
    if (matchInstallView) {
      document.title = t("Dashboard Installation");
    } else if (
      matchDashboard &&
      matchDashboard.params?.mode === InstallationPages.Archived
    ) {
        document.title = t("Archive");
    } else if (matchAccount) {
      document.title = t("User account");
    } else {
      document.title = t("Dashboard")
    }
  }, [
    matchAccount,
    matchDashboard,
    matchInstallView,
    t,
  ]);

  return (
    <Suspense fallback={<SpinnyThingy />}>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path="/dashboard/alarm/level"
            component={AlarmsPage}
          />
          <Route path="/dashboard/admin" component={LazyAdmin} />
          <Route
            path="/dashboard/:mode/installation/:id"
            render={() => 
              <ChartDataContextProvider>
                <InstallationPage />
              </ChartDataContextProvider>
            }
          />
          <Route
            exact
            path="/dashboard/list/:mode"
            component={DeviceListPageManager}
          />
          <Route exact path="/dashboard/account" component={UserPageManager} />

          {/* DIFFERENT REDIRECTS */}
          <Route
            exact
            path="/"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            exact
            path="/dashboard"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            exact
            path="/dashboard/list"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            path="/dashboard/default/index"
            // Legacy URL redirect
            render={(props) => {
              let mode = "active";
              if (props.location.search !== null) {
                const getPars = new URLSearchParams(props.location.search);
                if (getPars.get("archive") !== null) {
                  mode = "archive";
                }
              }
              return <Redirect to={`/dashboard/list/${mode}`} />;
            }}
          />
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
}
