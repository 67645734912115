import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviceNodeIMR, STESubSensors, ScheduleDeviceNode } from "../../types/types";

import style from "./NodeInfo.module.scss";
import timerYellow from "../../images/timer-yellow.svg";
import timerBlack from "../../images/timer-black.svg";
import powerYellow from "../../images/lightning-yellow.svg";
import powerBlack from "../../images/lightning-black.svg";

import moment from "moment-timezone";
import { PortalModal } from "../PortalModal/PortalModal";
import { PowerButtonModal } from "./PowerButtonModal";
import { NodeTypes, TSensorTypes } from "../../types/generated_types";

interface Props {
  isOn: boolean;
  outlet: STESubSensors;
  node: ScheduleDeviceNode | DeviceNodeIMR;
}

interface ButtonProps {
  icon: string,
  on: boolean,
}

function selectButtonProps(powerOn: boolean, node: ScheduleDeviceNode | DeviceNodeIMR, outlet: STESubSensors): ButtonProps {
  // box or socket turned off by user
  if (!powerOn) return {icon: powerBlack, on: false};

  // if schedule is set then show timer icons
  if (node.node_type !== NodeTypes.IMR && isScheduleRelevant(node, outlet)) {
    return isScheduleOn(node, outlet)
      ? {icon: timerBlack, on: false}
      : {icon: timerYellow, on: true};
  }
  // no schedule but power on (first condition)
  return {icon: powerYellow, on: true};
}

function isScheduleRelevant(
  node: ScheduleDeviceNode,
  outlet: STESubSensors,
): boolean {
  // schedule is not set
  if (node.quiet_times.length < 1) return false;

  // has no sockets therefore schedule only needs to be present
  if ([NodeTypes.IMI, NodeTypes.AD9].includes(node.node_type)) return true;
  
  // for IMRMk2 and CC4 schedule is relevant if for given sensor (socket) at least one entry
  // has schedule for the given sensor (socket)
  return (
    node.quiet_times.some(
      quietTime => quietTime.mask & steToBit(outlet)
    )
  );
}

function isScheduleOn(node: ScheduleDeviceNode, outlet: STESubSensors): boolean {
  if (!node.timezone || !node.standard_timezone) return false;

  const timezone = node.standard_timezone;
  const now = moment.tz(timezone);
  
  const currentSecond = now.diff(now.clone().startOf("day"), "seconds");
  const currentDay = 1 << (moment.tz(timezone).isoWeekday() - 1);

  return (
    node.quiet_times.some(quietTime => {
      // check sockets for node types that have sockets
      if ([NodeTypes.IMRMk2, NodeTypes.CC4].includes(node.node_type)) {
        // fail if both socket bit and corresponding mask bit are not both 1
        if (!(quietTime.mask & steToBit(outlet))) return false;
      }
      // fail if not in selected time range
      if (currentSecond < quietTime.start_time) return false;
      if (currentSecond > quietTime.end_time) return false;
      // check whether selected day matches current day
      return currentDay & quietTime.days;
    })
  );
}

// convert STE sensor types to socket bits
function steToBit(sensorType: STESubSensors) {
  if (sensorType === TSensorTypes.STE1) return 1;
  if (sensorType === TSensorTypes.STE2) return 1 << 1;
  if (sensorType === TSensorTypes.STE3) return 1 << 2;
  if (sensorType === TSensorTypes.STE4) return 1 << 3;
  return 0;
}

export function PowerButton({
  isOn,
  outlet,
  node,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const buttonProps = selectButtonProps(isOn, node, outlet)

  return (
    <div className={style["on-off-button-wrapper"]}>
      <abbr title={t("Turn the power ON or OFF")}>
        <button
          type="button"
          className={`mr-1 ${style["on-off-button"]} ${isOn ? style.on : style.off}`}
          id="load-data-table"
          onClick={() => {
            setShowModal(true);
          }}
          data-toggle="modal"
          data-target="#powerButtonModalId"
        >
          <img src={buttonProps.icon} alt="timer"/>
          {buttonProps.on ? t("ON") : t("OFF")}
        </button>
        <PortalModal
          isOpen={showModal}
          close={() => setShowModal(false)}
        >
          <PowerButtonModal
            outlet={outlet}
            node={node}
            isOn={isOn}
            close={() => setShowModal(false)}
          />
        </PortalModal>
      </abbr>
    </div>
  );
}
