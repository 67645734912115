import React, { ChangeEvent, useState, useEffect } from "react";

interface Props {
  fieldDescriber: string;
  errMessage: string;
  labelTxt: string;
  onChange: (arg: string) => void;
  value: string | number |undefined;
  onEnter?: () => void;
  canBeBlank?: boolean;
}

export function LoginInput({
  fieldDescriber,
  errMessage,
  labelTxt,
  onChange,
  value,
  canBeBlank,
  onEnter,
}: Props): React.ReactElement {
  const [showErr, setShowErr] = useState<boolean>(false);

  useEffect(() => {
    if (value !== undefined && !canBeBlank) {
      setShowErr(value === "");
    }
  }, [canBeBlank, value]);

  return (
    <>
      <div className="form-group">
        <label htmlFor={labelTxt}>{fieldDescriber}</label>
        <input
          type={labelTxt}
          className={`form-control ${showErr ? "border border-danger" : ""}`}
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder={fieldDescriber}
          value={value === undefined ? "" : value}
          onKeyDown={(e) => {
            if (e.key === "Enter" && onEnter !== undefined) {
              onEnter();
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
          }}
        />
        <div className="col-sm">
          {showErr && <p className="mb-0 pt-2 text-danger">{errMessage}</p>}
        </div>
      </div>
    </>
  );
}
