import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExtremeButtons, range } from "./Pagination.helper";
import style from "./Pagination.module.scss";

interface Props {
  currentPage: number;
  totalPages: number;
  switchToPage: (arg: number) => void;
  children?: JSX.Element;
  oldestNewestVisable?: boolean;
}

export function Pagination({
  currentPage,
  switchToPage,
  totalPages,
  children,
  oldestNewestVisable,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  let startPage;
  let endPage;

  if (totalPages < 10) {
    endPage = totalPages;
    startPage = 1;
  } else {
    startPage = currentPage - 4;
    endPage = currentPage + 5;
    if (startPage < 1) {
      endPage -= startPage - 1; // Pad the extra space to the right
      startPage = 1;
    }
    if (endPage > totalPages) {
      startPage += totalPages - endPage;
      endPage = totalPages;
    }
  }

  const paginationPageNumbers = (
    <ul className="pagination">
      { oldestNewestVisable ? (
        <li
          key="Min"
          className={`page-item ${currentPage > 1 ? "" : "disabled"}`}
        >
          <ExtremeButtons
            text={t("Min")}
            sign={t("Newest")}
            click={() => switchToPage(1)}
          />
        </li>
      ) : ""}
      <li
        key="Previous"
        className={`page-item ${currentPage > 1 ? "" : "disabled"}`}
      >
        <ExtremeButtons
          text={t("Previous")}
          sign="&laquo;"
          click={() => switchToPage(currentPage - 1)}
        />
      </li>
      {range(startPage, endPage, 1).map((el) => (
        <li
          key={el}
          className={el === currentPage ? "page-item active" : "page-item"}
        >
          <button
            type="button"
            className="page-link"
            onClick={() => switchToPage(el)}
          >
            {el.toFixed(0)}
          </button>
        </li>
      ))}
      <li
        key="Next"
        className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}
      >
        <ExtremeButtons
          text={t("Next")}
          sign="&raquo;"
          enabled={currentPage + 1 > totalPages}
          click={() => switchToPage(currentPage + 1)}
        />
      </li>
      { oldestNewestVisable ? (
        <li
          key="Max"
          className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}
        >
          <ExtremeButtons
            text={t("Max")}
            sign={t("Oldest")}
            enabled={currentPage + 1 > totalPages}
            click={() => switchToPage(totalPages)}
          />
        </li>
      ) : ""}
    </ul>
  );
  return (
    <div className={style.pagination}>
      <div className={style.items}>{paginationPageNumbers}</div>
      <div className={style.children}>{children}</div>
    </div>
  );
}

export function usePageCalculation<T>(
  value: T[],
  itemsPerPage: number,
  initialPage = 1,
): {
    valuesToShow: T[];
    maxPages: number;
    currentPage: number;
    setCurrentPage: (arg0: number) => void;
  } {
  const [maxPages, setMaxPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  useEffect(() => {
    setMaxPages(Math.ceil(value.length / itemsPerPage));
  }, [itemsPerPage, value]);
  const valuesToShow = useMemo(
    () => value.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [value, itemsPerPage, currentPage],
  );

  return {
    valuesToShow, maxPages, currentPage, setCurrentPage,
  };
}
