/* eslint-disable import/no-default-export */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

const initLang = localStorage.getItem("lang");
const backendOptions = {
  loadPath: "/i18n/{{lng}}/{{ns}}.json",
  reloadInterval: 300000, // Reload language layer very 5 minutes
};
void i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: backendOptions,
    lng: initLang || "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },

    // Allow "." and ":" in translation keys
    // at cost of losing inline namspacing of the keys
    // NOTE: See also frontend/i18next-parser.config.js
    keySeparator: false,
    nsSeparator: false,
  });

export default i18n;
