import React, { CSSProperties, useState } from 'react';
import Select from 'react-select';
import { Option } from 'react-select/src/filters';


export interface MultiSelectorOption {
  value: string;
  label: string;
  red?: boolean;
}

interface Props {
  options: Array<MultiSelectorOption>;
  defaultValue?: string;
  onChange: (options: Array<MultiSelectorOption>) => void;
}

export default function MultiNodeSelector({
  options,
  onChange,
  defaultValue,
}: Props): React.ReactElement {
  const styles = {
    multiValue: (base: CSSProperties, option: Option): CSSProperties => ({ 
      ...base,
      backgroundColor: option.data.red ? '#FF7D7D' : base.backgroundColor,
    }),
    option: (base: CSSProperties): CSSProperties => ({
      ...base,
      cursor: 'pointer',
    }),
    control: (base: CSSProperties): CSSProperties => ({
      ...base,
      cursor: 'pointer',
    }),
  };
  const [selectedOptions, setSelectedOptions] = useState<Array<MultiSelectorOption>>(
    options.filter(option => option.value === defaultValue)
  );
  
  return <Select 
    options={options}
    value={selectedOptions.map(selectedOption => ({
      ...selectedOption,
      // When options is updated with different "red" data selectedOptions also need to be updated
      // in order to keep background color of already selected items up to date (which is based on "red" data) 
      red: options.find(item => item.value === selectedOption.value)?.red,
    }))}
    styles={styles}
    isMulti

    onChange={options => {
      if (options) {
        setSelectedOptions(options as Array<MultiSelectorOption>);
        onChange(options as Array<MultiSelectorOption>);
      } else {
        setSelectedOptions([]);
        onChange([]);
      }
    }}
  />;
}