import React, { ReactNode } from 'react';

import style from './Card.module.scss';

interface Props {
  children: ReactNode;
  wide?: boolean;
}

export default function Card({
  children,
  wide,
}: Props): React.ReactElement {
  return (
    <div 
      className={`${style.card} ${wide ? style["card-wide"] : ""}`}
    >
      {children}
    </div>
  );
}